import React from 'react'

function ValidateAssetMixChangeForm (numberAssetMixChangeItems, presetObject, assetMixChangeAge, assetMixChangeEquity, assetMixChangeBonds, assetMixChangeIndex, setErrors, setIsModalOpen, setModalmessage) {

    const newErrors = {};

    if (numberAssetMixChangeItems >= 50) {setModalmessage('Maximum number of items exceeded'); setIsModalOpen(true); return false}
    if (isNaN(assetMixChangeAge) || assetMixChangeAge === null || assetMixChangeAge === undefined || String(assetMixChangeAge).trim() === '') {newErrors.assetMixChangeAge = 'Can not have empty fields. Enter a value.'}
    if (assetMixChangeEquity < 0 || assetMixChangeEquity > 100) {newErrors.assetMixChangeEquity = 'Must be between 0-100%'}
    if (isNaN(assetMixChangeEquity) || assetMixChangeEquity === null || assetMixChangeEquity === undefined || String(assetMixChangeEquity).trim() === '') {newErrors.aassetMixChangeEquity = 'Can not have empty fields. Enter a value.'}
    if (assetMixChangeBonds < 0 || assetMixChangeBonds > 100) {newErrors.assetMixChangeBonds = 'Must be between 0-100%'}
    if (isNaN(assetMixChangeBonds) || assetMixChangeBonds === null || assetMixChangeBonds === undefined || String(assetMixChangeBonds).trim() === '') {newErrors.assetMixChangeBonds = 'Can not have empty fields. Enter a value.'}
    if (assetMixChangeIndex < 0 || assetMixChangeIndex > 100) {newErrors.assetMixChangeIndex = 'Must be between 0-100%'}
    if (isNaN(assetMixChangeIndex) || assetMixChangeIndex === null || assetMixChangeIndex === undefined || String(assetMixChangeIndex).trim() === '') {newErrors.aassetMixChangeIndex = 'Can not have empty fields. Enter a value.'}
    if ((parseFloat(assetMixChangeEquity) + parseFloat(assetMixChangeBonds) + parseFloat(assetMixChangeIndex)) !== 100 ) {newErrors.assetMixChangeIndex = 'Asset mix must total 100%'}

    if (Object.keys(newErrors).length > 0) {
        setIsModalOpen(true)
        setModalmessage('Entry error. Check beneath input boxes for error messages.')
        setErrors(newErrors); 
        return false};
    setErrors({}); 

    return (true)
    };

export default ValidateAssetMixChangeForm


