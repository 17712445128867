import React from 'react'

function ValidateExtraContributionForm (numberExtraInflowItems, presetObject, extraName, extraYear, extraAmount, extraIncrease, extraDate, setErrors, setIsModalOpen, setModalmessage) {

    const newErrors = {};
    if (numberExtraInflowItems >= 10) {setModalmessage('Maximum number of items exceeded'); setIsModalOpen(true); return false}
    if (extraName.length > 64) {newErrors.extraName = 'Name description must be less than 255 characters'}
    if (extraName === undefined || String(extraName).trim() === '') {newErrors.extraName = 'Add a name'}
    if (extraYear < presetObject.start_simulation_age || extraYear > presetObject.end_simulation_age) {newErrors.extraYear = 'Start age must be inside range of simulation start age and end age'}
    if (extraAmount < 0) {newErrors.extraAmount = 'Can not be negative'}
    if (isNaN(extraAmount) || extraAmount === null || extraAmount === undefined || String(extraAmount).trim() === '') {newErrors.extraAmount = 'Can not have empty fields. Enter a value.'}
    if (isNaN(extraIncrease) || extraIncrease === null || extraIncrease === undefined || String(extraIncrease).trim() === '') {newErrors.extraIncrease = 'Can not have empty fields. Enter a value.'}

    if (Object.keys(newErrors).length > 0) {
        setIsModalOpen(true)
        setModalmessage('Entry error. Check beneath input boxes for error messages.')
        setErrors(newErrors); 
        return false};
    setErrors({}); 

    return (true)
    };

export default ValidateExtraContributionForm
