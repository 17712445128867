import React from 'react'

function ValidateForm (presetObject, setErrors, setIsModalOpen, setModalmessage) {

    const newErrors = {};
    const warningMessages = [];
    if (presetObject.plan_title === "") {newErrors.plan_title = 'Enter a plan name';}
    if (presetObject.plan_title && presetObject.plan_title.length > 100) {newErrors.plan_title = 'Max 100 characters';}
    if (presetObject.equity_tax > 100 || presetObject.equity_tax < 0) {newErrors.equity_tax = 'Enter 0-100%'};
    if (isNaN(presetObject.equity_tax)) {newErrors.equity_tax = 'Can not have empty fields. Enter a value.'};
    if (presetObject.fees > 200 || presetObject.fees < 0) {newErrors.div_perc = 'Enter 0-200 basis points'};
    if (isNaN(presetObject.fees)) {newErrors.fees = 'Can not have empty fields. Enter a value.'};
    if (presetObject.draw_tax > 100 || presetObject.draw_tax < 0) {newErrors.draw_tax = 'Enter 0-100%'};
    if (isNaN(presetObject.draw_tax)) {newErrors.draw_tax = 'Can not have empty fields. Enter a value.'};
    if (presetObject.bond_tax > 100 || presetObject.bond_tax < 0) {newErrors.bond_tax = 'Enter 0-100%'};
    if (isNaN(presetObject.bond_tax)) {newErrors.bond_tax = 'Can not have empty fields. Enter a value.'};
    if (presetObject.gilt_coupon < 0 || presetObject.gilt_coupon > 5) {newErrors.gilt_coupon = 'Can not be negative'};
    if (presetObject.ilb_coupon < 0 || presetObject.ilb_coupon > 5) {newErrors.ilb_coupon = 'Can not be negative'};
    if (presetObject.asset_mix_equity > 100 || presetObject.asset_mix_equity < 0) {newErrors.asset_mix_equity = 'Enter 0-100%'};
    if (isNaN(presetObject.asset_mix_equity)) {newErrors.asset_mix_equity = 'Can not have empty fields. Enter a value.'};
    if (presetObject.asset_mix_bond > 100 || presetObject.asset_mix_bond < 0) {newErrors.asset_mix_bond = 'Enter 0-100%'};
    if (isNaN(presetObject.asset_mix_bond)) {newErrors.asset_mix_bond = 'Can not have empty fields. Enter a value.'};
    if (presetObject.asset_mix_index_bond > 100 || presetObject.asset_mix_index_bond < 0) {newErrors.asset_mix_index_bond = 'Enter 0-100%'};
    if (isNaN(presetObject.asset_mix_index_bond)) {newErrors.asset_mix_index_bond = 'Can not have empty fields. Enter a value.'};
    // this is set to max of 99, otherwise there we will be a divide by zero error in the model
    if (presetObject.annuity_tax_rate < 0 || presetObject.annuity_tax_rate > 99) {newErrors.annuity_tax_rate = 'Enter 0-99%'};
    if (isNaN(presetObject.annuity_tax_rate)) {newErrors.annuity_tax_rate = 'Can not have empty fields. Enter a value.'};
    if (presetObject.annuity_tax_rate2 < 0 || presetObject.annuity_tax_rate2 > 100) {newErrors.annuity_tax_rate2 = 'Enter 0-100%'};
    if (isNaN(presetObject.annuity_tax_rate2)) {newErrors.annuity_tax_rate2 = 'Can not have empty fields. Enter a value.'};
    if (presetObject.annuity_tax_rate3 < 0 || presetObject.annuity_tax_rate3 > 100) {newErrors.annuity_tax_rate2 = 'Enter 0-100%'};
    if (isNaN(presetObject.annuity_tax_rate3)) {newErrors.annuity_tax_rate3 = 'Can not have empty fields. Enter a value.'};
    if (presetObject.asset_mix_bond_historic > 100 || presetObject.asset_mix_bond_historic < 0) {newErrors.asset_mix_bond_historic = 'Enter 0-100%'};
    if (presetObject.asset_mix_index_bond_historic > 100 || presetObject.asset_mix_index_bond_historic < 0) {newErrors.asset_mix_index_bond_historic = 'Enter 0-100%'};
    if (presetObject.asset_mix_index_bond > 0 && presetObject.data_option == "back") {setModalmessage('Can not have an allocation to index linked bonds if backtesting data is set to use historical data for bonds (as historic data does not go back far enough).  Either set index linked bond allocation to 0% or change backtesting data to use current market yields for bonds.'); setIsModalOpen(true); return false};
    if (presetObject.asset_mix_index_bond > 0 && presetObject.data_option == "backUSD") {setModalmessage('Can not have an allocation to index linked bonds if backtesting data is set to use historical data for bonds (as historic data does not go back far enough).  Either set index linked bond allocation to 0% or change backtesting data to use current market yields for bonds.'); setIsModalOpen(true); return false};
    if (presetObject.asset_mix_index_bond > 0 && presetObject.data_option == "backUSDshort") {setModalmessage('Can not have an allocation to index linked bonds if backtesting data is set to use historical data for bonds (as historic data does not go back far enough).  Either set index linked bond allocation to 0% or change backtesting data to use current market yields for bonds.'); setIsModalOpen(true); return false};
    if (((presetObject.asset_mix_equity) + (presetObject.asset_mix_bond) + (presetObject.asset_mix_index_bond)) !== 100) {
        setModalmessage('Check under Portfolio Asset Mix that asset mix weights add up to 100%'); setIsModalOpen(true);
        {newErrors.asset_mix_bond = 'Asset mix must sum to 100%'}};
    if (presetObject.target_withdrawal_percent < 0 || presetObject.target_withdrawal_percent > 100) {newErrors.target_withdrawal_percent = 'Enter 0-100%'};
    if (isNaN(presetObject.target_withdrawal_percent)) {newErrors.target_withdrawal_percent = 'Can not have empty fields. Enter a value.'};
    if (presetObject.start_simulation_age < 0) {newErrors.start_simulation_age = 'Can not be negative'};
    if (isNaN(presetObject.start_simulation_age)) {newErrors.start_simulation_age = 'Can not have empty fields. Enter a value.'};
    if (presetObject.end_simulation_age < 0) {newErrors.end_simulation_age = 'Can not be negative'};
    if (isNaN(presetObject.end_simulation_age)) {newErrors.end_simulation_age = 'Can not have empty fields. Enter a value.'};

    if (presetObject.year_retire < 0) {newErrors.year_retire = 'Can not be negative'};
    if (isNaN(presetObject.year_retire)) {newErrors.year_retire = 'Can not have empty fields. Enter a value.'};
    if (presetObject.year_retire < presetObject.start_simulation_age || presetObject.year_retire > presetObject.end_simulation_age) {newErrors.year_retire = 'Start withdrawal age needs to be within start simulation start age and end age'};
    
    if (presetObject.yale_weighting < 0 || presetObject.yale_weighting > 100) {newErrors.yale_weighting = 'Enter 0-100%'};
    if (presetObject.start_simulation_age >= presetObject.end_simulation_age) {newErrors.start_simulation_age = 'Start simulation age needs to be less than end simulation age'};
    if (isNaN(presetObject.yale_weighting)) {newErrors.yale_weighting = 'Can not have empty fields. Enter a value.'};
    if (presetObject.vanguard_decrease_floor < 0) {newErrors.vanguard_decrease_floor = 'Can not be negative'};
    if (isNaN(presetObject.vanguard_decrease_floor)) {newErrors.vanguard_decrease_floor = 'Can not have empty fields. Enter a value.'};
    if (presetObject.vanguard_increase_ceiling < 0) {newErrors.vanguard_increase_ceiling = 'Can not be negative'};
    if (isNaN(presetObject.vanguard_increase_ceiling)) {newErrors.vanguard_increase_ceiling = 'Can not have empty fields. Enter a value.'};
    if (presetObject.spread < 0) {newErrors.spread = 'Can not be negative'};
    if (isNaN(presetObject.spread)) {newErrors.spread = 'Can not have empty fields. Enter a value.'};
    if (isNaN(presetObject.equity_alpha)) {newErrors.equity_alpha = 'Can not have empty fields. Enter a value.'};
    if (isNaN(presetObject.fixed_income_alpha)) {newErrors.fixed_income_alpha = 'Can not have empty fields. Enter a value.'};
    if (presetObject.asset_portfolio.length > 50) {setModalmessage('The maximum number of Asset Portfolio items has been exceeded (50). Please review and reduce.'); setIsModalOpen(true); return false}
    if (presetObject.state_pension.length > 10) {setModalmessage('The maximum number of State Pension items has been exceeded (10). Please review and reduce.'); setIsModalOpen(true); return false}
    if (presetObject.occupational_pension.length > 10) {setModalmessage('The maximum number of State Pension items has been exceeded (10). Please review and reduce.'); setIsModalOpen(true); return false}
    if (presetObject.annuity_pension.length > 10) {setModalmessage('The maximum number of Annuity items has been exceeded (10). Please review and reduce.'); setIsModalOpen(true); return false}
    if (presetObject.annuity_pension.length > 10) {setModalmessage('The maximum number of Annuity items has been exceeded (10). Please review and reduce.'); setIsModalOpen(true); return false}
    if (presetObject.contribution_inflows.length > 10) {setModalmessage('The maximum number of Recurring Contribution items has been exceeded (10). Please review and reduce.'); setIsModalOpen(true); return false}
    if (presetObject.extraordinary_inflows.length > 10) {setModalmessage('The maximum number of One-Off Contribution items has been exceeded (10). Please review and reduce.'); setIsModalOpen(true); return false}
    if (presetObject.income_requirements.length > 20) {setModalmessage('The maximum number of Planned Expenditure items has been exceeded (20). Please review and reduce.'); setIsModalOpen(true); return false}
    if (presetObject.flex_income_requirements.length > 10) {setModalmessage('The maximum number of Bonus Expenditure items has been exceeded (10). Please review and reduce.'); setIsModalOpen(true); return false}

    presetObject.state_pension.forEach((instance) => {
        if (instance.year < presetObject.start_simulation_age) {warningMessages.push('Warning - state pension instance set to start before simulation start.')}
        else if (instance.year > presetObject.end_simulation_age) {warningMessages.push('Warning - state pension instance set to start after simulation end.')};
    });

    presetObject.occupational_pension.forEach((instance) => {
        if (instance.year < presetObject.start_simulation_age) {warningMessages.push('Warning - occupational pension instance set to start before simulation start.')}
        else if (instance.year > presetObject.end_simulation_age) {warningMessages.push('Warning - occupational pension instance set to start after simulation end.')};
    });

    presetObject.annuity_pension.forEach((instance) => {
        if (instance.year < presetObject.start_simulation_age) {warningMessages.push('Warning - annuity instance set to start before simulation start. Simulation will still run but results may not be as intended.')}
        else if (instance.year > presetObject.end_simulation_age) {warningMessages.push('Warning - annuity instance set to start after simulation end. Simulation will still run but results may not be as intended.')};
    });

    presetObject.extraordinary_inflows.forEach((instance) => {
        if (instance.year < presetObject.start_simulation_age) {warningMessages.push('Warning - extraordinary inflow before simulation start. Simulation will still run but results may not be as intended.')}
        else if (instance.year > presetObject.end_simulation_age) {warningMessages.push('Warning - extraordinary inflow after simulation end. Simulation will still run but results may not be as intended.')};
    });

    presetObject.contribution_inflows.forEach((instance) => {
        if (instance.year < presetObject.start_simulation_age) {warningMessages.push('Warning - inflow start before simulation start. Simulation will still run but results may not be as intended.')}
        else if (instance.year > presetObject.end_simulation_age) {warningMessages.push('Warning - inflow start after simulation end. Simulation will still run but results may not be as intended.')}
        if (instance.yearend < presetObject.start_simulation_age) {warningMessages.push('Warning - inflow end before simulation start. Simulation will still run but results may not be as intended.')}
        else if (instance.yearend > presetObject.end_simulation_age) {warningMessages.push('Warning - inflow end after simulation end. Simulation will still run but results may not be as intended.')};
    });

    presetObject.income_requirements.forEach((instance) => {
        if (instance.year < presetObject.start_simulation_age) {warningMessages.push('Warning - income requirement start before simulation start. Simulation will still run but results may not be as intended.')}
        else if (instance.year > presetObject.end_simulation_age) {warningMessages.push('Warning - income requirement start after simulation end. Simulation will still run but results may not be as intended.')}
        if (instance.yearend < presetObject.start_simulation_age) {warningMessages.push('Warning - income requirement end before simulation start. Simulation will still run but results may not be as intended.')}
        else if (instance.yearend > presetObject.end_simulation_age) {warningMessages.push('Warning - income requirement end after simulation end. Simulation will still run but results may not be as intended.')};
    });

    presetObject.flex_income_requirements.forEach((instance) => {
        if (instance.year < presetObject.start_simulation_age) {warningMessages.push("Warning - 'bonus' income requirement start before simulation start. Simulation will still run but results may not be as intended.")}
        else if (instance.year > presetObject.end_simulation_age) {warningMessages.push("Warning - 'bonus' income requirement start after simulation end. Simulation will still run but results may not be as intended.")}
        if (instance.yearend < presetObject.start_simulation_age) {warningMessages.push("Warning - 'bonus' income requirement end before simulation start. Simulation will still run but results may not be as intended.")}
        else if (instance.yearend > presetObject.end_simulation_age) {warningMessages.push("Warning - 'bonus' income requirement end after simulation end. Simulation will still run but results may not be as intended.")};
    });   

    if (warningMessages.length > 0) {
        const formattedMessages = warningMessages.map((msg, index) => `${index + 1}. ${msg}`).join('\n');
        setModalmessage(formattedMessages + ' Simulation will still run but results may not be as intended.');
        setIsModalOpen(true);
    }

    if (Object.keys(newErrors).length > 0) {
        setIsModalOpen(true);
        setModalmessage('Pre-simulation validation failed. Check inputs for errors.' );
        setErrors(newErrors); 
        return false};
    setErrors({}); 

    return (true)
    };

export default ValidateForm
