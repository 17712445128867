import React, { useEffect, useState, useRef, Component } from 'react'
import useAxios from '../utils/useAxios';
import ValidateForm from "../components/validation.js"
import ValidateFormOptimisation from "../components/validationforoptimisation.js"
import ValidatePortfolioForm from "../components/validation_portfolio.js"
import ValidateAssetDeduction from "../components/validation_assetdeduction.js"
import ValidateStatePensionForm from "../components/validation_statepension.js"
import ValidateOccupationalPensionForm from "../components/validation_occupationalpension.js"
import ValidateContributionForm from "../components/validation_contribution.js"
import ValidateExtraContributionForm from "../components/validation_extracontribution.js"
import ValidateAssetMixChangeForm from "../components/validation_assetmixchange.js"
import ValidateAnnuityForm from "../components/validation_annuity.js"
import ValidateIncomeForm from "../components/validation_income.js"
import ValidateFlexIncomeForm from "../components/validation_flexincome.js"
import MaxMinPlotChart from "../components/maxminchart.js"
import MasterLineChart from "../components/masterlinechart.js"
import DistTypeChart from "../components/drawbytypedistchart.js"
import DrawHistChart from "../components/drawhistchart.js"
import validatesanserrorsForm from "../components/validationsanserrors.js"
import PortfolioBreakdownPopup from "../components/portfoliobreakdownpopup.js"
import ButtonExample from "../components/loadingbutton.js";
import LogoBanner from "../components/logobanner.png"
import TradeOffAnalysis from "../components/tradeoffanalysis.js"
import PlanButtons from "../components/planbuttons.js";
import FormTemplate from "../components/formtemplate.js";
import FormTemplateLight from "../components/formtemplatelight.js";
import FormTemplateInteger from "../components/formtemplateinteger.js";
import ReLoginForm from '../views/relogin';
import { usePresetStore } from "../components/datastore.js";
import { useAuthStore } from '../store/auth'
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';
import Badge from 'react-bootstrap/Badge';
import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';
import ToggleButton from 'react-bootstrap/ToggleButton';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { Modal } from 'react-bootstrap';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import '../styles/mystyles.css'
import '../App.css' // Import the CSS file
import { FaInfoCircle } from 'react-icons/fa'; // Font Awesome info icon
import { useLocation } from 'react-router-dom';
import { SiReacthookform } from 'react-icons/si';


function CalcDash1() {
    const tempvar = false

    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 576);
    useEffect(() => {
      const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 576);
      };
      window.addEventListener('resize', handleResize);
      return () => {
      window.removeEventListener('resize', handleResize);
      };
    }, []);

    const webpageData = {
      "@context": "https://schema.org",
      "@type": "WebPage",
      "name": "Retire Smart Calc",
      "logo": "https://www.retiresmartcalc.com/logo.png",
      "description": "Retirement portfolio safe withdrawal rate (SWR) calculator. Back-test strategy and examine impact of asset mix, constant vs. flexible withdrawal strategies and purchase of an annuity.",
    };
    

    const api = useAxios();  // Ensure this is at the top level of your component or function
    const api_key = 'seh3599f@fUERkdf22&^2{u'
    const location = useLocation();
    const today = new Date().toISOString().split('T')[0];
    const presetObject = usePresetStore((state) => state.presetObject);
    const setPresetObject = usePresetStore((state) => state.setPresetObject);
    const resetPresetObject = usePresetStore((state) => state.resetPresetObject);
    const planId = usePresetStore((state) => state.planId);
    const setPlanId = usePresetStore((state) => state.setPlanId);
    const resetPlanId = usePresetStore((state) => state.resetPlanId);

    const autoRunSimulation = usePresetStore((state) => state.autoRunSimulation);
    const triggerAutoRunSimulation = usePresetStore((state) => state.triggerAutoRunSimulation);
    const resetAutoRunSimulation = usePresetStore((state) => state.resetAutoRunSimulation);
    const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

    // these are to help handle code sequencing with 'Start A Plan' questions
    const [redirectComplete, setRedirectComplete] = useState(false);  
    const [redirectCompleteFast, setRedirectCompleteFast] = useState(false);  
    const [redirectTwoComplete, setRedirectTwoComplete] = useState(false);  
        
    const logInState = useAuthStore().isLoggedIn();
    const userId = useAuthStore().user();
    const [yearList, setYearList] = useState([]);
    const [isInitialRender, setIsInitialRender] = useState(true);
    const [optrun, setOptrun] = useState(false);
    const [errors, setErrors] = useState({});
    const [result, setResult] = useState(100);
    const [decresult, setDecresult] = useState([0,0,0,0,0,0,0,0,0,0,0]);
    const [allresult, setAllresult] = useState([]);
    const [allresultGA, setAllresultGA] = useState([]);
    const [allresultTE, setAllresultTE] = useState([]);
    const [allresultTD, setAllresultTD] = useState([]);
    const [portfoliovaluepercentiles, setPortfoliovaluepercentiles] = useState([]);
    const [incomepercentiles, setIncomepercentiles] = useState([]);
    const [alldrawstreams, setAlldrawstreams] = useState([]);
    const [drawstreamsbytype, setDrawstreamsbytype] = useState([]);
    const [averagedraw, setAveragedraw] = useState(0.0);
    const [averageadjusteddraw, setAverageadjusteddraw] = useState(0.0);
    const [bestswrs, setBestswrs] = useState([]);
    const [safefunding, setSafefunding] = useState([]);
    const [safefundinglevel, setSafefundinglevel] = useState([]);
    const [resultyears, setResultyears] = useState([]);
    const [yearsForChart, setYearsForChart] = useState([]);
    const [yearsForChartShort, setYearsForChartShort] = useState([]);
    const [drawhistdata, setDrawhistdata] = useState({
      histogram: [],
      bins: [],
    });


    function convertToCSV(data) {
      const headers = Object.keys(data[0]).join(",") + "\n";
      const rows = data.map(row => Object.values(row).join(",")).join("\n");
      return headers + rows;
    }

    function downloadCSV(data, filename) {
      const csvData = convertToCSV(data);
      const blob = new Blob([csvData], { type: 'text/csv' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = filename;
      link.click();
      URL.revokeObjectURL(url);
    }

    const [currencyIcon, setCurrencyIcon] = useState('');

    useEffect(() => {
      setCurrencyIcon(presetObject.currency_set === 'GBP' ? '\u00A3' : '$');
    }, [presetObject.currency_set]);

    const [portfolioTotal,  setPortfolioTotal] = useState();
    const [sumdrawadjustedavg, setSumdrawadjustedavg] = useState(0.0)
    const [safeFundingPercentiles, setSafeFundingPercentiles] = useState([]);
    const [safeWithdrawalPercentiles, setSafeWithdrawalPercentiles] = useState([]);

    const [loading2, setLoading2] = useState(false);
    const [loading3, setLoading3] = useState(false);
    const [loading4, setLoading4] = useState(false);
    const [buttonlabel, setButtonlabel] = useState('Calculating...')
    const [switchvalue, setSwitchvalue] = useState(1)
    const handleSwitchChange = (val) => setSwitchvalue(val);
    const [switchvalue2, setSwitchvalue2] = useState(2)
    const handleSwitchChange2 = (val) => setSwitchvalue2(val);
    const [switchvalue3, setSwitchvalue3] = useState(1)
    const handleSwitchChange3 = (val) => setSwitchvalue3(val);    
    const [switchvalue4, setSwitchvalue4] = useState(2)
    const handleSwitchChange4 = (val) => setSwitchvalue4(val); 

    const [annuityTableData, setAnnuityTableData] = useState({})

    const [isAnnuityPriceModalOpen, setIsAnnuityPriceModalOpen] = useState(false);
    const openAnnuityPriceModal = () => {
      getAnnuityPrices();
      setIsAnnuityPriceModalOpen(true)}
    const closeAnnuityPriceModal = () => {setIsAnnuityPriceModalOpen(false)}  

    const getAnnuityPrices = async () => {
      let apiroute = ""
      try {
      setLoading4(true);
      apiroute = "annuitytable";
      const response = await fetch(`/api/${apiroute}/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-API-KEY': api_key, // Set the custom header here
          },
        body: JSON.stringify(presetObject),
      });
      const data = await response.json();
      console.log(data.results)
      setAnnuityTableData(data.results)
      } catch (error) {setModalmessage('Something went wrong.'); openModal();} finally {setLoading4(false)}
    }

    const [userLocation, setUserLocation] = useState('');

    useEffect(() => {
      const fetchUserLocation = async () => {
        let apiroute = ""
        try {
        apiroute = "getlocation";
        const response = await fetch(`/api/${apiroute}/`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'X-API-KEY': api_key, // Set the custom header here
            },
        });
        const data = await response.json();
        setUserLocation(data.country)
        // console.log(data.country)
        if (data.country == 'GB') {
          setPresetObject({['currency_set']: 'GBP'});
          setPresetObject({['geographic_set']: 'GLOBAL'});
        }
        } catch (error) {
          setModalmessage('Could not auto-detect location to set currency denomination. Check and set in Simulation data options.'); openModal();} 
      }
      fetchUserLocation();
    }, []);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const openModal = () => {setIsModalOpen(true)};
    const closeModal = () => {setIsModalOpen(false)};

    const [isReLoginModalOpen, setIsReLoginModalOpen] = useState(false);
    const openReLoginModal = () => setIsReLoginModalOpen(true);
    const closeReLoginModal = () => setIsReLoginModalOpen(false);

    const [isAssetModalOpen, setIsAssetModalOpen] = useState(false);
    const openAssetModal = () => {setIsAssetModalOpen(true)};
    const closeAssetModal = () => {setIsAssetModalOpen(false)};

    const [isStateModalOpen, setIsStateModalOpen] = useState(false);
    const openStateModal = () => {setIsStateModalOpen(true)};
    const closeStateModal = () => {setIsStateModalOpen(false)};

    const [isOccupationalModalOpen, setIsOccupationalModalOpen] = useState(false);
    const openOccupationalModal = () => {setIsOccupationalModalOpen(true)};
    const closeOccupationalModal = () => {setIsOccupationalModalOpen(false)};   

    const [isContributionModalOpen, setIsContributionModalOpen] = useState(false);
    const openContributionModal = () => {setIsContributionModalOpen(true)};
    const closeContributionModal = () => {setIsContributionModalOpen(false)}; 

    const [isExtraordinaryModalOpen, setIsExtraordinaryModalOpen] = useState(false);
    const openExtraordinaryModal = () => {setIsExtraordinaryModalOpen(true)};
    const closeExtraordinaryModal = () => {setIsExtraordinaryModalOpen(false)};  

    const [isIncomeModalOpen, setIsIncomeModalOpen] = useState(false);
    const openIncomeModal = () => {setIsIncomeModalOpen(true)};
    const closeIncomeModal = () => {setIsIncomeModalOpen(false)};  

    const [isBonusModalOpen, setIsBonusModalOpen] = useState(false);
    const openBonusModal = () => {setIsBonusModalOpen(true)};
    const closeBonusModal = () => {setIsBonusModalOpen(false)};  
    
    const [isAnnuityModalOpen, setIsAnnuityModalOpen] = useState(false);
    const openAnnuityModal = () => {setIsAnnuityModalOpen(true)};
    const closeAnnuityModal = () => {setIsAnnuityModalOpen(false)};  

    const [isPortfolioModalOpen, setIsPortfolioModalOpen] = useState(false);
    const openPortfolioModal = () => {setIsPortfolioModalOpen(true)};
    const closePortfolioModal = () => {setIsPortfolioModalOpen(false)};

    const [isResetModalOpen, setIsResetModalOpen] = useState(false);
    const [modalResetMessage, setModalResetMessage] = useState('Are you sure you want to reset the data? This action cannot be undone.');
    const openResetModal = () => {setIsResetModalOpen(true)};
    const closeResetModal = () => {setIsResetModalOpen(false)};
    
    const [modalmessage, setModalmessage] = useState();

    const [inPortfolioEquity, setInPortfolioEquity] = useState({});
    const [inPortfolioBonds, setInPortfolioBonds] = useState({});
    const [inPortfolioIndex, setInPortfolioIndex] = useState({});
    const [inPortfolioTotal, setInPortfolioTotal] = useState({});

    const [plans, setPlans] = useState([]);
    const [selectedPlanId, setSelectedPlanId] = useState(null); // State for selected plan ID for deletion

    const [isRetrieveModalOpen, setIsRetrieveModalOpen] = useState(false);
    const openRetrieveModal = () => {setIsRetrieveModalOpen(true)};
    const closeRetrieveModal = () => {setIsRetrieveModalOpen(false)};

    const [showDeleteModal, setShowDeleteModal] = useState(false); // State for delete modal visibility

    const [isAddAssetModalOpen, setIsAddAssetModalOpen] = useState(false);
    const openAddAssetModal = () => setIsAddAssetModalOpen(true);
    const closeAddAssetModal = () => setIsAddAssetModalOpen(false);

    const [isAddStateModalOpen, setIsAddStateModalOpen] = useState(false);
    const openAddStateModal = () => setIsAddStateModalOpen(true);
    const closeAddStateModal = () => setIsAddStateModalOpen(false);

    const [isAddOccupationModalOpen, setIsAddOccupationModalOpen] = useState(false);
    const openAddOccupationModal = () => setIsAddOccupationModalOpen(true);
    const closeAddOccupationModal = () => setIsAddOccupationModalOpen(false);

    const [isAddContributionModalOpen, setIsAddContributionModalOpen] = useState(false);
    const openAddContributionModal = () => setIsAddContributionModalOpen(true);
    const closeAddContributionModal = () => setIsAddContributionModalOpen(false);
    
    const [isAddExtraordinaryModalOpen, setIsAddExtraordinaryModalOpen] = useState(false);
    const openAddExtraordinaryModal = () => setIsAddExtraordinaryModalOpen(true);
    const closeAddExtraordinaryModal = () => setIsAddExtraordinaryModalOpen(false);

    const [isAddIncomeRequirementsModalOpen, setIsAddIncomeRequirementsModalOpen] = useState(false);
    const openAddIncomeRequirementsModal = () => setIsAddIncomeRequirementsModalOpen(true);
    const closeAddIncomeRequirementsModal = () => setIsAddIncomeRequirementsModalOpen(false);

    const [isAddFlexIncomeRequirementsModalOpen, setIsAddFlexIncomeRequirementsModalOpen] = useState(false);
    const openAddFlexIncomeRequirementsModal = () => setIsAddFlexIncomeRequirementsModalOpen(true);
    const closeAddFlexIncomeRequirementsModal = () => setIsAddFlexIncomeRequirementsModalOpen(false);

    const [isAssetMixChangeModalOpen, setIsAssetMixChangeModalOpen] = useState(false);
    const openAssetMixChangeModal = () => setIsAssetMixChangeModalOpen(true);
    const closeAssetMixChangeModal = () => setIsAssetMixChangeModalOpen(false);

    const [isAssetMixChangeEntryModalOpen, setIsAssetMixChangeEntryModalOpen] = useState(false);
    const openAssetMixChangeEntryModal = () => setIsAssetMixChangeEntryModalOpen(true);
    const closeAssetMixChangeEntryModal = () => setIsAssetMixChangeEntryModalOpen(false);
    
    const [isAddAnnuityModalOpen, setIsAddAnnuityModalOpen] = useState(false);
    const openAddAnnuityModal = () => setIsAddAnnuityModalOpen(true);
    const closeAddAnnuityModal = () => setIsAddAnnuityModalOpen(false);

    const [isTaxesModalOpen, setIsTaxesModalOpen] = useState(false);
    const openTaxesModal = () => setIsTaxesModalOpen(true);
    const closeTaxesModal = () => setIsTaxesModalOpen(false);

    const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false);
    const openSettingsModal = () => setIsSettingsModalOpen(true);
    const closeSettingsModal = () => setIsSettingsModalOpen(false);
   
    const [isDataChoiceModalOpen, setIsDataChoiceModalOpen] = useState(false);
    const openDataChoiceModal = () => setIsDataChoiceModalOpen(true);
    const closeDataChoiceModal = () => setIsDataChoiceModalOpen(false);

    const [isAssetDeductionModalOpen, setIsAssetDeductionModalOpen] = useState(false);
    const openAssetDeductionModal = () => {
      setIsAssetDeductionModalOpen(true);
      setDeductionEqGA(presetObject.asset_deduction[0].equity_ga); setDeductionBondGA(presetObject.asset_deduction[0].bonds_ga); setDeductionIndexGA(presetObject.asset_deduction[0].index_ga);
      setDeductionEqTE(presetObject.asset_deduction[0].equity_te); setDeductionBondTE(presetObject.asset_deduction[0].bonds_te); setDeductionIndexTE(presetObject.asset_deduction[0].index_te);
      setDeductionEqTD(presetObject.asset_deduction[0].equity_td); setDeductionBondTD(presetObject.asset_deduction[0].bonds_td); setDeductionIndexTD(presetObject.asset_deduction[0].index_td);
    }

    const closeAssetDeductionModal = () => setIsAssetDeductionModalOpen(false);  

    const [showDateModal, setShowDateModal] = useState(false)

    const [modifyingEntry, setModifyingEntry] = useState(false);

    const [firstInflationIndex, setFirstInflationIndex] = useState(1)
    const [secondInflationIndex, setSecondInflationIndex] = useState(1)
    const [firstDateFound, setFirstDateFound] = useState('')
    const [secondDateFound, setSecondDateFound] = useState('')
    const [updateMessage, setUpdateMessage] = useState(false)
    const [updateMessageDate, setUpdateMessageDate] = useState('')

    const [isIndexAdjustModalOpen, setIsIndexAdjustModalOpen] = useState(false);
    const openIndexAdjustModal = () => {
      const fetchData = async () => {
        try {
          const apiroute = "getinflationdata";
          const response = await fetch(`/api/${apiroute}/`, {
            method: 'POST',
            headers: {
            'Content-Type': 'application/json',
            'X-API-KEY': api_key, // Set the custom header here
            },
            body: JSON.stringify({'first_date': incomeDate, 'second_date': today, 'currency_set': presetObject.currency_set}),
          });
          const response_data = await response.json();
          if (new Date(response_data.second_date_found) > new Date(incomeDate)) {
          setFirstInflationIndex(response_data.first_inflation_index)
          setSecondInflationIndex(response_data.second_inflation_index)
          setFirstDateFound(response_data.first_date_found)
          setSecondDateFound(response_data.second_date_found)
          setUpdateMessage(true)
          } else {
          setFirstInflationIndex(1)
          setSecondInflationIndex(1)
          setFirstDateFound('')
          setSecondDateFound('')    
          setUpdateMessage(false)
          setUpdateMessageDate(response_data.second_date_found)        
          }
        } catch (error) {
          console.log(error.message);
        } 
      };
      fetchData();
      setIsIndexAdjustModalOpen(true);
    }
    const closeIndexAdjustModal = () => setIsIndexAdjustModalOpen(false);

    const closeIndexAdjustModalUpdate = () => {
      setIsIndexAdjustModalOpen(false)
      setIncomeAmount(parseFloat((incomeAmount * secondInflationIndex / firstInflationIndex).toFixed(2)))
      setIncomeDate(secondDateFound)
    };
    
    const getPlans = async () => {
      try {
          const response = await api.get('/getplans/');
          const data = response.data;
          setPlans(data.plans);
          openRetrieveModal();
      } catch (error) {
          // ideally would have a mechanism here to identify type 400 or 401 errors and auto relogin with openReLoginModal() but because the error is triggered inside axiom interceptor, the required error.response.status isn't passed to this point
          // openReLoginModal();
          setModalmessage('An unexpected error occurred. Check network connection. If working, try logging out and logging in again.');
          openModal();
        }
   };

    const handleOpenDeleteModal = (planIdNumber) => {
      setSelectedPlanId(planIdNumber); // Set the planId to delete
      setShowDeleteModal(true); // Show the confirmation modal
    };
  
    const handleCloseDeleteModal = () => {
      setSelectedPlanId(null); // Clear selected plan ID
      setShowDeleteModal(false); // Close the confirmation modal
    };

    const deletePlan = async (planIdNumber) => {
      try {
        // Send DELETE request as a POST request with planIdNumber in the body
        const response = await api.post('/deleteplan/', { plan_id: planIdNumber });
        // Update plans by removing the deleted plan
        setPlans((prevPlans) => prevPlans.filter((plan) => plan.id !== planIdNumber));
        // Reset the state if the deleted plan was the currently active one
        if (planIdNumber === planId) {
          resetPlanId();
          resetPresetObject();
        }
      } catch (error) {     
          // openReLoginModal();
          setModalmessage('An unexpected error occurred. Check network connection. If working, try logging out and logging in again.');
          openModal();
      } 
      handleCloseDeleteModal();
    };

    const retrievePlan = async (planIdNumber) => {
        try {
          const response = await api.post('/retrieve/', { plan_id: planIdNumber });
          const data = response.data;
          if (response.status === 200) {  
            {setPresetObject({ 
              ['plan_title']: data.plan_title,
              ['plan_date']: data.plan_date,
              ['data_start_year']: data.data_start_year,
              ['data_end_year']: data.data_end_year,
              ['currency_set']: data.currency_set,
              ['geographic_set']: data.geographic_set,
              ['equity_tax']: data.equity_tax,
              ['bond_tax']: data.bond_tax,
              ['draw_tax']: data.draw_tax,
              ['bond_coupon']: data.bond_coupon,
              ['index_bond_coupon']: data.index_bond_coupon,
              ['asset_mix_equity']: data.asset_mix_equity,
              ['asset_mix_bond']: data.asset_mix_bond,
              ['asset_mix_index_bond']: data.asset_mix_index_bond,
              ['asset_mix_source']: data.asset_mix_source,
              ['data_option']: data.data_option,
              ['data_direction']: data.data_direction,
              ['dynamic_option']: data.dynamic_option,
              // ['withdrawal_percent']: data.withdrawal_percent,
              ['target_withdrawal_percent']: data.target_withdrawal_percent,
              ['net_other_income']: data.net_other_income,
              ['yale_weighting']: data.yale_weighting,
              ['vanguard_decrease_floor']: data.vanguard_decrease_floor,
              ['vanguard_increase_ceiling']: data.vanguard_increase_ceiling,
              ['annuity_tax_rate']: data.annuity_tax_rate,
              ['annuity_tax_rate2']: data.annuity_tax_rate2,
              ['annuity_tax_rate3']: data.annuity_tax_rate3,
              ['fees']: data.fees,
              ['spread']: data.spread,
              ['equity_alpha']: data.equity_alpha,
              ['fixed_income_alpha']: data.fixed_income_alpha,
              ['start_simulation_age']: data.start_simulation_age,
              ['end_simulation_age']: data.end_simulation_age,
              ['year_retire']: data.year_retire,
              ['circular_simulation']: data.circular_simulation,
              ['extraordinary_inflows']: data.extraordinary_inflows,
              ['state_pension']: data.state_pension,
              ['occupational_pension']: data.occupational_pension,
              ['annuity_pension']: data.annuity_pension,
              ['contribution_inflows']: data.contribution_inflows,
              ['income_requirements']: data.income_requirements,
              ['ladder_income_requirements']: data.ladder_income_requirements,
              ['flex_income_requirements']: data.flex_income_requirements,
              ['asset_portfolio']: data.asset_portfolio,
              ['asset_deduction']: data.asset_deduction,
              ['apply_tax_to_inflation']: data.apply_tax_to_inflation,
              ['bond_ladder']: data.bond_ladder,
              ['bond_matching_option']: data.bond_matching_option,
              ['ladder_tax']: data.ladder_tax,
              ['annuity_price_scale']: data.annuity_price_scale,
              ['asset_mix_changes']: data.asset_mix_changes,
              ['bond_portfolio']: data.bond_portfolio,
            })};
            setPlanId(planIdNumber);
            closeRetrieveModal();
            // setModalmessage('Portfolio data retrieved.');
            // openModal()
          };
        } catch (error) {
          // openReLoginModal();
          setModalmessage('An unexpected error occurred. Check network connection. If still not working, try logging out and logging in again.');
          openModal();
      };
    };

        const checkNumberPlans = async () => {
          try {
            const response = await api.get('/getplans/'); // Fetch the list of plans
            const data = response.data;
            const response2 = await api.get('/userinformation/');  // Fetch the user's permitted number of plans
            const permittedNumberOfPlans = response2.data
            const numberOfPlans = data.plans.length;
            if (numberOfPlans < permittedNumberOfPlans.userinformation.number_plans) {return true} else {return false}
          } catch (error) {
            // console.error('Error fetching plans:', error);
            return 0; // In case of an error, return 0 (or handle this case as you see fit)
          }
        };

        const savePlan = () => {
          if (presetObject.plan_date !== today) {setShowDateModal(true)} else {handleDateModalClose(false)}
          // setShowDateModal(true);
        };

        const handleDateModalClose = async (choice) => {
          setShowDateModal(false);
          if (ValidateForm(presetObject, setErrors, setIsModalOpen, setModalmessage)) {
            try {
              const saveAllowed = await checkNumberPlans();
              if (saveAllowed === false) {
                setModalmessage('You have exceeded the permitted number of plans. Please delete a plan before saving a new plan.')
                openModal()
              } else {
              let requestData;
              if (choice === true) {
                setPresetObject({['plan_date']: today})
                requestData = {
                  ...presetObject,
                  ...(planId && { planId }),  // Include planId if it's not null or undefined
                  plan_date: today  // Add this only if needed
                };
              } else {
                requestData = {
                  ...presetObject,
                  ...(planId && { planId })  // Include planId if it's not null or undefined
                };
              }
              const response = await api.post('/save/', requestData);
              if (response.status === 200) {  
                setModalmessage('Portfolio data saved.');
                setPlanId(response.data.plan_id)
                openModal();
              }
            }
            } catch (error) {
          // openReLoginModal();
          setModalmessage('An unexpected error occurred. Check network connection. If working, try logging out and logging in again.');
          openModal();
          }
          }
        };

        const copyPlan = async () => {
          if (ValidateForm(presetObject, setErrors, setIsModalOpen, setModalmessage)) {
            try {
              const saveAllowed = await checkNumberPlans();
              if (saveAllowed === false) {
                setModalmessage('You have exceeded the permitted number of plans. Please delete a plan before saving a new plan.')
                openModal()
              } else {
              const requestData = {
                ...presetObject,
                // ...(planId && { planId })  // Include planId if it's not null or undefined
              };
              const response = await api.post('/save/', requestData);
        
              if (response.status === 200) {  
                setModalmessage('Portfolio data saved as a new plan.');
                setPlanId(response.data.plan_id)
                openModal();
              }}
            } catch (error) {
          // openReLoginModal();
          setModalmessage('An unexpected error occurred. Check network connection. If working, try logging out and logging in again.');
          openModal();
          }
          }
        };

    const resetPlan = () => {
      resetPresetObject();
      resetPlanId();
      setPortfolioTotal();
      setPortfoliovaluepercentiles([]);
      setSafeFundingPercentiles([]);
      setSafeWithdrawalPercentiles([]);
      setIncomepercentiles([]);
      setAveragedraw(0.0);
      setAverageadjusteddraw(0.0);
      closeResetModal();
    };

    const [portfolioName, setPortfolioName] = useState('');
    const [portfolioType, setPortfolioType] = useState('0');
    const [portfolioAmount, setPortfolioAmount] = useState('');
    const [portfolioEquity, setPortfolioEquity] = useState('');
    const [portfolioBonds, setPortfolioBonds] = useState('');
    const [portfolioIndex, setPortfolioIndex] = useState('');
    const [portfolioDate, setPortfolioDate] = useState(today);

    const handlePortfolioChange = () => {
      var portfolioweightsum = parseInt(portfolioEquity) + parseInt(portfolioBonds) + parseInt(portfolioIndex)
      var numberPortfolioItems = presetObject.asset_portfolio?.length || 0;
      if (ValidatePortfolioForm(numberPortfolioItems, portfolioName, portfolioAmount, portfolioEquity, portfolioBonds, portfolioIndex, portfolioDate, setErrors, setIsModalOpen, setModalmessage)) {
        setPresetObject({asset_portfolio: [...presetObject.asset_portfolio, {name: portfolioName, type: portfolioType, amount: portfolioAmount, equity: portfolioEquity, bonds: portfolioBonds, index: portfolioIndex, last_update_date: portfolioDate}]});
        setPortfolioName('');
        setPortfolioType('0');
        setPortfolioAmount('');
        setPortfolioEquity('');
        setPortfolioBonds('');
        setPortfolioIndex('');
        setPortfolioDate(today);
        setModifyingEntry(false);
        closeAssetModal();
      };
    };    
    
    const [deductionEqGA, setDeductionEqGA] = useState(presetObject.asset_deduction[0].equity_ga);
    const [deductionBondGA, setDeductionBondGA] = useState(presetObject.asset_deduction[0].bonds_ga);
    const [deductionIndexGA, setDeductionIndexGA] = useState(presetObject.asset_deduction[0].index_ga);
    const [deductionEqTE, setDeductionEqTE] = useState(presetObject.asset_deduction[0].equity_te);
    const [deductionBondTE, setDeductionBondTE] = useState(presetObject.asset_deduction[0].bonds_te);
    const [deductionIndexTE, setDeductionIndexTE] = useState(presetObject.asset_deduction[0].index_te);
    const [deductionEqTD, setDeductionEqTD] = useState(presetObject.asset_deduction[0].equity_td);
    const [deductionBondTD, setDeductionBondTD] = useState(presetObject.asset_deduction[0].bonds_td);
    const [deductionIndexTD, setDeductionIndexTD] = useState(presetObject.asset_deduction[0].index_td);  

    const handleAssetDeductionChange = () => {
      // Check and set values to 0 if null or empty
      const finalDeductionEqGA = deductionEqGA === null || deductionEqGA === '' ? 0 : deductionEqGA;
      const finalDeductionBondGA = deductionBondGA === null || deductionBondGA === '' ? 0 : deductionBondGA;
      const finalDeductionIndexGA = deductionIndexGA === null || deductionIndexGA === '' ? 0 : deductionIndexGA;
      const finalDeductionEqTE = deductionEqTE === null || deductionEqTE === '' ? 0 : deductionEqTE;
      const finalDeductionBondTE = deductionBondTE === null || deductionBondTE === '' ? 0 : deductionBondTE;
      const finalDeductionIndexTE = deductionIndexTE === null || deductionIndexTE === '' ? 0 : deductionIndexTE;
      const finalDeductionEqTD = deductionEqTD === null || deductionEqTD === '' ? 0 : deductionEqTD;
      const finalDeductionBondTD = deductionBondTD === null || deductionBondTD === '' ? 0 : deductionBondTD;
      const finalDeductionIndexTD = deductionIndexTD === null || deductionIndexTD === '' ? 0 : deductionIndexTD;
    
      // Proceed with validation after setting defaults to zero
      if (ValidateAssetDeduction(
        finalDeductionEqGA, finalDeductionBondGA, finalDeductionIndexGA, 
        finalDeductionEqTE, finalDeductionBondTE, finalDeductionIndexTE, 
        finalDeductionEqTD, finalDeductionBondTD, finalDeductionIndexTD, 
        setErrors, setIsModalOpen, setModalmessage)) {
          setPresetObject({
            asset_deduction: [{
              equity_ga: finalDeductionEqGA, 
              bonds_ga: finalDeductionBondGA, 
              index_ga: finalDeductionIndexGA, 
              equity_te: finalDeductionEqTE, 
              bonds_te: finalDeductionBondTE, 
              index_te: finalDeductionIndexTE, 
              equity_td: finalDeductionEqTD, 
              bonds_td: finalDeductionBondTD, 
              index_td: finalDeductionIndexTD
            }]
          });
          closeAssetDeductionModal();
      }
    };

    const [extraName, setExtraName] = useState('');
    const [extraYear, setExtraYear] = useState('');
    const [extraAmount, setExtraAmount] = useState('');
    const [extraIndex, setExtraIndex] = useState('1');
    const [extraIncrease, setExtraIncrease] = useState('0');
    const [extraType, setExtraType] = useState('0');
    const [extraDate, setExtraDate] = useState(today);

    const handleExtraInflowChange = () => {
      var numberExtraInflowItems = presetObject.extraordinary_inflows?.length || 0;
      if (ValidateExtraContributionForm(numberExtraInflowItems, presetObject, extraName, extraYear, extraAmount, extraIncrease, extraDate, setErrors, setIsModalOpen, setModalmessage)) { 
        setPresetObject({extraordinary_inflows: [...presetObject.extraordinary_inflows, {name: extraName, year: extraYear, amount: extraAmount, index: extraIndex, increase: extraIncrease, type: extraType, last_update_date: extraDate}]});
        setExtraName('');
        setExtraYear('');
        setExtraAmount('');
        setExtraIndex('1');
        setExtraIncrease(0);
        setExtraType('0');
        setExtraDate(today);
        setModifyingEntry(false);
        closeExtraordinaryModal();
      };
    };

    const [stateName, setStateName] = useState('');
    const [stateYear, setStateYear] = useState('');
    const [stateAmount, setStateAmount] = useState('');
    const [stateIndex, setStateIndex] = useState('1');
    const [stateIncrease, setStateIncrease] = useState('0');
    const [stateDate, setStateDate] = useState(today);

    const handleStatePensionChange = () => {
      var numberStatePensionItems = presetObject.state_pension?.length || 0;
      if (ValidateStatePensionForm(numberStatePensionItems, presetObject, stateName, stateYear, stateAmount, stateIncrease, stateDate, setErrors, setIsModalOpen, setModalmessage)) {
        setPresetObject({state_pension: [...presetObject.state_pension, {name: stateName, year: stateYear, amount: stateAmount, index: stateIndex, increase: stateIncrease, last_update_date: stateDate}]});
        setStateName('');
        setStateYear('');
        setStateAmount('');
        setStateIndex('1');
        setStateIncrease(0);
        setStateDate(today);
        setModifyingEntry(false);
        closeStateModal();
      };
    };

    const [occupationalName, setOccupationalName] = useState('');
    const [occupationalYear, setOccupationalYear] = useState('');
    const [occupationalAmount, setOccupationalAmount] = useState('');
    const [occupationalIndex, setOccupationalIndex] = useState('1');
    const [occupationalIncrease, setOccupationalIncrease] = useState('0');
    const [occupationalDate, setOccupationalDate] = useState(today);

    const handleOccupationalPensionChange = () => {
      var numberOccupationalPensionItems = presetObject.occupational_pension?.length || 0;
        if (ValidateOccupationalPensionForm(numberOccupationalPensionItems, presetObject, occupationalName, occupationalYear, occupationalAmount, occupationalIncrease, occupationalDate, setErrors, setIsModalOpen, setModalmessage)) {
          setPresetObject({occupational_pension: [...presetObject.occupational_pension, {name: occupationalName, year: occupationalYear, amount: occupationalAmount, index: occupationalIndex, increase: occupationalIncrease, last_update_date: occupationalDate}]});
          setOccupationalName('');
          setOccupationalYear('');
          setOccupationalAmount('');
          setOccupationalIndex('1');
          setOccupationalIncrease(0);
          setOccupationalDate(today);
          setModifyingEntry(false);
          closeOccupationalModal();
      };
    };  

    const [annuityName, setAnnuityName] = useState('');
    const [annuityYear, setAnnuityYear] = useState('');
    const [annuityAmount, setAnnuityAmount] = useState('');
    const [annuityPrice, setAnnuityPrice] = useState('');
    const [annuityDeferred, setAnnuityDeferred] = useState('0');
    const [annuityIndex, setAnnuityIndex] = useState('1');
    const [annuityIncrease, setAnnuityIncrease] = useState('0');
    const [annuityDate, setAnnuityDate] = useState(today);

    const handleAnnuityPensionChange = () => {
      var numberAnnuityPensionItems = presetObject.annuity_pension?.length || 0;
      if (ValidateAnnuityForm(numberAnnuityPensionItems, presetObject, annuityName, annuityYear, annuityAmount, annuityPrice, annuityIncrease, annuityDate, annuityDeferred, setErrors, setIsModalOpen, setModalmessage)) {
        setPresetObject({annuity_pension: [...presetObject.annuity_pension, {name: annuityName, year: annuityYear, amount: annuityAmount, price: annuityPrice, deferred: annuityDeferred, index: annuityIndex, increase: annuityIncrease, last_update_date: annuityDate}]});
        setAnnuityName('');
        setAnnuityYear('');
        setAnnuityAmount('');
        setAnnuityPrice('');
        setAnnuityDeferred('0');
        setAnnuityIndex('1');
        setAnnuityIncrease(0);
        setAnnuityDate(today);
        setModifyingEntry(false);
        closeAnnuityModal();
      };
    };

    const [contributionName, setContributionName] = useState('');
    const [contributionYear, setContributionYear] = useState('');
    const [contributionYearEnd, setContributionYearEnd] = useState('');
    const [contributionAmount, setContributionAmount] = useState('');
    const [contributionIndex, setContributionIndex] = useState('1');
    const [contributionIncrease, setContributionIncrease] = useState('0');
    const [contributionType, setContributionType] = useState('0');
    const [contributionDate, setContributionDate] = useState(today);

    const handleContributionChange = () => {
      var numberContributionItems = presetObject.contribution_inflows?.length || 0;
        if (ValidateContributionForm(numberContributionItems, presetObject, contributionName, contributionYear, contributionYearEnd, contributionAmount, contributionIncrease, contributionDate, setErrors, setIsModalOpen, setModalmessage)) { 
        setPresetObject({contribution_inflows: [...presetObject.contribution_inflows, {name: contributionName, year: contributionYear, yearend: contributionYearEnd, amount: contributionAmount, index: contributionIndex, increase: contributionIncrease, type: contributionType, last_update_date: contributionDate}]});
        setContributionName('');
        setContributionYear('');
        setContributionYearEnd('');
        setContributionAmount('');
        setContributionIndex('1');
        setContributionIncrease(0);
        setContributionType('0');
        setContributionDate(today);
        setModifyingEntry(false);
        closeContributionModal();
      };
    };  

    const [incomeName, setIncomeName] = useState('');
    const [incomeYear, setIncomeYear] = useState('');
    const [incomeYearEnd, setIncomeYearEnd] = useState('');
    const [incomeAmount, setIncomeAmount] = useState('');
    const [incomeIndex, setIncomeIndex] = useState('1');
    const [incomeIncrease, setIncomeIncrease] = useState('0');
    const [incomeIncrease2, setIncomeIncrease2] = useState('0');
    const [incomeDate, setIncomeDate] = useState(today);
    const [incomeSingleYear, setIncomeSingleYear] = useState('0');
    const [incomePercentCovered, setIncomePercentCovered] = useState(100);
    const [incomeJanuary, setIncomeJanuary] = useState('1');
    const [incomeFebruary, setIncomeFebruary] = useState('1');
    const [incomeMarch, setIncomeMarch] = useState('1');
    const [incomeApril, setIncomeApril] = useState('1');
    const [incomeMay, setIncomeMay] = useState('1');
    const [incomeJune, setIncomeJune] = useState('1');
    const [incomeJuly, setIncomeJuly] = useState('1');
    const [incomeAugust, setIncomeAugust] = useState('1');
    const [incomeSeptember, setIncomeSeptember] = useState('1');
    const [incomeOctober, setIncomeOctober] = useState('1');
    const [incomeNovember, setIncomeNovember] = useState('1');
    const [incomeDecember, setIncomeDecember] = useState('1');
    const [incomeStartDate, setIncomeStartDate] = useState(today);
    const [incomeEndDate, setIncomeEndDate] = useState(today);

    const handleIncomeChange = () => {
      var numberIncomeItems = presetObject.income_requirements?.length || 0;
        if (ValidateIncomeForm(numberIncomeItems, presetObject, incomeName, incomeYear, incomeYearEnd, incomeAmount, incomeIncrease, incomeIncrease2, incomeDate, incomePercentCovered, setErrors, setIsModalOpen, setModalmessage)) { 
        if (incomeIndex === '0' || incomeIndex === '1') {setPresetObject({income_requirements: [...presetObject.income_requirements, {name: incomeName, year: incomeYear, yearend: incomeYearEnd, amount: incomeAmount, index: incomeIndex, increase: incomeIncrease, increase2: incomeIncrease, last_update_date: incomeDate, single_year: incomeSingleYear, percent_covered: incomePercentCovered, january: incomeJanuary, february: incomeFebruary, march: incomeMarch, april: incomeApril, may: incomeMay, june: incomeJune, july: incomeJuly, august: incomeAugust, september: incomeSeptember, october: incomeOctober, november: incomeNovember, december: incomeDecember, date_start: incomeStartDate, date_end: incomeEndDate}]})} else {setPresetObject({...presetObject, income_requirements: [...presetObject.income_requirements, {name: incomeName, year: incomeYear, yearend: incomeYearEnd, amount: incomeAmount, index: incomeIndex, increase: incomeIncrease, increase2: incomeIncrease2, last_update_date: incomeDate, single_year: incomeSingleYear, percent_covered: incomePercentCovered, january: incomeJanuary, february: incomeFebruary, march: incomeMarch, april: incomeApril, may: incomeMay, june: incomeJune, july: incomeJuly, august: incomeAugust, september: incomeSeptember, october: incomeOctober, november: incomeNovember, december: incomeDecember, date_start: incomeStartDate, date_end: incomeEndDate}]})};
        setIncomeName('');
        setIncomeYear('');
        setIncomeYearEnd('');
        setIncomeAmount('');
        setIncomeIndex('1');
        setIncomeIncrease('0');
        setIncomeIncrease2('0');
        setIncomeDate(today);
        setIncomeSingleYear('0')
        setIncomePercentCovered(100);
        setIncomeJanuary('1');
        setIncomeFebruary('1');
        setIncomeMarch('1');
        setIncomeApril('1');
        setIncomeMay('1');
        setIncomeJune('1');
        setIncomeJuly('1');
        setIncomeAugust('1');
        setIncomeSeptember('1');
        setIncomeOctober('1');
        setIncomeNovember('1');
        setIncomeDecember('1');
        setIncomeStartDate(today);
        setIncomeEndDate(today);  
        setModifyingEntry(false);
        closeIncomeModal();
      };
    }; 

    const [flexIncomeName, setFlexIncomeName] = useState('');
    const [flexIncomeYear, setFlexIncomeYear] = useState('');
    const [flexIncomeYearEnd, setFlexIncomeYearEnd] = useState('');
    const [flexIncomeAmount, setFlexIncomeAmount] = useState('');
    const [flexIncomeIndex, setFlexIncomeIndex] = useState('1');
    const [flexIncomeIncrease, setFlexIncomeIncrease] = useState('0');
    const [flexIncomeDate, setFlexIncomeDate] = useState(today);

    const handleFlexIncomeChange = () => {
      var numberFlexIncomeItems = presetObject.flex_income_requirements?.length || 0;
        if (ValidateFlexIncomeForm(numberFlexIncomeItems, presetObject, flexIncomeName, flexIncomeYear, flexIncomeYearEnd, flexIncomeAmount, flexIncomeIncrease, flexIncomeDate, setErrors, setIsModalOpen, setModalmessage)) { 
        setPresetObject({flex_income_requirements: [...presetObject.flex_income_requirements, {name: flexIncomeName, year: flexIncomeYear, yearend: flexIncomeYearEnd, amount: flexIncomeAmount, index: flexIncomeIndex, increase: flexIncomeIncrease, last_update_date: flexIncomeDate}]});
        setFlexIncomeName('');
        setFlexIncomeYear('');
        setFlexIncomeYearEnd('');
        setFlexIncomeAmount('');
        setFlexIncomeIndex('1');
        setFlexIncomeIncrease(0);
        setFlexIncomeDate(today);
        setModifyingEntry(false);
        closeBonusModal();
      };
    }; 

    const handlePortfolioDeleteItem = (index) => {
      setPresetObject({
        asset_portfolio: presetObject.asset_portfolio.filter((_, i) => i !== index),
      });
    };

    const handlePortfolioModityItem = (index) => {
      const item = presetObject.asset_portfolio[index];
      setPortfolioName(item.name);
      setPortfolioType(item.type); 
      setPortfolioAmount(item.amount);
      setPortfolioEquity(item.equity);
      setPortfolioBonds(item.bonds);
      setPortfolioIndex(item.index);
      setPortfolioDate(item.last_update_date);
      setPresetObject({
        asset_portfolio: presetObject.asset_portfolio.filter((_, i) => i !== index),
      });
      setModifyingEntry(true);
      openAssetModal();
    };   

    const handleExtraDeleteItem = (index) => {
      setPresetObject({
        extraordinary_inflows: presetObject.extraordinary_inflows.filter((_, i) => i !== index),
      });
    };

    const handleExtraModityItem = (index) => {
      const item = presetObject.extraordinary_inflows[index];
      setExtraName(item.name);
      setExtraYear(item.year); 
      setExtraAmount(item.amount);
      setExtraIndex(item.index);
      setExtraIncrease(item.increase);
      setExtraType(item.type);
      setExtraDate(item.last_update_date);
      setPresetObject({
        extraordinary_inflows: presetObject.extraordinary_inflows.filter((_, i) => i !== index),
      });
      setModifyingEntry(true);
      openExtraordinaryModal();
    };

    const handleStatePensionDeleteItem = (index) => {
      setPresetObject({
        state_pension: presetObject.state_pension.filter((_, i) => i !== index),
      });
    };

    const handleStatePensionModityItem = (index) => {
      const item = presetObject.state_pension[index];
      setStateName(item.name);
      setStateYear(item.year); 
      setStateAmount(item.amount);
      setStateIndex(item.index);
      setStateIncrease(item.increase);
      setStateDate(item.last_update_date);
      setPresetObject({
        state_pension: presetObject.state_pension.filter((_, i) => i !== index),
      });
      setModifyingEntry(true);
      openStateModal();
    };

    const handleOccupationalPensionDeleteItem = (index) => {
      setPresetObject({
        occupational_pension: presetObject.occupational_pension.filter((_, i) => i !== index),
      });
    };

    const handleOccupationalPensionModityItem = (index) => {
      const item = presetObject.occupational_pension[index];
      setOccupationalName(item.name);
      setOccupationalYear(item.year); 
      setOccupationalAmount(item.amount);
      setOccupationalIndex(item.index);
      setOccupationalIncrease(item.increase);
      setOccupationalDate(item.last_update_date);
      setPresetObject({
        occupational_pension: presetObject.occupational_pension.filter((_, i) => i !== index),
      });
      setModifyingEntry(true);
      openOccupationalModal();
    };

    const handleAnnuityPensionDeleteItem = (index) => {
      setPresetObject({
        annuity_pension: presetObject.annuity_pension.filter((_, i) => i !== index),
      });
    };

    const handleAnnuityPensionModityItem = (index) => {
      const item = presetObject.annuity_pension[index];
      setAnnuityName(item.name);
      setAnnuityYear(item.year); 
      setAnnuityAmount(item.amount);
      setAnnuityPrice(item.price);
      setAnnuityDeferred(item.deferred);
      setAnnuityIndex(item.index);
      setAnnuityIncrease(item.increase);
      setAnnuityDate(item.last_update_date);
      setPresetObject({
        annuity_pension: presetObject.annuity_pension.filter((_, i) => i !== index),
      });
      setModifyingEntry(true);
      openAnnuityModal();
    };

    const handleContributionDeleteItem = (index) => {
      setPresetObject({
        contribution_inflows: presetObject.contribution_inflows.filter((_, i) => i !== index),
      });
    };

    const handleContributionModityItem = (index) => {
      const item = presetObject.contribution_inflows[index];
      setContributionName(item.name);
      setContributionYear(item.year); 
      setContributionYearEnd(item.yearend);
      setContributionAmount(item.amount);
      setContributionIndex(item.index);
      setContributionIncrease(item.increase);
      setContributionType(item.type);
      setAnnuityDate(item.last_update_date);
      setPresetObject({
        contribution_inflows: presetObject.contribution_inflows.filter((_, i) => i !== index),
      });
      setModifyingEntry(true);
      openContributionModal();
    };

    const handleIncomeDeleteItem = (index) => {
      setPresetObject({
        income_requirements: presetObject.income_requirements.filter((_, i) => i !== index),
      });
    };

    const handleIncomeModityItem = (index) => {
      const item = presetObject.income_requirements[index];
      setIncomeName(item.name);
      setIncomeYear(item.year); 
      setIncomeYearEnd(item.yearend);
      setIncomeAmount(item.amount);
      setIncomeIndex(item.index);
      setIncomeIncrease(item.increase);
      setIncomeIncrease2(item.increase2);
      setIncomeDate(item.last_update_date);
      setIncomeSingleYear(item.single_year);
      setIncomePercentCovered(item.percent_covered);
      setIncomeJanuary(item.january);
      setIncomeFebruary(item.february);
      setIncomeMarch(item.march);
      setIncomeApril(item.april);
      setIncomeMay(item.may);
      setIncomeJune(item.june);
      setIncomeJuly(item.july);
      setIncomeAugust(item.august);
      setIncomeSeptember(item.september);
      setIncomeOctober(item.october);
      setIncomeNovember(item.november);
      setIncomeDecember(item.december);
      setIncomeStartDate(item.date_start);
      setIncomeEndDate(item.date_end);

      setPresetObject({
        income_requirements: presetObject.income_requirements.filter((_, i) => i !== index),
      });
      setModifyingEntry(true);
      openIncomeModal();
    };

    const handleFlexIncomeDeleteItem = (index) => {
      setPresetObject({
        flex_income_requirements: presetObject.flex_income_requirements.filter((_, i) => i !== index),
      });
    };

    const handleFlexIncomeModityItem = (index) => {
      const item = presetObject.flex_income_requirements[index];
      setFlexIncomeName(item.name);
      setFlexIncomeYear(item.year); 
      setFlexIncomeYearEnd(item.yearend);
      setFlexIncomeAmount(item.amount);
      setFlexIncomeIndex(item.index);
      setFlexIncomeIncrease(item.increase);
      setFlexIncomeDate(item.last_update_date);
      setPresetObject({
        flex_income_requirements: presetObject.flex_income_requirements.filter((_, i) => i !== index),
      });
      setModifyingEntry(true);
      openBonusModal();
    };

    const [assetMixChangeAge, setAssetMixChangeAge] = useState('');
    const [assetMixChangeEquity, setAssetMixChangeEquity] = useState('');
    const [assetMixChangeBonds, setAssetMixChangeBonds] = useState('');
    const [assetMixChangeIndex, setAssetMixChangeIndex] = useState('');

    const handleAssetMixChangeSaveItem = () => {
      var numberAssetMixChangeItems = presetObject.asset_mix_changes?.length || 0;
        if (ValidateAssetMixChangeForm(numberAssetMixChangeItems, presetObject, assetMixChangeAge, assetMixChangeEquity, assetMixChangeBonds, assetMixChangeIndex, setErrors, setIsModalOpen, setModalmessage)) { 
        setPresetObject({asset_mix_changes: [...presetObject.asset_mix_changes, {age: assetMixChangeAge, equity: assetMixChangeEquity, bonds: assetMixChangeBonds, index: assetMixChangeIndex}]});
        setAssetMixChangeAge('');
        setAssetMixChangeEquity('');
        setAssetMixChangeBonds('');
        setAssetMixChangeIndex('');
        setModifyingEntry(false);
        closeAssetMixChangeEntryModal();
      };
    }; 

    const handleAssetMixChangeDeleteItem = (index) => {
      setPresetObject({
        asset_mix_changes: presetObject.asset_mix_changes.filter((_, i) => i !== index),
      });
    };

    const handleAssetMixChangeModityItem = (index) => {
      const item = presetObject.asset_mix_changes[index];
      setAssetMixChangeAge(item.age);
      setAssetMixChangeEquity(item.equity); 
      setAssetMixChangeBonds(item.bonds);
      setAssetMixChangeIndex(item.index);
      setPresetObject({
        asset_mix_changes: presetObject.asset_mix_changes.filter((_, i) => i !== index),
      });
      setModifyingEntry(true);
      openAssetMixChangeEntryModal();
    };

    const handleObjectChange = (property, value) => {
      if (validatesanserrorsForm({...presetObject,[property]: parseFloat(value),})) {
        setPresetObject({[property]: parseFloat(value),});
      };
    };

    const handleDateChange = (property, value) => {
      setPresetObject({[property]: value});
    };

    const handleTextChange = (property, value) => {
      if (validatesanserrorsForm({...presetObject,[property]: value,})) {
        setPresetObject({[property]: value,});
      };
    };

    const handleSelectChange = (property, value) => {
      setPresetObject({[property]: value});
    };

    const handleDataCalibrationChange = (property, value) => {
      if (value === "backUSDshiller") {setPresetObject({['data_start_year']: 1870, ['data_end_year']: 2023, ['currency_set']: 'USD', ['geographic_set']: 'DOMESTIC', ['data_direction']: 'back', ['circular_simulation']: "1",[property]: value,})}
      else if (value === "backUSDshort") {setPresetObject({['data_start_year']: 1925, ['data_end_year']: 1995, ['currency_set']: 'USD', ['geographic_set']: 'DOMESTIC', ['data_direction']: 'back', ['circular_simulation']: "0",[property]: value,})}
      else if (value === "backUSDglobal") {setPresetObject({['data_start_year']: 1899, ['data_end_year']: 2023, ['currency_set']: 'USD', ['geographic_set']: 'GLOBAL', ['data_direction']: 'back', ['circular_simulation']: "1",[property]: value,})}
      else if (value === "forwardUSD") {setPresetObject({['data_start_year']: 1870, ['data_end_year']: 2023, ['currency_set']: 'USD', ['geographic_set']: 'DOMESTIC', ['data_direction']: 'forward', ['circular_simulation']: "1",[property]: value,})}
      else if (value === "forwardUSDglobal") {setPresetObject({['data_start_year']: 1899, ['data_end_year']: 2023, ['currency_set']: 'USD', ['geographic_set']: 'GLOBAL', ['data_direction']: 'forward', ['circular_simulation']: "1",[property]: value,})}
      else if (value === "back") {setPresetObject({['data_start_year']: 1899, ['data_end_year']: 2023, ['currency_set']: 'GBP', ['geographic_set']: 'GLOBAL', ['data_direction']: 'back', ['circular_simulation']: "1",[property]: value,})}
      else {setPresetObject({['data_start_year']: 1899, ['data_end_year']: 2023, ['currency_set']: 'GBP', ['geographic_set']: 'GLOBAL', ['data_direction']: 'forward', ['circular_simulation']: "1",[property]: value,})};    
    };

    useEffect(() => {
      presetObject.geographic_set === 'DOMESTIC' ? setPresetObject({['data_start_year']: 1870}) : setPresetObject({['data_start_year']: 1899})
    }, [presetObject.geographic_set])

    const [assetDeductionTotal, setAssetDeductionTotal] = useState(0)

    useEffect(() => {
      setAssetDeductionTotal(
        presetObject.asset_deduction[0]['equity_ga'] + 
        presetObject.asset_deduction[0]['bonds_ga'] + 
        presetObject.asset_deduction[0]['index_ga'] + 
        presetObject.asset_deduction[0]['equity_te'] + 
        presetObject.asset_deduction[0]['bonds_te'] + 
        presetObject.asset_deduction[0]['index_te'] + 
        presetObject.asset_deduction[0]['equity_td'] + 
        presetObject.asset_deduction[0]['bonds_td'] + 
        presetObject.asset_deduction[0]['index_td']
      )
    }, [presetObject.asset_deduction[0]])

    const getSimulationData = async () => {
      let apiroute = ""
      apiroute = "getsimulationdata"
      try {
      const response = await fetch(`/api/${apiroute}/`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'X-API-KEY': api_key, // Set the custom header here
          },
      })
      const data = await response.json();
      setPresetObject({['historic_asset_return_data']: data.historic_dataset, ['forward_asset_return_data']: data.forward_dataset, ['mortality_data']: data.mortality_dataset});
      } catch (error) {console.log(error); console.log('error getting simulation data')}
    }

    const handleSubmit = async () => {
      let apiroute = ""
      if (ValidateForm(presetObject, setErrors, setIsModalOpen, setModalmessage)) {
      try {
      setLoading4(true);
      // console.log(presetObject);
      apiroute = "simulation";
      const response = await fetch(`/api/${apiroute}/`, {
        method: 'POST',
        headers: {
        'Content-Type': 'application/json',
        'X-API-KEY': api_key, // Set the custom header here
        },
        body: JSON.stringify(presetObject),
      });
      const data = await response.json();
      setPortfoliovaluepercentiles(data.portfolio_all_value_streams_percentiles);
      setIncomepercentiles(data.income_all_streams_percentiles);     
      setResult(data.simulation_fails * 100);
      setDecresult(data.portfolio_end_value_decile);
      setAllresult(data.portfolio_all_value_streams);
      setAllresultGA(data.portfolio_all_value_streams_ga);
      setAllresultTE(data.portfolio_all_value_streams_te);
      setAllresultTD(data.portfolio_all_value_streams_td);
      setAlldrawstreams(data.income_all_streams);
      setDrawstreamsbytype(data.median_income_by_type_all_cycles);
      setBestswrs(data.max_zero_fail_SWR_by_cycle);
      setAveragedraw(data.avg_income);
      setAverageadjusteddraw(data.avg_mort_adjusted_income);
      setSafefunding(data.max_SWR_by_simulation_year);
      setSafefundinglevel(data.safe_funding_level);

      setDrawhistdata({
        'histogram':data.income_histogram_data,
        'bins':data.income_histogram_edges,
      });
      setSumdrawadjustedavg(data.sum_mort_adjusted_discounted_income);
      setResultyears(data.simulation_years);
      setYearsForChart(data.years_for_chart);
      setPortfolioTotal(data.portfolio_total);
      setYearsForChartShort(data.years_for_chart_short);
      setSafeFundingPercentiles(data.safe_funding_level_by_cycle_percentiles);
      setSafeWithdrawalPercentiles(data.safe_withdrawal_rate_by_cycle_percentiles);

      // below is to make sure asset mix equals exactly 100 after rounding
      if (presetObject.asset_mix_source === "0") {
        setPresetObject({
          asset_mix_equity: parseFloat((data.portfolio_actual_asset_mix[0] * 100).toFixed(1)),
          asset_mix_bond: parseFloat((data.portfolio_actual_asset_mix[1] * 100).toFixed(1)),
          asset_mix_index_bond: 100 - (parseFloat((data.portfolio_actual_asset_mix[0] * 100).toFixed(1)) + parseFloat((data.portfolio_actual_asset_mix[1] * 100).toFixed(1))),
        });
      }
      } catch (error) {setModalmessage('Something went wrong.'); openModal();} finally {setLoading4(false)}
    };
    };

    const handleSubmitFast = async () => {
      let apiroute = ""
      if (ValidateForm(presetObject, setErrors, setIsModalOpen, setModalmessage)) {
      try {
      setLoading4(true);
      // console.log(presetObject);
      apiroute = "simulationfast";
      const response = await fetch(`/api/${apiroute}/`, {
        method: 'POST',
        headers: {
        'Content-Type': 'application/json',
        'X-API-KEY': api_key, // Set the custom header here
        },
        body: JSON.stringify(presetObject),
      });
      const data = await response.json();
      setPortfoliovaluepercentiles(data.portfolio_all_value_streams_percentiles);
      setIncomepercentiles(data.income_all_streams_percentiles);     
      setResult(data.simulation_fails * 100);
      setDecresult(data.portfolio_end_value_decile);
      setAllresult(data.portfolio_all_value_streams);
      setAllresultGA(data.portfolio_all_value_streams_ga);
      setAllresultTE(data.portfolio_all_value_streams_te);
      setAllresultTD(data.portfolio_all_value_streams_td);
      setAlldrawstreams(data.income_all_streams);
      setDrawstreamsbytype(data.median_income_by_type_all_cycles);
      setBestswrs(data.max_zero_fail_SWR_by_cycle);
      setAveragedraw(data.avg_income);
      setAverageadjusteddraw(data.avg_mort_adjusted_income);
      setSafefunding(data.max_SWR_by_simulation_year);
      setSafefundinglevel(data.safe_funding_level);

      setDrawhistdata({
        'histogram':data.income_histogram_data,
        'bins':data.income_histogram_edges,
      });
      setSumdrawadjustedavg(data.sum_mort_adjusted_discounted_income);
      setResultyears(data.simulation_years);
      setYearsForChart(data.years_for_chart);
      setPortfolioTotal(data.portfolio_total);
      setYearsForChartShort(data.years_for_chart_short);
      setSafeFundingPercentiles(data.safe_funding_level_by_cycle_percentiles);
      setSafeWithdrawalPercentiles(data.safe_withdrawal_rate_by_cycle_percentiles);

      // below is to make sure asset mix equals exactly 100 after rounding
      if (presetObject.asset_mix_source === "0") {
        setPresetObject({
          asset_mix_equity: parseFloat((data.portfolio_actual_asset_mix[0] * 100).toFixed(1)),
          asset_mix_bond: parseFloat((data.portfolio_actual_asset_mix[1] * 100).toFixed(1)),
          asset_mix_index_bond: 100 - (parseFloat((data.portfolio_actual_asset_mix[0] * 100).toFixed(1)) + parseFloat((data.portfolio_actual_asset_mix[1] * 100).toFixed(1))),
        });
      }
      } catch (error) {setModalmessage('Something went wrong.'); openModal();} finally {setLoading4(false)}
    };
    };

    const handleFindOptimalIncome = async (retireyear) => {
      let apiroute = ""
      if (ValidateForm(presetObject, setErrors, setIsModalOpen, setModalmessage)) {
      try {
      setLoading4(true);
      apiroute = "findoptimalincome";
      const response = await fetch(`/api/${apiroute}/`, {
        method: 'POST',
        headers: {
        'Content-Type': 'application/json',
        'X-API-KEY': api_key, // Set the custom header here
        },
        body: JSON.stringify(presetObject),
      });
      const data = await response.json();
      // console.log(data.max_income_cycle_percentiles);
      setPresetObject({
        income_requirements: [{name: 'Expenditure in retirement', single_year: '0', year: retireyear, yearend: '100', date_start: '2024-01-01', date_end: '2024-01-01', amount: data.max_income_cycle_percentiles[3], index: '1', increase: '0', increase2: '0', last_update_date: '2024-01-01', percent_covered: 100, january: '0', february: '0', march: '0', april: '0', may: '0', june: '0', july: '0', august: '0', september: '0', october: '0', november: '0', december: '0'}]
      });
      setRedirectCompleteFast(true);
      }
      catch (error) {setModalmessage('Something went wrong.'); openModal();} finally {setLoading4(false)}
    };
    };

    const changeOptimalMixMax = async () => {
      let apiroute = ""
      if (ValidateFormOptimisation(presetObject, setErrors, setIsModalOpen, setModalmessage)) {
      try {
        setLoading2(true);
        setPresetObject({['asset_mix_source']: "1"});
        apiroute = "mixopt";
        const response = await fetch(`/api/${apiroute}/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-API-KEY': api_key, // Set the custom header here
            },
          body: JSON.stringify(presetObject),
        });
        const data = await response.json();
        setPresetObject({
          ['asset_mix_equity']: data.optimised_equity_max,
          ['asset_mix_bond']: data.optimised_bond_max,
          ['asset_mix_index_bond']: data.optimised_index_bond_max,
        });
        setOptrun(!optrun);
        if (data.optimised_failure_max > 0) {
          setModalmessage('Asset mix optimised to give lowest possible but >0% failure rate. Review portfolio starting value and/or withdrawal level.');
          openModal()} else {setModalmessage('Asset mix updated.'); openModal()};
        } catch (error) {setModalmessage('Something went wrong.'); openModal();}
        finally {setLoading2(false)} 
      };
    };
    const changeOptimalMixMin = async () => {
      let apiroute = ""
      if (ValidateFormOptimisation(presetObject, setErrors, setIsModalOpen, setModalmessage)) {
      try {
        setLoading3(true);
        setPresetObject({['asset_mix_source']: "1"});
        apiroute = "mixopt";
        const response = await fetch(`/api/${apiroute}/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-API-KEY': api_key, // Set the custom header here
            },
          body: JSON.stringify(presetObject),
        });
        const data = await response.json();
        setPresetObject({
          ['asset_mix_equity']: data.optimised_equity_min, 
          ['asset_mix_bond']: data.optimised_bond_min, 
          ['asset_mix_index_bond']: data.optimised_index_bond_min,});
        setOptrun(!optrun);
        if (data.optimised_failure_min > 0) {
          setModalmessage('Asset mix optimised to give lowest possible but >0% failure rate. Review portfolio starting value and/or withdrawal level.');
          openModal()} else {setModalmessage('Asset mix updated.'); openModal()};
        } catch (error) {setModalmessage('Something went wrong.'); openModal();} 
        finally {setLoading3(false)} 
      };
    };

    const runMixMaxOptimisationAndReCalc = async () => {
      await changeOptimalMixMax();
    }

    const runMixMinOptimisationAndReCalc = async () => {
      await changeOptimalMixMin();
    }

    useEffect(() => {
      getSimulationData()
    }, []);

    useEffect(() => {
      if (isInitialRender) {setIsInitialRender(false)} else {handleSubmit()}
    }, [optrun]);

    useEffect(() => {
      if (presetObject.data_direction == 'back' && presetObject.asset_mix_index_bond > 0) {
        {setModalmessage('No allocation to index bonds possible with historic backtesting data selected. Existing index linked bond allocation moved to conventional bond allocation.'); openModal()};
      setPresetObject({['asset_mix_bond']: parseFloat(presetObject.asset_mix_index_bond + presetObject.asset_mix_bond), ['asset_mix_index_bond']: parseFloat(0),});
    }}, [presetObject.data_direction]);

    useEffect(() => {
      setYearList(Array.from({ length: presetObject.data_end_year - presetObject.data_start_year + 1}, (_, index) => presetObject.data_start_year + index));
    }, [presetObject.data_option]);

    useEffect(() => {
      
      var eq_general = 0.0 
      var eq_taxexempt = 0.0
      var eq_taxdeferred = 0.0
      var bd_general = 0.0;
      var bd_taxexempt = 0.0;
      var bd_taxdeferred = 0.0;
      var ix_general = 0.0;
      var ix_taxexempt = 0.0;
      var ix_taxdeferred = 0.0;
      var total = 0.0;
      var eq_general_percent = 0
      var bd_general_percent = 0
      var ix_general_percent = 0
      var eq_taxexempt_percent = 0
      var bd_taxexempt_percent = 0
      var ix_taxexempt_percent = 0
      var eq_taxdeferred_percent = 0
      var bd_taxdeferred_percent = 0
      var ix_taxdeferred_percent = 0
      var general_percent = 0
      var taxexempt_percent = 0 
      var taxdeferred_percent = 0
      var eq_percent = 0
      var bd_percent = 0
      var ix_percent = 0
      var total_percent = 0
      var total_general = 0
      var total_taxexempt = 0
      var total_taxdeferred = 0
      var total_equity = 0
      var total_bonds = 0
      var total_index = 0

      for (let i = 0; i < presetObject.asset_portfolio.length; i++) {
        if (presetObject.asset_portfolio[i].type === "0") 
        {
        eq_general = eq_general + parseFloat(presetObject.asset_portfolio[i].amount) * parseFloat(presetObject.asset_portfolio[i].equity / 100);
        bd_general = bd_general + parseFloat(presetObject.asset_portfolio[i].amount) * parseFloat(presetObject.asset_portfolio[i].bonds / 100);
        ix_general = ix_general + parseFloat(presetObject.asset_portfolio[i].amount) * parseFloat(presetObject.asset_portfolio[i].index / 100);
        }
        else if (presetObject.asset_portfolio[i].type === "1")
        {
        eq_taxexempt = eq_taxexempt + parseFloat(presetObject.asset_portfolio[i].amount) * parseFloat(presetObject.asset_portfolio[i].equity / 100);
        bd_taxexempt = bd_taxexempt + parseFloat(presetObject.asset_portfolio[i].amount) * parseFloat(presetObject.asset_portfolio[i].bonds / 100);
        ix_taxexempt = ix_taxexempt + parseFloat(presetObject.asset_portfolio[i].amount) * parseFloat(presetObject.asset_portfolio[i].index / 100);
        }
        else
        {
        eq_taxdeferred = eq_taxdeferred + parseFloat(presetObject.asset_portfolio[i].amount) * parseFloat(presetObject.asset_portfolio[i].equity / 100);
        bd_taxdeferred = bd_taxdeferred + parseFloat(presetObject.asset_portfolio[i].amount) * parseFloat(presetObject.asset_portfolio[i].bonds / 100);
        ix_taxdeferred = ix_taxdeferred + parseFloat(presetObject.asset_portfolio[i].amount) * parseFloat(presetObject.asset_portfolio[i].index / 100);
        }
        total = total + parseFloat(presetObject.asset_portfolio[i].amount)
      };

      eq_general = Math.max(0, eq_general - presetObject.asset_deduction[0].equity_ga);
      bd_general = Math.max(0, bd_general - presetObject.asset_deduction[0].bonds_ga);
      ix_general = Math.max(0, ix_general - presetObject.asset_deduction[0].index_ga);
      eq_taxexempt = Math.max(0, eq_taxexempt - presetObject.asset_deduction[0].equity_te);
      bd_taxexempt = Math.max(0, bd_taxexempt - presetObject.asset_deduction[0].bonds_te);
      ix_taxexempt = Math.max(0, ix_taxexempt - presetObject.asset_deduction[0].index_te);
      eq_taxdeferred = Math.max(0, eq_taxdeferred - presetObject.asset_deduction[0].equity_td);
      bd_taxdeferred = Math.max(0, bd_taxdeferred - presetObject.asset_deduction[0].bonds_td);
      ix_taxdeferred = Math.max(0, ix_taxdeferred - presetObject.asset_deduction[0].index_td);

      total_general = eq_general + bd_general + ix_general
      total_taxexempt = eq_taxexempt + bd_taxexempt + ix_taxexempt
      total_taxdeferred = eq_taxdeferred + bd_taxdeferred + ix_taxdeferred
      total_equity = eq_general + eq_taxexempt + eq_taxdeferred
      total_bonds = bd_general + bd_taxexempt + bd_taxdeferred
      total_index = ix_general + ix_taxexempt + ix_taxdeferred
      total = eq_general + bd_general + ix_general + eq_taxexempt + bd_taxexempt + ix_taxexempt + eq_taxdeferred + bd_taxdeferred + ix_taxdeferred 

      if (total !== 0) {
        eq_general_percent = eq_general / total * 100
        bd_general_percent = bd_general / total * 100
        ix_general_percent = ix_general / total * 100
        eq_taxexempt_percent = eq_taxexempt / total * 100
        bd_taxexempt_percent = bd_taxexempt / total * 100
        ix_taxexempt_percent = ix_taxexempt / total * 100
        eq_taxdeferred_percent = eq_taxdeferred / total * 100
        bd_taxdeferred_percent = bd_taxdeferred / total * 100
        ix_taxdeferred_percent = ix_taxdeferred / total * 100
        general_percent = total_general / total * 100
        taxexempt_percent = total_taxexempt / total * 100
        taxdeferred_percent = total_taxdeferred / total * 100
        eq_percent = total_equity / total * 100
        bd_percent = total_bonds / total * 100
        ix_percent = total_index / total * 100
        total_percent = total / total * 100
      } else
      {
        eq_general_percent = 0
        bd_general_percent = 0
        ix_general_percent = 0
        eq_taxexempt_percent = 0
        bd_taxexempt_percent = 0
        ix_taxexempt_percent = 0
        eq_taxdeferred_percent = 0
        bd_taxdeferred_percent = 0
        ix_taxdeferred_percent = 0
        general_percent = 0
        taxexempt_percent = 0 
        taxdeferred_percent = 0
        eq_percent = 0
        bd_percent = 0
        ix_percent = 0
        total_percent = 0
      }
      setInPortfolioEquity({['general'] : eq_general, ['tax_exempt'] : eq_taxexempt, ['tax_deferred'] : eq_taxdeferred, ['total'] : total_equity, ['general_percent'] : eq_general_percent, ['tax_exempt_percent'] : eq_taxexempt_percent, ['tax_deferred_percent'] : eq_taxdeferred_percent, ['total_percent'] : eq_percent});
      setInPortfolioBonds({['general'] : bd_general, ['tax_exempt'] : bd_taxexempt, ['tax_deferred'] : bd_taxdeferred, ['total'] : total_bonds, ['general_percent'] : bd_general_percent, ['tax_exempt_percent'] : bd_taxexempt_percent, ['tax_deferred_percent'] : bd_taxdeferred_percent, ['total_percent'] : bd_percent});
      setInPortfolioIndex({['general'] : ix_general, ['tax_exempt'] : ix_taxexempt, ['tax_deferred'] : ix_taxdeferred, ['total'] : total_index, ['general_percent'] : ix_general_percent, ['tax_exempt_percent'] : ix_taxexempt_percent, ['tax_deferred_percent'] : ix_taxdeferred_percent, ['total_percent'] : ix_percent});
      setInPortfolioTotal({['general'] : total_general, ['tax_exempt'] : total_taxexempt, ['tax_deferred'] : total_taxdeferred, ['total'] : total, ['general_percent'] : general_percent, ['tax_exempt_percent'] : taxexempt_percent, ['tax_deferred_percent'] : taxdeferred_percent, ['total_percent'] : total_percent});
      setPortfolioTotal(total);
    }, [presetObject.asset_portfolio, presetObject.asset_deduction])

    const [dataForChart, setDataForChart] = useState(allresult);
    const [unitsForYAxis, setUnitsForYAxis] = useState(yearsForChart);
    const [titleForChart, setTitleForChart] = useState('Development of portfolio value through each back-test simulation cycle ($,\u00A3 current value)');
    const [yaxisTitleForChart, setyAxisTitleForChart] = useState('At Beginning Age');
    const [percentileSettingForChart, setPercentileSettingForChart] = useState(false);
    const [multipleLinesSettingForChart, setMultipleLinesSettingForChart] = useState(true);
    const [flagPortfolioValueDistChart, setFlagPortfolioValueDistChart] = useState(true);
    
    useEffect(() => {
      if (switchvalue === 1 && switchvalue4 === 1) {setDataForChart(allresult); setyAxisTitleForChart('At age'); setUnitsForYAxis(yearsForChart); setTitleForChart(`Development of portfolio value through back-test cycles (current ${currencyIcon})`); setPercentileSettingForChart(false); setMultipleLinesSettingForChart(true); setFlagPortfolioValueDistChart(true)} 
      else if (switchvalue === 1 && switchvalue4 === 2) {setDataForChart(portfoliovaluepercentiles); setyAxisTitleForChart('At age'); setUnitsForYAxis(yearsForChart); setTitleForChart(`Development of portfolio value through back-test cycles by percentile (current ${currencyIcon})`); setPercentileSettingForChart(true); setMultipleLinesSettingForChart(true); setFlagPortfolioValueDistChart(true)}
      else if (switchvalue === 1 && switchvalue4 === 3) {setDataForChart(allresultGA); setyAxisTitleForChart('At age'); setUnitsForYAxis(yearsForChart); setTitleForChart(`Development of GA portfolio value through back-test cycles (current ${currencyIcon})`); setPercentileSettingForChart(false); setMultipleLinesSettingForChart(true); setFlagPortfolioValueDistChart(true)}
      else if (switchvalue === 1 && switchvalue4 === 4) {setDataForChart(allresultTE); setyAxisTitleForChart('At age'); setUnitsForYAxis(yearsForChart); setTitleForChart(`Development of TE portfolio value through back-test cycles (current ${currencyIcon})`); setPercentileSettingForChart(false); setMultipleLinesSettingForChart(true); setFlagPortfolioValueDistChart(true)}
      else if (switchvalue === 1 && switchvalue4 === 5) {setDataForChart(allresultTD); setyAxisTitleForChart('At age'); setUnitsForYAxis(yearsForChart); setTitleForChart(`Development of TD portfolio value through back-test cycles (current ${currencyIcon})`); setPercentileSettingForChart(false); setMultipleLinesSettingForChart(true); setFlagPortfolioValueDistChart(true)}
      else if (switchvalue === 4 && switchvalue2 === 1) {setDataForChart(alldrawstreams); setyAxisTitleForChart('In year beginning at age'); setUnitsForYAxis(yearsForChartShort); setTitleForChart(`Development of annual income/withdrawal through back-test cycles (current ${currencyIcon})`); setPercentileSettingForChart(false); setMultipleLinesSettingForChart(true); setFlagPortfolioValueDistChart(false)}
      else if (switchvalue === 4 && switchvalue2 === 2) {setDataForChart(incomepercentiles); setyAxisTitleForChart('In year beginning at age'); setUnitsForYAxis(yearsForChartShort); setTitleForChart(`Development of annual income/withdrawal through back-test cycles by percentile (current ${currencyIcon})`); setPercentileSettingForChart(true); setMultipleLinesSettingForChart(true); setFlagPortfolioValueDistChart(false)}
      else if (switchvalue === 3 && switchvalue3 === 1) {setDataForChart(bestswrs); setyAxisTitleForChart('First year of back-testing history'); setUnitsForYAxis(resultyears); setTitleForChart(`Safe funding level (current ${currencyIcon}) by back-test cycle start year for constant withdrawal strategy`); setPercentileSettingForChart(false); setMultipleLinesSettingForChart(false); setFlagPortfolioValueDistChart(false)}
      else if (switchvalue === 3 && switchvalue3 === 2) {setDataForChart(safefundinglevel); setyAxisTitleForChart('At age'); setUnitsForYAxis(yearsForChartShort); setTitleForChart('Max. safe withdrawal rate (as % min. safe funding level) by year through simulation'); setPercentileSettingForChart(false); setMultipleLinesSettingForChart(false); setFlagPortfolioValueDistChart(false)}
      else if (switchvalue === 3 && switchvalue3 === 3) {setDataForChart(safefunding); setyAxisTitleForChart('At age'); setUnitsForYAxis(yearsForChartShort); setTitleForChart(`Min. safe funding level (current ${currencyIcon}) by year through simulation`); setPercentileSettingForChart(false); setMultipleLinesSettingForChart(false); setFlagPortfolioValueDistChart(false)};
    }, [switchvalue, switchvalue2, switchvalue3, switchvalue4, allresult, portfoliovaluepercentiles, allresultGA, allresultTE, allresultTD, alldrawstreams, incomepercentiles, bestswrs, safefundinglevel, safefunding, yearsForChart, yearsForChart]);

    useEffect(() => {
      if (autoRunSimulation === true) {
        resetAutoRunSimulation();
        resetPlan();
        handleShow(); 
        };
    }, []);

    const [equityAllocation, setEquityAllocation] = useState(70)
    const [showModal, setShowModal] = useState(false);
    const [showRedirectModal, setShowRedirectModal] = useState(false);
    // this is set at 1 to hop the first question (which is now automated)
    const [questionIndex, setQuestionIndex] = useState(0);

    const [responses, setResponses] = useState({
      question1: '',
      question2: null,
      question3: '',
      question4: null,
      question5: '',
      question6: null,
      question7: null,
      question8: '',
      question9: null,
      question10: null,
      question11: '',
      question12: null,
      question13: null,
      question14: '',
      question15: null,
      question16: null,
      question17: '',
      question18: null,
    });
  
    const resetResponseFields = () => {
      setResponses({
        question1: '',  // Corrected to use string keys
        question2: null,
        question3: '',
        question4: null,
        question5: '',
        question6: null,
        question7: null,
        question8: '',
        question9: null,
        question10: null,
        question11: '',
        question12: null,
        question13: null,
        question14: '',
        question15: null,
        question16: null,
        question17: '',
        question18: null,
      });
      };
    
    const [questionErrors, setQuestionErrors] = useState({
      question1: '',
      question2: '',
      question3: '',
      question4: '',
      question5: '',
      question6: '',
      question7: '',
      question8: '',
      question9: '',
      question10: '',
      question11: '',
      question12: '',
      question13: '',
      question14: '',
      question15: '',
      question16: '',
      question17: '',
      question18: '',
    });
  
    // Array of questions with their type (text or yes/no)
    const questions = [
      { question: "Which type of simulation do you want to run?", type: "radio", options: ['Enter all plan parameters and test for success rate', 'Enter accumulation parameters and test for possible retirement income'], validation: (input) => input === 'Enter all plan parameters and test for success rate' || input === 'Enter accumulation parameters and test for possible retirement income' },
      { question: "What is your age?", type: "number", validation: (input) => !isNaN(input) && input >= 0 && input <= 100 },
      { question: "Are you retired yet?", type: "radio", options: ['Yes', 'No'], validation: (input) => input === 'Yes' || input === 'No' },
      {
        question: "At what age do you plan to retire?", 
        type: "number", 
        validation: (input, responses) => {
          if (typeof responses['question2'] === 'undefined' || responses['question2'] === '') {return false;}
          const currentAge = parseInt(responses['question2'], 10); 
          return !isNaN(input) && input > currentAge && input <= 100;
        }, 
        condition: (responses) => responses['question3'] === 'No'
      },
      { question: "Do you expect to receive a state pension / social security?", type: "radio", options: ['Yes', 'No'], validation: (input) => input === 'Yes' || input === 'No' },
      { question: "How much do you expect to receive (per year, in current value)?", type: "number", validation: (input) => !isNaN(input) && input >= 0, condition: (responses) => responses['question5'] === 'Yes'},
      { question: "At what age will you receive it?", type: "number", validation: (input) => !isNaN(input) && input >= 0, condition: (responses) => responses['question5'] === 'Yes' },
      { question: "Do you expect to receive a (defined benefit) occupational pension?", type: "radio", options: ['Yes', 'No'], validation: (input) => input === 'Yes' || input === 'No' },
      { question: "How much do you expect to receive (per year, in current value)?", type: "number", validation: (input) => !isNaN(input) && input >= 0, condition: (responses) => responses['question8'] === 'Yes'},
      { question: "At what age will you receive it?", type: "number", validation: (input) => !isNaN(input) && input >= 0, condition: (responses) => responses['question8'] === 'Yes' },
      { question: "Do you have retirement savings (such as a  defined contribution pension fund)? (NB. do not include any property that you plan to sell in the future to provide retirement income - this is captured in a following question)", type: "radio", options: ['Yes', 'No'], validation: (input) => input === 'Yes' || input === 'No', condition: (responses) => responses['question1'] === 'Question not in use' },
      // { question: "How much do you currently have in your retirement savings (e.g. defined contribution pension fund)?", type: "number", validation: (input) => !isNaN(input) && input >= 0, condition: (responses) => responses['question11'] === 'Yes' },
      { question: "How much do you currently have in your retirement savings (e.g. defined contribution pension fund)?", type: "number", validation: (input) => !isNaN(input) && input >= 0,},
      { question: "How much are you saving into your retirement savings each year?", type: "number", validation: (input) => !isNaN(input) && input >= 0, condition: (responses) => responses['question3'] === 'No' },
      { question: "Do you expect any other one-off inflows into your retirement savings (e.g. future property downsizing, inheritence, etc)?", type: "radio", options: ['Yes', 'No'], validation: (input) => input === 'Yes' || input === 'No' },
      { question: "How much do you expect to receive (in current value)?", type: "number", validation: (input) => !isNaN(input) && input >= 0, condition: (responses) => responses['question14'] === 'Yes' },
      { question: "At what age will you receive it?", type: "number", validation: (input) => !isNaN(input) && input >= 0, condition: (responses) => responses['question14'] === 'Yes' },
      { question: "How are your retirement savings currently invested?", type: "radio", options: [
        'Growth - mostly equities',
        'Balanced - mix of equities and bonds'
      ], validation: (input) => input === 'Growth - mostly equities' || input === 'Balanced - mix of equities and bonds', condition: (responses) => responses['question11'] === 'Yes' || responses['question13'] > 0} || responses['question14'] > 0,
      { question: "How much do you plan to spend each year in retirement (in current value)?", type: "number", validation: (input) => !isNaN(input) && input >= 0, condition: (responses) => responses['question1'] === 'Enter all plan parameters and test for success rate'}
      ];
  
    useEffect(() => {
      responses['question17'] === 'Growth - mostly equities' ? setEquityAllocation(70) : setEquityAllocation(40)
    }, [responses['question17']])
  
    // Functions to show and hide the modal
    const handleShow = () => setShowModal(true);
    const handleClose = () => 
      {
        setQuestionIndex(0);
        resetResponseFields();
        setShowModal(false);
      }

    const handleRedirectShow = () => setShowRedirectModal(true);
    const handleRedirectClose = () => 
      {
        setShowRedirectModal(false);
        resetPresetObject();
        if (userLocation === 'GB') {
          setPresetObject({
            'currency_set': 'GBP',
            'geographic_set': 'GLOBAL',
          })
        } else {
          setPresetObject({
            'currency_set': 'USD',
            'geographic_set': 'DOMESTIC',
          })  
        };
  
        const retireyear = responses['question4'] ? parseInt(responses['question4'], 10) : parseInt(responses['question2'], 10);		

        setPresetObject({
          data_direction: "forward",
          start_simulation_age: parseInt(responses['question2'], 10),
          end_simulation_age: 100,
          asset_mix_equity: equityAllocation,
          asset_mix_bond: (100 - equityAllocation) / 2,
          asset_mix_index_bond: (100 - equityAllocation) / 2,
          equity_tax: 0,
          bond_tax: 0,
          draw_tax: 0,
          annuity_tax_rate: 0,
          annuity_tax_rate2: 0,
          annuity_tax_rate3: 0,
          fees: 0,
        });
        if (responses['question6']) {
          setPresetObject({
            state_pension: [{name: 'State pension / social security', year: responses['question7'], amount: responses['question6'], index: '1', increase: '0', last_update_date: '2024-01-01'}]
          });}
        if (responses['question9']) {
          setPresetObject({
            occupational_pension: [{name: 'Occupational pension', year: responses['question10'], amount: responses['question9'], index: '1', increase: '0', last_update_date: '2024-01-01'}]
        });}
        if (responses['question12']) {
          setPresetObject({
              asset_portfolio: [{name: 'Retirement savings', type: '2', amount: responses['question12'], equity: String(equityAllocation), bonds: String((100 - equityAllocation)/2), index: String((100 - equityAllocation)/2), last_update_date: '2024-01-01'}]
          });}
        if (responses['question13']) {
          setPresetObject({
            contribution_inflows: [{name: 'Contribution to savings', year: responses['question2'], yearend: retireyear, amount: responses['question13'], index: '1', increase: '0', type: '2', last_update_date: '2024-01-01'}]
          });}
        if (responses['question15']) {
          setPresetObject({
            extraordinary_inflows: [{name: 'One-off inflow', year: responses['question16'], amount: responses['question15'], index: '1', increase: '0', type: '0', last_update_date: '2024-01-01'}]
          });}
        if (responses['question18']) {
          setPresetObject({
            income_requirements: [{name: 'Expenditure in retirement', single_year: '0', year: retireyear, yearend: '100', date_start: '2024-01-01', date_end: '2024-01-01', amount: responses['question18'], index: '1', increase: '0', increase2: '0', last_update_date: '2024-01-01', percent_covered: 100, january: '0', february: '0', march: '0', april: '0', may: '0', june: '0', july: '0', august: '0', september: '0', october: '0', november: '0', december: '0'}]
          });} else {
          setPresetObject({
            income_requirements: [{name: 'Expenditure in retirement', single_year: '0', year: retireyear, yearend: '100', date_start: '2024-01-01', date_end: '2024-01-01', amount: 1, index: '1', increase: '0', increase2: '0', last_update_date: '2024-01-01', percent_covered: 100, january: '0', february: '0', march: '0', april: '0', may: '0', june: '0', july: '0', august: '0', september: '0', october: '0', november: '0', december: '0'}]
          });}
        if (responses['question1'] === 'Enter accumulation parameters and test for possible retirement income') {
          setRedirectTwoComplete(true);
        } else {
          setRedirectComplete(true);
        };
      }
  
    useEffect(() => {
      if (redirectTwoComplete) {
        const retireyear = responses['question4'] ? parseInt(responses['question4'], 10) : parseInt(responses['question2'], 10);	
        handleFindOptimalIncome(retireyear);
        setRedirectTwoComplete(false);
      };
    }, [redirectTwoComplete]);

    useEffect(() => {
      if (redirectComplete) {
        handleSubmit(); 
        setRedirectComplete(false);
      };
    }, [redirectComplete]);

    useEffect(() => {
      if (redirectCompleteFast) {
        handleSubmitFast(); 
        setRedirectCompleteFast(false);
      };
    }, [redirectCompleteFast]);   

    const handleNext = () => {
      const currentQuestion = questions[questionIndex];
      const response = responses[`question${questionIndex + 1}`];
    
      // Pass responses explicitly to the validation function
      if (!currentQuestion.validation(response, responses)) {
        // Handle validation error
        setQuestionErrors({
          ...questionErrors,
          [`question${questionIndex + 1}`]: "Invalid response. Please check your input."
        });
        return;
      }
    
      // If valid, clear error and move to next question
      setQuestionErrors({
        ...questionErrors,
        [`question${questionIndex + 1}`]: ''
      });
  
      // Find the next question that meets the condition or doesn't have a condition
      let nextIndex = questionIndex + 1;
      while (nextIndex < questions.length && questions[nextIndex].condition && !questions[nextIndex].condition(responses)) {
        nextIndex++;
      }
    
      if (nextIndex < questions.length) {
        setQuestionIndex(nextIndex);
      } else {
        // All valid questions answered, handle submission here
        // console.log('Responses:', responses);
        setShowModal(false);  // Close the modal when done
        setShowRedirectModal(true);
      }
    };
  
    // Update the response for the current question
    const handleInputChange = (e) => {
      const currentQuestionKey = `question${questionIndex + 1}`;
      setResponses({
      ...responses,
      [currentQuestionKey]: e.target.value,
      });
    };

    const handleRunPlan = () => {
      handleRedirectClose();
      // handleSubmit();
    };

    return (

      <div>
        	<Helmet>
            <title>Retire Smart Calc - Retirement Portfolio Simulation Calculator</title>
            <meta name="description" content="Retirement portfolio safe withdrawal rate (SWR) calculator. Back-test strategy and examine impact of asset mix, constant vs. flexible withdrawal strategies and purchase of an annuity." />
            <link rel="canonical" href="https://www.retiresmartcalc.com/#/calc"></link>
            <link rel="icon" href="https://www.retiresmartcalc.com/logo.png" />
            <script type="application/ld+json">
            {JSON.stringify(webpageData)}
            </script>
          </Helmet>
      
      <Container fluid className="bg-dark text-light" style={{ minHeight: '100vh' }}>
        <Row style={{ display: 'flex', gap: '0px' }}>
          <Col sm={4} className={`border border-secondary p-3 mt-2 mb-2 ${isSmallScreen ? '' : 'custom-scrollbar-col'}`} style={isSmallScreen ? {} : { overflowY: 'auto', height: '100vh' }}>

          <div className="d-flex align-items-center">
            <p className="lead mb-0">Plan:</p>
            <OverlayTrigger
            placement="right"
            overlay={
            <Tooltip id="tooltip-right">Inputs are specific to each plan. You can save and retrieve plans by registering for a user account.</Tooltip>}>
            <span style={{ cursor: 'pointer', display: 'inline-block', marginLeft: '8px', verticalAlign: 'middle' }}>
            <FaInfoCircle style={{ color: '#adb5bd', fontSize: '1.0rem' }} />
            </span>
            </OverlayTrigger>
            </div>


          <PlanButtons 
          logInState={logInState} 
          userId={userId} 
          getPlans={getPlans} 
          copyPlan={copyPlan}
          savePlan={savePlan} 
          openResetModal={openResetModal} 
          planAlreadySaved={planId}
          />

            <Row>
              <Form.Group>
                <OverlayTrigger placement="right" overlay={<Tooltip id="tooltip-right">Plan will be saved under this title name. Title name should distinguish plan from other plans.</Tooltip>}>
                <Form.Label htmlFor="plan_title" className="fw-light small">Plan title:</Form.Label>
                </OverlayTrigger>
                <Form.Control type="text" name="plan_title" id="plan_title" value={presetObject.plan_title} onChange={(e) => handleTextChange('plan_title', e.target.value)} className="form-control bg-secondary border-secondary text-light" autoComplete="off"></Form.Control>
                {errors.plan_title && <Alert className="alert alert-danger">{errors.plan_title}</Alert>}
              </Form.Group>              
            </Row>

            <Row>
            <Form.Group as={Col}>
            <FormTemplateInteger formlabel="Age at start of simulation:" formname="start_simulation_age" type="number" overlaytext="Enter the starting age for the simulation (e.g. current age or expected age at retirement)" presetObject={presetObject} handleObjectChange={handleObjectChange} errors={errors}/>
            </Form.Group>
            <Form.Group as={Col}>
            <FormTemplateInteger formlabel="Age at end of simulation:" formname="end_simulation_age" type="number" overlaytext="Age to which you wish to plan to (e.g. 100 years)" presetObject={presetObject} handleObjectChange={handleObjectChange} errors={errors}/>
            </Form.Group>
            </Row>

            <hr className="my-2" />           

            <div className="d-flex justify-content-between align-items-start">
            <h6>Current retirement savings:</h6>
            <OverlayTrigger
              placement="right"
              overlay={<Tooltip id="tooltip-right">Add existing portfolio of savings designated for retirement together with any expected state pension / social security and (defined benefit) occupational pension income.</Tooltip>}>
            <span style={{ cursor: 'pointer', display: 'inline-block', verticalAlign: 'top' }}><FaInfoCircle style={{ color: '#adb5bd', fontSize: '1rem' }} /></span>
            </OverlayTrigger>
            </div>


            {/* <div className="my-2" /> */}
            <div className="d-grid gap-2">
              <Button variant="secondary" size="" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} onClick={openAddAssetModal}>Add asset to portfolio
              <span className="badge-space"><Badge bg="info">{presetObject.asset_portfolio.length ? presetObject.asset_portfolio.length : null}</Badge></span>
              </Button>
            </div>
            <div className="my-2"></div>
            <div className="d-grid gap-2">
              <Button variant="secondary" size="" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} onClick={openAddStateModal}>Add state pension
              <span className="badge-space"><Badge bg="info">{presetObject.state_pension.length ? presetObject.state_pension.length : null}</Badge></span>
              </Button>
            </div>
            <div className="my-2"></div>
            <div className="d-grid gap-2">
              <Button variant="secondary" size="" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} onClick={openAddOccupationModal}>Add occupational pension
              <span className="badge-space"><Badge bg="info">{presetObject.occupational_pension.length ? presetObject.state_pension.length : null}</Badge></span>
              </Button>
            </div>

            {/* Asset Modal */}
            <Modal show={isAddAssetModalOpen} onHide={closeAddAssetModal} size="xl" className="border-light">
              <Modal.Header closeButton className="bg-dark text-light border-light">
                <Modal.Title>Asset Portfolio</Modal.Title>
              </Modal.Header>
              <Modal.Body className="bg-dark text-light border-light">

              <div className="my-2" />
              <PortfolioBreakdownPopup show={isPortfolioModalOpen} onClose={closePortfolioModal} data={portfolioTotal} data2={inPortfolioEquity} data3={inPortfolioBonds} data4={inPortfolioIndex} data5={inPortfolioTotal} data6={currencyIcon}/>
              <div className="mt-4" />

              <Row>
              {presetObject.asset_portfolio
              .map((item, index) => ({ ...item, originalIndex: index })) // Add the original index to each item
              .sort((a, b) => a.name.localeCompare(b.name)) // Sort by 'name'
              .map((item) => (
                <Col key={item.originalIndex} md={3} className="mb-4">
                  <Card bg="dark" text="white" className="border-light">
                    <Card.Body>
                      <Card.Title className="h6">{item.name}</Card.Title>
                      <hr className="hr-line" />
                      <Card.Text className="tight-spacing">
                        {item.amount === null || item.amount === undefined ? (
                          <>''</>
                        ) : (
                          <p className={isSmallScreen ? 'small tight-spacing' : 'small tight-spacing'}>
                            Amount: {currencyIcon}{item.amount.toLocaleString()}
                          </p>
                        )}
                      </Card.Text>
                      <Card.Text className="small tight-spacing">Type: {item.type === '0' ? 'General' : item.type === '1' ? 'Tax Exempt' : 'Tax Deferred'}</Card.Text>
                      <Card.Text className="small tight-spacing">Equity: {item.equity}</Card.Text>
                      <Card.Text className="small tight-spacing">Conventional Bonds: {item.bonds}</Card.Text>
                      <Card.Text className="small tight-spacing">Index Bonds: {item.index}</Card.Text>
                      <Card.Text className="small tight-spacing">Last Update: {new Date(item.last_update_date).toLocaleDateString('en-US', { year: 'numeric', month: 'long' })}</Card.Text>
                      <Button variant="danger" onClick={() => handlePortfolioDeleteItem(item.originalIndex)}>Delete</Button>
                      <span> </span>
                      <Button variant="info" onClick={() => handlePortfolioModityItem(item.originalIndex)}>Modify</Button>                      
                    </Card.Body>
                  </Card>
                </Col>
              ))}
            </Row>
              </Modal.Body>
              <Modal.Footer className="bg-dark text-light border-light d-flex justify-content-between">
              <div>
                <Button variant="success" className="me-1" onClick={openAssetModal} style={{ marginBottom: '4px'}}>Add Asset</Button>
                <Button variant="success" className="me-1" onClick={openPortfolioModal} style={{ marginBottom: '4px'}}>Show Portfolio Breakdown</Button>
                <Button variant="success" className="me-1" onClick={openAssetDeductionModal} style={{ marginBottom: '4px'}}>
                Apply Portfolio Deduction
                {assetDeductionTotal > 0 && (
                  <span className="badge-space">
                    <Badge style={{ backgroundColor: 'transparent', padding: '0', }}>
                      <span style={{display: 'inline-block', width: '10px', height: '10px', borderRadius: '50%', backgroundColor: 'white', }}></span>
                    </Badge>
                  </span>
                )}
              </Button>
              </div>
              <Button variant="secondary" onClick={closeAddAssetModal}>Close</Button>
            </Modal.Footer>
            </Modal>
     
            <Modal show={isAssetModalOpen} onHide={modifyingEntry ? handlePortfolioChange : closeAssetModal}>
            <Modal.Header closeButton>
            <Modal.Title>Asset</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <Form.Group>
              <OverlayTrigger placement={'right'} overlay={<Tooltip id={`tooltip-right`}>For own reference.</Tooltip>}>           
              <Form.Label htmlFor="portfolioName" className="small">Description:</Form.Label>
              </OverlayTrigger>
              <Form.Control name="portfolioName" id="portfolioName" className="form-control border-secondary" type="text" value={portfolioName} onChange={(e) => setPortfolioName(e.target.value)} placeholder="Name"/>
              {errors.portfolioName && <Alert className="alert alert-danger">{errors.portfolioName}</Alert>}
            </Form.Group>
            <Form.Group>
              <OverlayTrigger placement={'right'} overlay={<Tooltip id={`tooltip-right`}>Enter current market value.</Tooltip>}>           
              <Form.Label htmlFor="portfolioAmount" className="small">Amount (current):</Form.Label>
              </OverlayTrigger>
              <Form.Control name="portfolioAmount" id="portfolioAmount" className="form-control border-secondary" type="number" value={portfolioAmount} onChange={(e) => setPortfolioAmount(e.target.value)} placeholder="Amount"/>
              {errors.portfolioAmount && <Alert className="alert alert-danger">{errors.portfolioAmount}</Alert>}
            </Form.Group>
            <Form.Group>
              <OverlayTrigger placement={'right'} overlay={<Tooltip id={`tooltip-right`}>Select type of fund. If you enter tax rates in the plan, these tax rates will be applied in their respective ways to either the simulated returns in the fund (general account) or to withdrawals from the fund (tax deferred account).<br/>Examples of tax exempt accounts are UK: ISA, US: Roth IRA, 529 or HSA.<br/>Examples of tax deferred accounts are UK: SIPP or DC Workplace Pension, US: 401k or 403b.</Tooltip>}>           
              <Form.Label htmlFor="portfolioType" className="small">Fund type:</Form.Label>
              </OverlayTrigger>
              <Form.Select name="portfolioType" id="portfolioType" className="form-control border-secondary" value={portfolioType} onChange={(e) => setPortfolioType(e.target.value)} defaultValue="0">
                <option value="0">General Account</option>
                <option value="2">Tax Deferred Pension Account</option>
                <option value="1">Tax Exempt Savings Account</option>
              </Form.Select>
            </Form.Group>
            <Form.Group>
              <OverlayTrigger placement={'right'} overlay={<Tooltip id={`tooltip-right`}>Allocation within asset to equity (e.g. in case of multi-asset fund). For 60% enter 60.</Tooltip>}>           
              <Form.Label htmlFor="portfolioEquity" className="small">% Equity:</Form.Label>
              </OverlayTrigger>
              <Form.Control name="portfolioEquity" id="portfolioEquity" className="form-control border-secondary" type="number" value={portfolioEquity} onChange={(e) => setPortfolioEquity(e.target.value)} placeholder="%"/>
              {errors.portfolioEquity && <Alert className="alert alert-danger">{errors.portfolioEquity}</Alert>}  
            </Form.Group>
            <Form.Group>
              <OverlayTrigger placement={'right'} overlay={<Tooltip id={`tooltip-right`}>Allocation within asset to conventional bonds (e.g. in case of multi-asset fund). For 40% enter 40.</Tooltip>}>           
              <Form.Label htmlFor="portfolioBonds" className="small">% Conventional Bonds:</Form.Label>
              </OverlayTrigger>
              <Form.Control name="portfolioBonds" id="portfolioBonds" className="form-control border-secondary" type="number" value={portfolioBonds} onChange={(e) => setPortfolioBonds(e.target.value)} placeholder="%"/>
              {errors.portfolioBonds && <Alert className="alert alert-danger">{errors.portfolioBonds}</Alert>}
            </Form.Group>
            <Form.Group>
              <OverlayTrigger placement={'right'} overlay={<Tooltip id={`tooltip-right`}>Allocation within asset to index linked bonds (e.g. in case of multi-asset fund). For 40% enter 40.</Tooltip>}>           
              <Form.Label htmlFor="portfolioIndex" className="small">% Index Bonds:</Form.Label>
              </OverlayTrigger>
              <Form.Control name="portfolioIndex" id="portfolioIndex" className="form-control border-secondary" type="number" value={portfolioIndex} onChange={(e) => setPortfolioIndex(e.target.value)} placeholder="%"/>
              {errors.portfolioIndex && <Alert className="alert alert-danger">{errors.portfolioIndex}</Alert>}
            </Form.Group>
            <Form.Group>
              <OverlayTrigger placement={'right'} overlay={<Tooltip id={`tooltip-right`}>For own reference when updating plan.</Tooltip>}>           
              <Form.Label htmlFor="portfolioDate" className="small">Reference date item last updated:</Form.Label>
              </OverlayTrigger>
              <Form.Control
                name="portfolioDate"
                id="portfolioDate"
                className="form-control border-secondary"
                type="month"
                value={portfolioDate.slice(0, 7)} // Show only the month and year in the input
                onChange={(e) => {
                  const selectedMonthYear = e.target.value; // yyyy-mm from input
                  const formattedDate = `${selectedMonthYear}-01`; // Set to first of the month
                  setPortfolioDate(formattedDate); // Update state with full yyyy-mm-dd format
                }}
                placeholder="yyyy-mm"
              />
              {errors.portfolioDate && <Alert className="alert alert-danger">{errors.portfolioDate}</Alert>}
            </Form.Group>

            </Modal.Body>
            <Modal.Footer>
            <Button variant="success" onClick={handlePortfolioChange }>Save</Button>
            </Modal.Footer>
            </Modal>

            <Modal show={isAssetDeductionModalOpen} onHide={closeAssetDeductionModal} size='lg'>
            <Modal.Header closeButton>
            <Modal.Title>Portfolio Deduction</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <>Apply a deduction to aggregate asset portfolio. Enter positive value(s) in relevant box(es).</>
            <Table hover className="striped bordered hover small">
            <thead>
                <tr>
                  <th style={{ width: '25%' }}></th>
                  <th style={{ width: '25%' }}>Equities</th>
                  <th style={{ width: '25%' }}>Conventional Bonds</th>
                  <th style={{ width: '25%' }}>Index Bonds</th>     
                </tr>
            </thead>
            <tbody>
                <tr>
                  <td >General Acc.</td>
                  <td ><Form.Group><Form.Control name="deductionEqGA" id="deductionEqGA" className="form-control border-secondary" type="number" value={deductionEqGA} onChange={(e) => setDeductionEqGA(e.target.value)} placeholder="0"/>{errors.deductionEqGA && <Alert className="alert alert-danger">{errors.deductionEqGA}</Alert>}</Form.Group></td>
                  <td ><Form.Group><Form.Control name="deductionBondGA" id="deductionBondGA" className="form-control border-secondary" type="number" value={deductionBondGA} onChange={(e) => setDeductionBondGA(e.target.value)} placeholder="0"/>{errors.deductionBondGA && <Alert className="alert alert-danger">{errors.deductionBondGA}</Alert>}</Form.Group></td>
                  <td ><Form.Group><Form.Control name="deductionIndexGA" id="deductionIndexGA" className="form-control border-secondary" type="number" value={deductionIndexGA} onChange={(e) => setDeductionIndexGA(e.target.value)} placeholder="0"/>{errors.deductionIndexGA && <Alert className="alert alert-danger">{errors.deductionIndexGA}</Alert>}</Form.Group></td>     
                </tr>
                <tr>
                  <td >Tax Exempt</td>
                  <td ><Form.Group><Form.Control name="deductionEqTE" id="deductionEqTE" className="form-control border-secondary" type="number" value={deductionEqTE} onChange={(e) => setDeductionEqTE(e.target.value)} placeholder="0"/>{errors.deductionEqTE && <Alert className="alert alert-danger">{errors.deductionEqTE}</Alert>}</Form.Group></td>
                  <td ><Form.Group><Form.Control name="deductionBondTE" id="deductionBondTE" className="form-control border-secondary" type="number" value={deductionBondTE} onChange={(e) => setDeductionBondTE(e.target.value)} placeholder="0"/>{errors.deductionBondTE && <Alert className="alert alert-danger">{errors.deductionBondTE}</Alert>}</Form.Group></td>
                  <td ><Form.Group><Form.Control name="deductionIndexTE" id="deductionIndexTE" className="form-control border-secondary" type="number" value={deductionIndexTE} onChange={(e) => setDeductionIndexTE(e.target.value)} placeholder="0"/>{errors.deductionIndexTE && <Alert className="alert alert-danger">{errors.deductionIndexTE}</Alert>}</Form.Group></td>     
                </tr>
                <tr>
                  <td >Tax Deferred</td>
                  <td ><Form.Group><Form.Control name="deductionEqTD" id="deductionEqTD" className="form-control border-secondary" type="number" value={deductionEqTD} onChange={(e) => setDeductionEqTD(e.target.value)} placeholder="0"/>{errors.deductionEqTD && <Alert className="alert alert-danger">{errors.deductionEqTD}</Alert>}</Form.Group></td>
                  <td ><Form.Group><Form.Control name="deductionBondTD" id="deductionBondTD" className="form-control border-secondary" type="number" value={deductionBondTD} onChange={(e) => setDeductionBondTD(e.target.value)} placeholder="0"/>{errors.deductionBondTD && <Alert className="alert alert-danger">{errors.deductionBondTD}</Alert>}</Form.Group></td>
                  <td ><Form.Group><Form.Control name="deductionIndexTD" id="deductionIndexTD" className="form-control border-secondary" type="number" value={deductionIndexTD} onChange={(e) => setDeductionIndexTD(e.target.value)} placeholder="0"/>{errors.deductionIndexTD && <Alert className="alert alert-danger">{errors.deductionIndexTD}</Alert>}</Form.Group></td>     
                </tr>
            </tbody>
            </Table>
            </Modal.Body>
            <Modal.Footer>
            <Button variant="success" onClick={handleAssetDeductionChange }>Enter</Button>
            </Modal.Footer>
            </Modal>

            <div className="mt-2"></div>
            <div className="my-2" />

            {/* State Modal */}
            <Modal show={isAddStateModalOpen} onHide={closeAddStateModal} size="xl" className="border-light">
              <Modal.Header closeButton className="bg-dark text-light border-light">
                <Modal.Title>State Pension</Modal.Title>
              </Modal.Header>
              <Modal.Body className="bg-dark text-light border-light">
              <div className="mt-4" />
              <Row>
              {presetObject.state_pension
                .map((item, index) => ({ ...item, originalIndex: index })) // Add the original index to each item
                .sort((a, b) => a.name.localeCompare(b.name)) // Sort by 'name'
                .map((item) => (
                  <Col key={item.originalIndex} md={3} className="mb-4">
                    <Card bg="dark" text="white" className="border-light">
                      <Card.Body>
                        <Card.Title className="h6">{item.name}</Card.Title>
                        <hr className="hr-line" />
                        <Card.Text className="small tight-spacing">Year (age): {item.year}</Card.Text>
                        <Card.Text className="small tight-spacing">Annual amount: {currencyIcon}{item.amount}</Card.Text>
                        <Card.Text className="small tight-spacing">Indexed: {item.index === '1' ? 'Yes' : 'No'}</Card.Text>
                        <Card.Text className="small tight-spacing">Inc. over index (%): {item.increase}</Card.Text>
                        <Card.Text className="small tight-spacing">Last Update: {new Date(item.last_update_date).toLocaleDateString('en-US', { year: 'numeric', month: 'long' })}</Card.Text>
                        <Button variant="danger" onClick={() => handleStatePensionDeleteItem(item.originalIndex)}>Delete</Button>
                        <span> </span>
                        <Button variant="info" onClick={() => handleStatePensionModityItem(item.originalIndex)}>Modify</Button>                      
                      </Card.Body>
                    </Card>
                  </Col>
                ))}
              </Row>

              </Modal.Body>
              <Modal.Footer className="bg-dark text-light border-light d-flex justify-content-between">
                <Button className="btn btn-success" onClick={openStateModal}>Add State Pension</Button>
                <Button variant="secondary" onClick={closeAddStateModal}>Close</Button>
              </Modal.Footer>
            </Modal>

            <Modal show={isStateModalOpen} onHide={modifyingEntry ? handleStatePensionChange : closeStateModal}>
            <Modal.Header closeButton>
            <Modal.Title>State Pension</Modal.Title>
            </Modal.Header>
            <Modal.Body>

              <Form.Group as={Col}>
                <OverlayTrigger placement={'right'} overlay={<Tooltip id={`tooltip-right`}>For own reference</Tooltip>}>           
                <Form.Label htmlFor="stateName" className="small">Description:</Form.Label>
                </OverlayTrigger>
                <Form.Control name="stateName" id="stateName" className="form-control border-secondary" type="text" value={stateName} onChange={(e) => setStateName(e.target.value)} placeholder="Name" />                
                {errors.stateName && <Alert className="alert alert-danger">{errors.stateName}</Alert>}
              </Form.Group>
              <Form.Group as={Col}>
                <OverlayTrigger placement={'right'} overlay={<Tooltip id={`tooltip-right`}>Age when payments will start to be received (e.g. 67)</Tooltip>}>           
                <Form.Label htmlFor="stateYear" className="small">Year (age):</Form.Label>
                </OverlayTrigger>
                <Form.Control name="stateYear" id="stateYear" className="form-control border-secondary" type="number" value={stateYear} onChange={(e) => { if (/^\d*$/.test(e.target.value)) {setStateYear(e.target.value)}}} placeholder="Year" min="1" />               
                {errors.stateYear && <Alert className="alert alert-danger">{errors.stateYear}</Alert>}
              </Form.Group>
              <Form.Group as={Col}>
                <OverlayTrigger placement={'right'} overlay={<Tooltip id={`tooltip-right`}>Expected annual value of payment today's money</Tooltip>}>           
                <Form.Label htmlFor="stateAmount" className="small">Amount (current):</Form.Label>
                </OverlayTrigger>
                <Form.Control name="stateAmount" id="stateAmount" className="form-control border-secondary" type="number" value={stateAmount} onChange={(e) => setStateAmount(e.target.value)} placeholder="Amount" min="1" />                
                {errors.stateAmount && <Alert className="alert alert-danger">{errors.stateAmount}</Alert>}
              </Form.Group>
              <Form.Group as={Col}>
                <OverlayTrigger placement={'right'} overlay={<Tooltip id={`tooltip-right`}>Select whether the value of the payment is indexed to inflation each year</Tooltip>}>           
                <Form.Label htmlFor="stateIndex" className="small">Annual inc. (select):</Form.Label>
                </OverlayTrigger>
                <Form.Select name="stateIndex" id="stateIndex" className="form-control border-secondary" value={stateIndex} onChange={(e) => setStateIndex(e.target.value)} defaultValue="1">     
                  <option value="1">Indexed</option>
                  <option value="0">Not Indexed</option>
                </Form.Select>
              </Form.Group>
              <Form.Group as={Col}>
                <OverlayTrigger placement={'right'} overlay={<Tooltip id={`tooltip-right`}>Enter expected increase in value of the payment each year (over inflation if you selected 'Indexed' in the previous question or in absolute terms if you selected 'Not Indexed')</Tooltip>}>           
                <Form.Label htmlFor="stateIncrease" className="small">Inc. over index (%):</Form.Label>
                </OverlayTrigger>
                <Form.Control name="stateIncrease" id="stateIncrease" className="form-control border-secondary" type="number" value={stateIncrease} onChange={(e) => setStateIncrease(e.target.value)} placeholder="%" />       
                {errors.stateIncrease && <Alert className="alert alert-danger">{errors.stateIncrease}</Alert>}
              </Form.Group>
              <Form.Group as={Col}>
                <OverlayTrigger placement={'right'} overlay={<Tooltip id={`tooltip-right`}>For own reference when updating plan.</Tooltip>}>           
                <Form.Label htmlFor="stateDate" className="small">Reference date item last updated:</Form.Label>
                </OverlayTrigger>
                <Form.Control
                  name="stateData"
                  id="stateDate"
                  className="form-control border-secondary"
                  type="month"
                  value={stateDate.slice(0, 7)} // Display only yyyy-mm in the input
                  onChange={(e) => {
                    const selectedMonthYear = e.target.value; // yyyy-mm from input
                    const formattedDate = `${selectedMonthYear}-01`; // Set to the first of the month
                    setStateDate(formattedDate); // Update state with full yyyy-mm-dd format
                  }}
                  placeholder="yyyy-mm"
                />
                {errors.stateDate && <Alert className="alert alert-danger">{errors.stateDate}</Alert>}
              </Form.Group>

            </Modal.Body>
            <Modal.Footer>
            <Button variant="success" onClick={handleStatePensionChange }>Save</Button>
            </Modal.Footer>
            </Modal>

            {/* Occupational Modal */}
            <Modal show={isAddOccupationModalOpen} onHide={closeAddOccupationModal} size="xl" className="border-light">
              <Modal.Header closeButton className="bg-dark text-light border-light">
                <Modal.Title>Occupational Pension</Modal.Title>
              </Modal.Header>
              <Modal.Body className="bg-dark text-light border-light">
              <div className="mt-4" />
              <Row>
            {presetObject.occupational_pension
              .map((item, index) => ({ ...item, originalIndex: index })) // Add the original index to each item
              .sort((a, b) => a.name.localeCompare(b.name)) // Sort by 'name'
              .map((item) => (
                <Col key={item.originalIndex} md={3} className="mb-4">
                  <Card bg="dark" text="white" className="border-light">
                    <Card.Body>
                      <Card.Title className="h6">{item.name}</Card.Title>
                      <hr className="hr-line" />
                      <Card.Text className="small tight-spacing">Year (age): {item.year}</Card.Text>
                      <Card.Text className="small tight-spacing">Annual amount: {currencyIcon}{item.amount}</Card.Text>
                      <Card.Text className="small tight-spacing">Indexed: {item.index === '1' ? 'Yes' : 'No'}</Card.Text>
                      <Card.Text className="small tight-spacing">Inc. over index (%): {item.increase}</Card.Text>
                      <Card.Text className="small tight-spacing">Last Update: {new Date(item.last_update_date).toLocaleDateString('en-US', { year: 'numeric', month: 'long' })}</Card.Text>
                      <Button variant="danger" onClick={() => handleOccupationalPensionDeleteItem(item.originalIndex)}>Delete</Button>
                      <span> </span>
                      <Button variant="info" onClick={() => handleOccupationalPensionModityItem(item.originalIndex)}>Modify</Button>   
                    </Card.Body>
                  </Card>
                </Col>
              ))}
            </Row>
              </Modal.Body>
              <Modal.Footer className="bg-dark text-light border-light d-flex justify-content-between">
                <Button className="btn btn-success" onClick={openOccupationalModal}>Add Occupational Pension</Button>
                <Button variant="secondary" onClick={closeAddOccupationModal}>Close</Button>
              </Modal.Footer>
            </Modal>

            <Modal show={isOccupationalModalOpen} onHide={modifyingEntry ? handleOccupationalPensionChange : closeOccupationalModal}>
            <Modal.Header closeButton>
            <Modal.Title>Occupational Pension</Modal.Title>
            </Modal.Header>
            <Modal.Body>

            <Form.Group as={Col}>
                <OverlayTrigger placement={'right'} overlay={<Tooltip id={`tooltip-right`}>For own reference.</Tooltip>}>           
                <Form.Label htmlFor="occupationalName" className="small">Description:</Form.Label>
                </OverlayTrigger>
                <Form.Control name="occupationalName" id="occupationalName" className="form-control border-secondary" type="text" value={occupationalName} onChange={(e) => setOccupationalName(e.target.value)} placeholder="Name" />
                {errors.occupationalName && <Alert className="alert alert-danger">{errors.occupationalName}</Alert>}
              </Form.Group>
            <Form.Group as={Col}>
                <OverlayTrigger placement={'right'} overlay={<Tooltip id={`tooltip-right`}>Age when payments will start to be received (e.g. 65)</Tooltip>}>           
                <Form.Label htmlFor="occupationalYear" className="small">Year (age):</Form.Label>
                </OverlayTrigger>
                <Form.Control name="occupationalYear" id="occupationalYear" className="form-control border-secondary" type="number" value={occupationalYear} onChange={(e) => { if (/^\d*$/.test(e.target.value)) {setOccupationalYear(e.target.value)}}} placeholder="Year" min="1" />     
                {errors.occupationalYear && <Alert className="alert alert-danger">{errors.occupationalYear}</Alert>}
              </Form.Group>
              <Form.Group as={Col}>
                <OverlayTrigger placement={'right'} overlay={<Tooltip id={`tooltip-right`}>Expected annual value of payment in today's money</Tooltip>}>           
                <Form.Label htmlFor="occupationalAmount" className="small">Amount (current):</Form.Label>
                </OverlayTrigger>
                <Form.Control name="occupationalAmount" id="occupationalAmount" className="form-control border-secondary" type="number" value={occupationalAmount} onChange={(e) => setOccupationalAmount(e.target.value)} placeholder="Amount" min="1" />
                {errors.occupationalAmount && <Alert className="alert alert-danger">{errors.occupationalAmount}</Alert>}
                </Form.Group>
                <Form.Group as={Col}>
                <OverlayTrigger placement={'right'} overlay={<Tooltip id={`tooltip-right`}>Select whether the value of the payment is indexed to inflation each year</Tooltip>}>           
                <Form.Label htmlFor="occupationalIndex" className="small">Annual inc. (select):</Form.Label>
                </OverlayTrigger>
                <Form.Select name="occupationalIndex" id="occupationalIndex" className="form-control border-secondary" value={occupationalIndex} onChange={(e) => setOccupationalIndex(e.target.value)} defaultValue="1">
                  <option value="1">Indexed</option>
                  <option value="0">Not Indexed</option>
                </Form.Select>
                </Form.Group>
                <Form.Group as={Col}>
                <OverlayTrigger placement={'right'} overlay={<Tooltip id={`tooltip-right`}>Enter expected increase in value of the payment each year (over inflation if you selected 'Indexed' in the previous question or in absolute terms if you selected 'Not Indexed')</Tooltip>}>           
                <Form.Label htmlFor="occupationalIncrease" className="small">Inc. over index (%):</Form.Label>
                </OverlayTrigger>
                <Form.Control name="occupationalIndex" id="occupationalIndex" className="form-control border-secondary" type="number" value={occupationalIncrease} onChange={(e) => setOccupationalIncrease(e.target.value)} placeholder="%" />
                {errors.occupationalIncrease && <Alert className="alert alert-danger">{errors.occupationalIncrease}</Alert>}
                </Form.Group>
                <Form.Group as={Col}>
                <OverlayTrigger placement={'right'} overlay={<Tooltip id={`tooltip-right`}>For own reference when updating plan</Tooltip>}>           
                <Form.Label htmlFor="occupationalDate" className="small">Reference date item last updated:</Form.Label>
                </OverlayTrigger>
                <Form.Control
                  name="occupationalDate"
                  id="occupationalDate"
                  className="form-control border-secondary"
                  type="month"
                  value={occupationalDate.slice(0, 7)} // Display only yyyy-mm in the input
                  onChange={(e) => {
                    const selectedMonthYear = e.target.value; // yyyy-mm from input
                    const formattedDate = `${selectedMonthYear}-01`; // Set to the first of the month
                    setOccupationalDate(formattedDate); // Update state with full yyyy-mm-dd format
                  }}
                  placeholder="yyyy-mm"
                />
                {errors.occupationalDate && <Alert className="alert alert-danger">{errors.occupationalDate}</Alert>}
                </Form.Group>

                </Modal.Body>
                <Modal.Footer>
            <Button variant="success" onClick={handleOccupationalPensionChange }>Save</Button>
            </Modal.Footer>
            </Modal>

            <hr className="my-2" />           
 
            <div className="d-flex justify-content-between align-items-start">
            <h6>Future retirement saving / additions to asset portfolio:</h6>
            <OverlayTrigger
              placement="right"
              overlay={<Tooltip id="tooltip-right">Add recurring contributions (e.g. further (defined contribution) pension contributions up to retirement, net rental income from property assets not included in asset portfolio, etc) or one-off contributions (e.g. property sale) that will add to the portfolio value or offset expenditure during the simulation.</Tooltip>}>
            <span style={{ cursor: 'pointer', display: 'inline-block', verticalAlign: 'top' }}><FaInfoCircle style={{ color: '#adb5bd', fontSize: '1rem' }} /></span>
            </OverlayTrigger>
            </div>

            <div className="d-grid gap-2">
              <Button variant="secondary" size="" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} onClick={openAddContributionModal}>Add recurring contributions
              <span className="badge-space"><Badge bg="info">{presetObject.contribution_inflows.length ? presetObject.contribution_inflows.length : null}</Badge></span>
              </Button>
            </div>
            <div className="my-2" />
            <div className="d-grid gap-2">
              <Button variant="secondary" size="" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} onClick={openAddExtraordinaryModal}>Add one-off contributions
              <span className="badge-space"><Badge bg="info">{presetObject.extraordinary_inflows.length ? presetObject.extraordinary_inflows.length : null}</Badge></span>
              </Button>
            </div>

            {/* Contribution Modal */}
            <Modal show={isAddContributionModalOpen} onHide={closeAddContributionModal} size="xl" className="border-light">
              <Modal.Header closeButton className="bg-dark text-light border-light">
                <Modal.Title>Recurring Contributions</Modal.Title>
              </Modal.Header>
              <Modal.Body className="bg-dark text-light border-light">
            <div className="mt-4" />

            <Row>
              {presetObject.contribution_inflows
                .map((item, index) => ({ ...item, originalIndex: index })) // Add the original index to each item
                .sort((a, b) => a.name.localeCompare(b.name)) // Sort by 'name'
                .map((item) => (
                  <Col key={item.originalIndex} md={3} className="mb-4">
                    <Card bg="dark" text="white" className="border-light">
                      <Card.Body>
                        <Card.Title className="h6">{item.name}</Card.Title>
                        <hr className="hr-line" />
                        <Card.Text className="small tight-spacing">Start year (age): {item.year}</Card.Text>
                        <Card.Text className="small tight-spacing">End year (age): {item.yearend}</Card.Text>                      
                        <Card.Text className="small tight-spacing">Annual amount: {currencyIcon}{item.amount}</Card.Text>
                        <Card.Text className="small tight-spacing">Fund type: {item.type === '0' ? 'General Account' : item.type === '1' ? 'Tax Exempt' : 'Tax Deferred'}</Card.Text>
                        <Card.Text className="small tight-spacing">Indexed: {item.index === '1' ? 'Yes' : 'No'}</Card.Text>
                        <Card.Text className="small tight-spacing">Inc. over index (%): {item.increase}</Card.Text>
                        <Card.Text className="small tight-spacing">Last Update: {new Date(item.last_update_date).toLocaleDateString('en-US', { year: 'numeric', month: 'long' })}</Card.Text>
                        <Button variant="danger" onClick={() => handleContributionDeleteItem(item.originalIndex)}>Delete</Button>
                        <span> </span>
                        <Button variant="info" onClick={() => handleContributionModityItem(item.originalIndex)}>Modify</Button>   
                      </Card.Body>
                    </Card>
                  </Col>
                ))}
            </Row>

            </Modal.Body>
              <Modal.Footer className="bg-dark text-light border-light d-flex justify-content-between">
                <Button className="btn btn-success" onClick={openContributionModal}>Add Recurring Contribution</Button>
                <Button variant="secondary" onClick={closeAddContributionModal}>Close</Button>
              </Modal.Footer>
            </Modal>

            <Modal show={isContributionModalOpen} onHide={modifyingEntry ? handleContributionChange : closeContributionModal}>
            <Modal.Header closeButton>
            <Modal.Title>Recurring Contribution</Modal.Title>
            </Modal.Header>
            <Modal.Body>

            <Form.Group as={Col}>
              <OverlayTrigger placement={'right'} overlay={<Tooltip id={`tooltip-right`}>For own reference</Tooltip>}>           
              <Form.Label htmlFor="contributionName" className="small">Description:</Form.Label>
              </OverlayTrigger>
                <Form.Control name="contributionName" id="contributionName" className="form-control border-secondary" type="text" value={contributionName} onChange={(e) => setContributionName(e.target.value)} placeholder="Name" />
                {errors.contributionName && <Alert className="alert alert-danger">{errors.contributionName}</Alert>}
              </Form.Group>
              <Form.Group as={Col}>
                <OverlayTrigger placement={'right'} overlay={<Tooltip id={`tooltip-right`}>Age when contributions will start to be made (e.g. at current age, start age of the plan)</Tooltip>}>           
                <Form.Label htmlFor="contributionYear" className="small">Start year (age):</Form.Label>
                </OverlayTrigger>
                <Form.Control name="contributionYear" id="contributionYear" className="form-control border-secondary" type="number" value={contributionYear} onChange={(e) => { if (/^\d*$/.test(e.target.value)) {setContributionYear(e.target.value)}}} placeholder="AgeStart" min="1" />
                {errors.contributionYear && <Alert className="alert alert-danger">{errors.contributionYear}</Alert>}
              </Form.Group>
              <Form.Group as={Col}>
                <OverlayTrigger placement={'right'} overlay={<Tooltip id={`tooltip-right`}>Age when payments will contributions will end (e.g. at retirement age, 65)</Tooltip>}>           
                <Form.Label htmlFor="contributionYearEnd" className="small">End year (age):</Form.Label>
                </OverlayTrigger>
                <Form.Control name="contributionYearEnd" id="contributionYearEnd" className="form-control border-secondary" type="number" value={contributionYearEnd} onChange={(e) => { if (/^\d*$/.test(e.target.value)) {setContributionYearEnd(e.target.value)}}} placeholder="AgeEnd" min="1" />
                {errors.contributionYearEnd && <Alert className="alert alert-danger">{errors.contributionYearEnd}</Alert>}
              </Form.Group>
              <Form.Group as={Col}>
                <OverlayTrigger placement={'right'} overlay={<Tooltip id={`tooltip-right`}>Annual contribution in today's money</Tooltip>}>           
                <Form.Label htmlFor="contributionAmount" className="small">Amount (current):</Form.Label>
                </OverlayTrigger>
                <Form.Control name="contributionAmount" id="contributionAmount" className="form-control border-secondary" type="number" value={contributionAmount} onChange={(e) => setContributionAmount(e.target.value)} placeholder="Amount" min="1" />
                {errors.contributionAmount && <Alert className="alert alert-danger">{errors.contributionAmount}</Alert>}
              </Form.Group>
              <Form.Group as={Col}>
                <OverlayTrigger placement={'right'} overlay={<Tooltip id={`tooltip-right`}>Select type of account contribution will be made to. If you enter tax rates in the plan, these tax rates will be applied in their respective ways to either the simulated returns in the fund (general account) or to withdrawals from the fund (tax deferred account).<br/>Examples of tax exempt accounts are UK: ISA, US: Roth IRA, 529 or HSA.<br/>Examples of tax deferred accounts are UK: SIPP or DC Workplace Pension, US: 401k or 403b.</Tooltip>}>           
                <Form.Label htmlFor="contributionType" className="small">Fund type:</Form.Label>
                </OverlayTrigger>
                <Form.Select name="contributionType" id="contributionType" className="form-control border-secondary" value={contributionType} onChange={(e) => setContributionType(e.target.value)} defaultValue="0">
                  <option value="0">General Account</option>
                  <option value="2">Tax Deferred Account</option>
                  <option value="1">Tax Exempt Account</option>
                </Form.Select>
              </Form.Group>
              <Form.Group as={Col}>
                <OverlayTrigger placement={'right'} overlay={<Tooltip id={`tooltip-right`}>Select whether the value of the contribution is expected to index with inflation each year</Tooltip>}>           
                <Form.Label htmlFor="contributionIndex" className="small">Index (Y/N):</Form.Label>
                </OverlayTrigger>
                <Form.Select name="contributionIndex" id="contributionIndex" className="form-control border-secondary" value={contributionIndex} onChange={(e) => setContributionIndex(e.target.value)} defaultValue="1">
                  <option value="1">Indexed</option>
                  <option value="0">Not Indexed</option>
                </Form.Select>
              </Form.Group>
              <Form.Group as={Col}>
                <OverlayTrigger placement={'right'} overlay={<Tooltip id={`tooltip-right`}>Enter expected increase in value of the contribution each year (over inflation if you selected 'Indexed' in the previous question or in absolute terms if you selected 'Not Indexed')</Tooltip>}>           
                <Form.Label htmlFor="contributionIncrease" className="small">Inc. over index (%):</Form.Label>
                </OverlayTrigger>
                <Form.Control name="contributionIncrease" id="contributionIncrease" className="form-control border-secondary" type="number" value={contributionIncrease} onChange={(e) => setContributionIncrease(e.target.value)} placeholder="%" />
                {errors.contributionIncrease && <Alert className="alert alert-danger">{errors.contributionIncrease}</Alert>}
              </Form.Group>
              <Form.Group as={Col}>
                <OverlayTrigger placement={'right'} overlay={<Tooltip id={`tooltip-right`}>For own reference when updating plan</Tooltip>}>           
                <Form.Label htmlFor="contributionDate" className="small">Reference date item last updated:</Form.Label>
                </OverlayTrigger>
                <Form.Control
                  name="contributionDate"
                  id="contributionDate"
                  className="form-control border-secondary"
                  type="month"
                  value={contributionDate.slice(0, 7)} // Display only yyyy-mm in the input
                  onChange={(e) => {
                    const selectedMonthYear = e.target.value; // yyyy-mm from input
                    const formattedDate = `${selectedMonthYear}-01`; // Set to the first of the month
                    setContributionDate(formattedDate); // Update state with full yyyy-mm-dd format
                  }}
                  placeholder="yyyy-mm"
                />
                {errors.contributionDate && <Alert className="alert alert-danger">{errors.contributionDate}</Alert>}
              </Form.Group>

                </Modal.Body>
                <Modal.Footer>
                <Button variant="success" onClick={handleContributionChange }>Save</Button>
                </Modal.Footer>
                </Modal>
            <div className="my-2" />



            {/* Extraordinary Modal */}
            <Modal show={isAddExtraordinaryModalOpen} onHide={closeAddExtraordinaryModal} size="xl" className="border-light">
              <Modal.Header closeButton className="bg-dark text-light border-light">
                <Modal.Title>One-Off Contributions</Modal.Title>
              </Modal.Header>
              <Modal.Body className="bg-dark text-light border-light">
              <div className="mt-4" />
              <Row>
                {presetObject.extraordinary_inflows
                  .map((item, index) => ({ ...item, originalIndex: index })) // Add the original index to each item
                  .sort((a, b) => a.name.localeCompare(b.name)) // Sort by 'name'
                  .map((item) => (
                    <Col key={item.originalIndex} md={3} className="mb-4">
                      <Card bg="dark" text="white" className="border-light">
                        <Card.Body>
                          <Card.Title className="h6">{item.name}</Card.Title>
                          <hr className="hr-line" />
                          <Card.Text className="small tight-spacing">Year (age): {item.year}</Card.Text>                   
                          <Card.Text className="small tight-spacing">Annual amount: {currencyIcon}{item.amount}</Card.Text>
                          <Card.Text className="small tight-spacing">Fund type: {item.type === '0' ? 'General Account' : item.type === '1' ? 'Tax Exempt' : 'Tax Deferred'}</Card.Text>
                          <Card.Text className="small tight-spacing">Indexed: {item.index === '1' ? 'Yes' : 'No'}</Card.Text>
                          <Card.Text className="small tight-spacing">Inc. over index (%): {item.increase}</Card.Text>
                          <Card.Text className="small tight-spacing">Last Update: {new Date(item.last_update_date).toLocaleDateString('en-US', { year: 'numeric', month: 'long' })}</Card.Text>
                          <Button variant="danger" onClick={() => handleExtraDeleteItem(item.originalIndex)}>Delete</Button>
                          <span> </span>
                          <Button variant="info" onClick={() => handleExtraModityItem(item.originalIndex)}>Modify</Button>                      
                        </Card.Body>
                      </Card>
                    </Col>
                  ))}
                </Row>

              </Modal.Body>
              <Modal.Footer className="bg-dark text-light border-light d-flex justify-content-between">
                <Button className="btn btn-success" onClick={openExtraordinaryModal}>Add One-Off Contribution</Button>
                <Button variant="secondary" onClick={closeAddExtraordinaryModal}>Close</Button>
              </Modal.Footer>
            </Modal>

            <Modal show={isExtraordinaryModalOpen} onHide={modifyingEntry ? handleExtraInflowChange : closeExtraordinaryModal}>
            <Modal.Header closeButton>
            <Modal.Title>One-Off Contribution</Modal.Title>
            </Modal.Header>
            <Modal.Body>

            <Form.Group>
              <OverlayTrigger placement={'right'} overlay={<Tooltip id={`tooltip-right`}>For own reference</Tooltip>}>           
              <Form.Label htmlFor="extraName" className="small">Description:</Form.Label>
              </OverlayTrigger>
              <Form.Control name="extraName" id="extraName" className="form-control border-secondary" type="text" value={extraName} onChange={(e) => setExtraName(e.target.value)} placeholder="Name" />
              {errors.extraName && <Alert className="alert alert-danger">{errors.extraName}</Alert>}
              </Form.Group>  
            <Form.Group>
            <OverlayTrigger placement={'right'} overlay={<Tooltip id={`tooltip-right`}>Age when one-off contributions will be made</Tooltip>}>           
            <Form.Label htmlFor="extraYear" className="small">Year (age):</Form.Label>
            </OverlayTrigger>
              <Form.Control name="extraYear" id="extraYear" className="form-control border-secondary" type="number" value={extraYear} onChange={(e) => { if (/^\d*$/.test(e.target.value)) {setExtraYear(e.target.value)}}} placeholder="Year" min="1" />
              {errors.extraYear && <Alert className="alert alert-danger">{errors.extraYear}</Alert>}
              </Form.Group>
              <Form.Group>
              <OverlayTrigger placement={'right'} overlay={<Tooltip id={`tooltip-right`}>One-off contribution in today's money</Tooltip>}>           
              <Form.Label htmlFor="extraAmount" className="small">Amount (current):</Form.Label>
              </OverlayTrigger>
              <Form.Control name="extraAmount" id="extraAmount" className="form-control border-secondary" type="number" value={extraAmount} onChange={(e) => setExtraAmount(e.target.value)} placeholder="Amount" min="1" />
              {errors.extraAmount && <Alert className="alert alert-danger">{errors.extraAmount}</Alert>}
                </Form.Group>
                <Form.Group>
                <OverlayTrigger placement={'right'} overlay={<Tooltip id={`tooltip-right`}>Select type of account one-off contribution will be made to. If you enter tax rates in the plan, these tax rates will be applied in their respective ways to either the simulated returns in the fund (general account) or to withdrawals from the fund (tax deferred account).<br/>Examples of tax exempt accounts are UK: ISA, US: Roth IRA, 529 or HSA.<br/>Examples of tax deferred accounts are UK: SIPP or DC Workplace Pension, US: 401k or 403b.</Tooltip>}>           
                <Form.Label htmlFor="extraType" className="small">Fund type:</Form.Label>
                </OverlayTrigger>
                <Form.Select name="extraType" id="extraType" className="form-control border-secondary" value={extraType} onChange={(e) => setExtraType(e.target.value)} defaultValue="1">
                  <option value="0">General Account</option>
                  <option value="2">Tax Deferred Account</option>
                  <option value="1">Tax Exempt Account</option>
                </Form.Select>
                </Form.Group>
                <Form.Group>
                <OverlayTrigger placement={'right'} overlay={<Tooltip id={`tooltip-right`}>Select whether the value of the one-off contribution is expected to index with inflation each year</Tooltip>}>           
                <Form.Label htmlFor="extraIndex" className="small">Annual inc. (select):</Form.Label>
                </OverlayTrigger>
                <Form.Select name="extraIndex" id="extraIndex" className="form-control border-secondary" value={extraIndex} onChange={(e) => setExtraIndex(e.target.value)} defaultValue="1">
                  <option value="1">Indexed</option>
                  <option value="0">Not Indexed</option>
                </Form.Select>
                </Form.Group>
                <Form.Group>
                <OverlayTrigger placement={'right'} overlay={<Tooltip id={`tooltip-right`}>Enter expected increase in value of the one-off contribution each year (over inflation if you selected 'Indexed' in the previous question or in absolute terms if you selected 'Not Indexed')</Tooltip>}>           
                <Form.Label htmlFor="extraIncrease" className="small">Inc. over index (%):</Form.Label>
                </OverlayTrigger>
                <Form.Control name="extraIncrease" id="extraIncrease" className="form-control border-secondary" type="number" value={extraIncrease} onChange={(e) => setExtraIncrease(e.target.value)} placeholder="%" />      
                {errors.extraIncrease && <Alert className="alert alert-danger">{errors.extraIncrease}</Alert>}                
                </Form.Group>
                <Form.Group>
                <OverlayTrigger placement={'right'} overlay={<Tooltip id={`tooltip-right`}>For own reference when updating plan</Tooltip>}>           
                <Form.Label htmlFor="extraDate" className="small">Reference date item last updated:</Form.Label>
                </OverlayTrigger>
                <Form.Control
                  name="extraDate"
                  id="extraDate"
                  className="form-control border-secondary"
                  type="month"
                  value={extraDate.slice(0, 7)} // Display only yyyy-mm in the input
                  onChange={(e) => {
                    const selectedMonthYear = e.target.value; // yyyy-mm from input
                    const formattedDate = `${selectedMonthYear}-01`; // Set to the first of the month
                    setExtraDate(formattedDate); // Update state with full yyyy-mm-dd format
                  }}
                  placeholder="yyyy-mm"
                />       
                {errors.extraDate && <Alert className="alert alert-danger">{errors.extraDate}</Alert>}                
                </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="success" onClick={handleExtraInflowChange }>Save</Button>
                </Modal.Footer>
                </Modal>

            <hr className="my-2" />           

            <div className="d-flex justify-content-between align-items-start">
            <h6>Retirement withdrawal approach and planned expenditure:</h6>
            <OverlayTrigger
              placement="right"
              overlay={<Tooltip id="tooltip-right">Select a withdrawal approach that either covers all planned expenditure or targets a percentage rate of portfolio withdrawal with option of inputting minimum planned expenditure to set a floor for the annual withdrawal amount.</Tooltip>}>
            <span style={{ cursor: 'pointer', display: 'inline-block', verticalAlign: 'top' }}><FaInfoCircle style={{ color: '#adb5bd', fontSize: '1.0rem' }} /></span>
            </OverlayTrigger>
            </div>

            <OverlayTrigger placement={'right'} overlay={<Tooltip id={`tooltip-right`} className={"custom-tooltip"}>See 'Withdrawal Approaches' in Learning menu<br />- Fixed: fixed withdrawal taken each year irrespective of market performance.<br />- Fixed with bonus: as fixed but additional 'bonus' withdrawal if portfolio value above safe funding level.<br />- Fixed percentage withdrawal: withdrawal level calculated each year as a % of portfolio value with option to add minimum withdrawal level<br />- Yale Endownment: withdrawal = x * previous withdrawal + (1 - x) * % portfolio value<br />- Vanguard Dynamic Spending: % portfolio value with cap / floor on max real-term change from previous withdrawal<br />- Bogleheads Variable Percentage Withdrawal: % portfolio value taken from look up table adjusted for age and asset mix</Tooltip>}>           
            <Form.Label htmlFor="dynamic_option" className="fw-light small">Select portfolio withdrawal approach:</Form.Label>
            </OverlayTrigger>
            <Form.Select name="dynamic_option" id="dynamic_option" className="form-control bg-secondary border-secondary text-light" onChange={(e) => handleSelectChange('dynamic_option', e.target.value)} value={presetObject.dynamic_option}>
              <option value="constant" >Fixed amount</option>
              <option value="constantbonus">Fixed amount with bonus</option>
              <option value="proportional" >Fixed percentage withdrawal</option>
              <option value="yale" >Yale endownment rule</option>
              <option value="vanguard" >Vanguard dynamic spending rule</option>
              <option value="vpw" >Bogleheads variable percentage withdrawal</option>
		        </Form.Select>
            {presetObject.dynamic_option === 'constant' || presetObject.dynamic_option === 'constantbonus' ? (
              <>
              </>
              ) : presetObject.dynamic_option === 'yale' ? (
              <>
              <Row className="align-items-end">
              <Form.Group as={Col}>
              <FormTemplate formlabel="Age start withdrawal:" formname="year_retire" type="number" overlaytext="Age in simulation to start withdrawal from. If withdrawal from year 1, enter same age as in start simulation" presetObject={presetObject} handleObjectChange={handleObjectChange} errors={errors} />
              </Form.Group>
              </Row>

              <Row className="align-items-end">
              <Form.Group as={Col}>
              <FormTemplate formlabel="Annual draw. rate (as % of port.):" formname="target_withdrawal_percent" type="number" overlaytext="Annual income withdrawal rate (as % of portfolio value each year through simulation) (net of tax)" presetObject={presetObject} handleObjectChange={handleObjectChange} errors={errors} />
              </Form.Group>
  
              <Form.Group as={Col}>
              <FormTemplate formlabel="Weight. of previous year's draw. (%):" formname="yale_weighting" type="number" overlaytext="Income withdrawal = weighting x previous_year_withdrawal + (1 - weighting) * (withdrawal rate * portfolio value)" presetObject={presetObject} handleObjectChange={handleObjectChange} errors={errors} /> 
              </Form.Group>
              </Row>
              </>
              ) : presetObject.dynamic_option === 'vanguard' ? (
              <>
              <Row className="align-items-end">
              <Form.Group as={Col}>
              <FormTemplate formlabel="Age start withdrawal:" formname="year_retire" type="number" overlaytext="Age in simulation to start withdrawal from. If withdrawal from year 1, enter same age as in start simulation" presetObject={presetObject} handleObjectChange={handleObjectChange} errors={errors} />
              </Form.Group>

              <Form.Group as={Col}>
              <FormTemplate formlabel="Annual draw. (as % of port.):" formname="target_withdrawal_percent" type="number" overlaytext="Annual income withdrawal rate (as % of portfolio value each year through simulation) (net of tax)" presetObject={presetObject} handleObjectChange={handleObjectChange} errors={errors} />
              </Form.Group>
              </Row>

              <Row className="align-items-end">
              <Form.Group as={Col}>
              <FormTemplate formlabel="Maximum annual real-term reduction (%):" formname="vanguard_decrease_floor" type="number" overlaytext="Maximum annual real-term reduction (%)" presetObject={presetObject} handleObjectChange={handleObjectChange} errors={errors} />
              </Form.Group>
              <Form.Group as={Col}>

              <FormTemplate formlabel="Maximum annual real-term increase (%):" formname="vanguard_increase_ceiling" type="number" overlaytext="Maximum annual real-term increase (%)" presetObject={presetObject} handleObjectChange={handleObjectChange} errors={errors} />
                
              </Form.Group>
              </Row>
              </>
              ) : presetObject.dynamic_option === 'vpw' ? (<>
              <Form.Group as={Col}>
              <FormTemplate formlabel="Age start withdrawal:" formname="year_retire" type="number" overlaytext="Age in simulation to start withdrawal from. If withdrawal from year 1, enter same age as in start simulation" presetObject={presetObject} handleObjectChange={handleObjectChange} errors={errors} />
              </Form.Group>        
              </>)
              : (
              <>
              <Row className="align-items-end">
              <Form.Group as={Col}>
              <FormTemplate formlabel="Age start withdrawal:" formname="year_retire" type="number" overlaytext="Age in simulation to start withdrawal from. If withdrawal from year 1, enter same age as in start simulation" presetObject={presetObject} handleObjectChange={handleObjectChange} errors={errors} />
              </Form.Group>
              
              <Form.Group as={Col}>
              <FormTemplate formlabel="Annual withdrawal (as % of portfolio):" formname="target_withdrawal_percent" type="number" overlaytext="Annual total income withdrawal (as % of portfolio value each year through simulation) (net of tax)." presetObject={presetObject} handleObjectChange={handleObjectChange} errors={errors} />
              </Form.Group>
              </Row>
              </>  
              )}

            <div className="mt-2"></div>

            {presetObject.dynamic_option !== 'vpw' ? 
            <>
            <div className="my-2" />

            <div className="my-2" />
            <div className="d-grid gap-2">
              <Button variant="secondary" size="" style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }} onClick={openAddIncomeRequirementsModal}>
              {presetObject.dynamic_option === 'proportional' || presetObject.dynamic_option === 'yale' || presetObject.dynamic_option === 'vanguard' ? (<span style={{ flexGrow: 1, textAlign: 'left' }}>Add minimum planned expenditure</span>) : (<span style={{ flexGrow: 1, textAlign: 'left' }}>Add planned expenditure</span>)}     
                <span className="badge-space"><Badge bg="info">{presetObject.income_requirements.length ? presetObject.income_requirements.length : null}</Badge></span>
              </Button>
            </div>

            {/* IncomeRequirements Modal */}
            <Modal show={isAddIncomeRequirementsModalOpen} onHide={closeAddIncomeRequirementsModal} size="xl" className="border-light">
              <Modal.Header closeButton className="bg-dark text-light border-light">
                <Modal.Title>Planned Expenditure</Modal.Title>
              </Modal.Header>
              <Modal.Body className="bg-dark text-light border-light">
              
              <div className="mt-4" />

              <Row>
                {presetObject.income_requirements
                  .map((item, index) => ({ ...item, originalIndex: index })) // Add the original index to each item
                  .sort((a, b) => a.name.localeCompare(b.name)) // Sort by 'name'
                  .map((item) => (
                    <Col key={item.originalIndex} md={3} className="mb-4">
                      <Card bg="dark" text="white" className="border-light">
                        <Card.Body>
                          <Card.Title className="h6">{item.name}</Card.Title>
                          <hr className="hr-line" />

                          <Card.Text className="small tight-spacing">{item.single_year === '0' ? 'Multi-year expenditure' : 'Single-year (one-off)'}</Card.Text>
                          {item.single_year === '0' ? 
                          <>
                          <Card.Text className="small tight-spacing">Start year (age): {item.year}</Card.Text>     
                          <Card.Text className="small tight-spacing">End year (age): {item.yearend}</Card.Text>
                          </>
                          :
                          <Card.Text className="small tight-spacing">Year (age): {item.year}</Card.Text>
                          }     
                          <Card.Text className="small tight-spacing">Annual amount: {currencyIcon}{item.amount}</Card.Text>
                          <Card.Text className="small tight-spacing">Indexed: {item.index === '1' || item.index === '2' ? 'Yes' : 'No'}</Card.Text>
                          <Card.Text className="small tight-spacing">Inc. to index up to start (%): {item.increase}</Card.Text>
                          <Card.Text className="small tight-spacing">Inc. to index from start (%): {item.increase2}</Card.Text>   
                          <Card.Text className="small tight-spacing">Last Update: {new Date(item.last_update_date).toLocaleDateString('en-US', { year: 'numeric', month: 'long' })}</Card.Text>
                          {item.single_year === '0' ? 
                          <>
                          </>
                          :
                          <br></br>
                          }                            
                          <Button variant="danger" onClick={() => handleIncomeDeleteItem(item.originalIndex)}>Delete</Button>
                          <span> </span>
                          <Button variant="info" onClick={() => handleIncomeModityItem(item.originalIndex)}>Modify</Button>   
                        </Card.Body>
                      </Card>
                    </Col>
                  ))}
              </Row>

              </Modal.Body>
              <Modal.Footer className="bg-dark text-light border-light d-flex justify-content-between">
                <Button className="btn btn-success" onClick={openIncomeModal}>Add Expenditure</Button>
                <Button variant="secondary" onClick={closeAddIncomeRequirementsModal}>Close</Button>
              </Modal.Footer>
            </Modal>

            <Modal show={isIncomeModalOpen} onHide={modifyingEntry ? handleIncomeChange : closeIncomeModal}>
            <Modal.Header closeButton>
            <Modal.Title>Planned Expenditure</Modal.Title>
            </Modal.Header>
            <Modal.Body>

            <Form.Group>
              <OverlayTrigger placement={'right'} overlay={<Tooltip id={`tooltip-right`}>For own reference</Tooltip>}>           
              <Form.Label htmlFor="incomeName" className="small">Description:</Form.Label>
              </OverlayTrigger>
              <Form.Control name="incomeName" id="incomeName" className="form-control border-secondary" type="text" value={incomeName} onChange={(e) => setIncomeName(e.target.value)} placeholder="Name" />
              {errors.incomeName && <Alert className="alert alert-danger">{errors.incomeName}</Alert>}
            </Form.Group>

              <Form.Group>
                <OverlayTrigger placement={'right'} overlay={<Tooltip id={`tooltip-right`}>Select whether expenditure is single year (one-off) or mulit-year.</Tooltip>}>           
                <Form.Label htmlFor="incomeSingleYear" className="small">Single / multi year:</Form.Label>
                </OverlayTrigger>
                <Form.Select name="incomeSingleYear" id="incomeSingleYear" className="form-control border-secondary" value={incomeSingleYear} onChange={(e) => setIncomeSingleYear(e.target.value)}>
                  <option value="1">Single-year (one-off)</option>
                  <option value="0">Multi-year</option>
                </Form.Select>
              </Form.Group>
              {incomeSingleYear === '0' ? <>
              <Form.Group>
              <OverlayTrigger placement={'right'} overlay={<Tooltip id={`tooltip-right`}>Age when expenditure will start to be made (e.g. at retirement age, 65)</Tooltip>}>           
                <Form.Label htmlFor="incomeYear" className="small">Start year (age):</Form.Label>
                </OverlayTrigger>           
                <Form.Control name="incomeYear" id="incomeYear" className="form-control border-secondary" type="number" value={incomeYear} onChange={(e) => { if (/^\d*$/.test(e.target.value)) {setIncomeYear(e.target.value)}}} placeholder="AgeStart" min="1" />
              </Form.Group>
              {errors.incomeYear && <Alert className="alert alert-danger">{errors.incomeYear}</Alert>}
              <Form.Group>
                <OverlayTrigger placement={'right'} overlay={<Tooltip id={`tooltip-right`}>Age when expenditure will end (e.g. at end the simulation, 100)</Tooltip>}>           
                <Form.Label htmlFor="incomeYearEnd" className="small">End year (age):</Form.Label>
                </OverlayTrigger>
                <Form.Control name="incomeYearEnd" id="incomeYearEnd" className="form-control border-secondary" type="number" value={incomeYearEnd} onChange={(e) => { if (/^\d*$/.test(e.target.value)) setIncomeYearEnd(e.target.value)}} placeholder="AgeEnd" min="1" />
              </Form.Group>
              {errors.incomeYearEnd && <Alert className="alert alert-danger">{errors.incomeYearEnd}</Alert>}
              </> : <>
              <Form.Group>
              <OverlayTrigger placement={'right'} overlay={<Tooltip id={`tooltip-right`}>Age (in that year) when expenditure will happen</Tooltip>}>           
                <Form.Label htmlFor="incomeYear" className="small">Year (age):</Form.Label>
                </OverlayTrigger>           
                <Form.Control name="incomeYear" id="incomeYear" className="form-control border-secondary" type="number" value={incomeYear} onChange={(e) => { if (/^\d*$/.test(e.target.value)) {setIncomeYear(e.target.value); setIncomeYearEnd(parseInt(e.target.value) + 1)}}} placeholder="Age" min="1" />
              </Form.Group>
              {errors.incomeYear && <Alert className="alert alert-danger">{errors.incomeYear}</Alert>}
              </>}
              <Form.Group>
              <OverlayTrigger placement={'right'} overlay={<Tooltip id={`tooltip-right`}>Expenditure amount in today's money</Tooltip>}>           
              <Form.Label htmlFor="incomeAmount" className="small">Amount (current):</Form.Label>
              </OverlayTrigger>
              <Form.Control name="incomeAmount" id="incomeAmount" className="form-control border-secondary" type="number" value={incomeAmount} onChange={(e) => setIncomeAmount(e.target.value)} placeholder="Amount" min="1" />
                {errors.incomeAmount && <Alert className="alert alert-danger">{errors.incomeAmount}</Alert>}
                </Form.Group>
                <Form.Group>
                <OverlayTrigger placement={'right'} overlay={<Tooltip id={`tooltip-right`}>Select whether the expenditure amount is expected to index with inflation each year. If you select 'differential rates' you can enter different rates of annual increase up to the age when the expenditure begins and after that age.</Tooltip>}>           
                <Form.Label htmlFor="incomeIndex" className="small">Index (Y/N):</Form.Label>
                </OverlayTrigger>
                {/* <Form.Select name="incomeIndex" id="incomeIndex" className="form-control border-secondary" value={incomeIndex} onChange={(e) => setIncomeIndex(e.target.value)} defaultValue="1"> */}
                <Form.Select name="incomeIndex" id="incomeIndex" className="form-control border-secondary" value={incomeIndex} onChange={(e) => setIncomeIndex(e.target.value)}>
                  <option value="1">Indexed</option>
                  <option value="2">Indexed (differential rates)</option>
                  <option value="0">Not Indexed</option>
                  <option value="3">Not Indexed (differential rates)</option>
                </Form.Select>
                </Form.Group>

                {incomeIndex === '0' || incomeIndex === '1' ? 
                <>
                <Form.Group>
                <OverlayTrigger placement={'right'} overlay={<Tooltip id={`tooltip-right`}>Enter expected increase in expenditure amount each year (over inflation if you selected 'Indexed' in the previous question or in absolute terms if you selected 'Not Indexed')</Tooltip>}>           
                <Form.Label htmlFor="incomeIncrease" className="small">Inc. to index (%):</Form.Label>
                </OverlayTrigger>
                <Form.Control name="incomeIncrease" id="incomeIncrease" className="form-control border-secondary" type="number" value={incomeIncrease} onChange={(e) => setIncomeIncrease(e.target.value)} placeholder="%" />
                {errors.incomeIncrease && <Alert className="alert alert-danger">{errors.incomeIncrease}</Alert>}
                </Form.Group>
                <Form.Group>
                </Form.Group>
                </>
                :
                <>
                <Form.Group>
                <OverlayTrigger placement={'right'} overlay={<Tooltip id={`tooltip-right`}>Enter expected increase in expenditure amount each year (over inflation if you selected 'Indexed' in the previous question or in absolute terms if you selected 'Not Indexed')</Tooltip>}>           
                <Form.Label htmlFor="incomeIncrease" className="small">Inc. to index up to expenditure start age(%):</Form.Label>
                </OverlayTrigger>
                <Form.Control name="incomeIncrease" id="incomeIncrease" className="form-control border-secondary" type="number" value={incomeIncrease} onChange={(e) => setIncomeIncrease(e.target.value)} placeholder="%" />
                {errors.incomeIncrease && <Alert className="alert alert-danger">{errors.incomeIncrease}</Alert>}
                </Form.Group>                  
                <Form.Group>
                <OverlayTrigger placement={'right'} overlay={<Tooltip id={`tooltip-right`}>Enter expected increase in expenditure amount of the one-off contribution each year (over inflation if you selected 'Indexed' in the previous question or in absolute terms if you selected 'Not Indexed')</Tooltip>}>           
                <Form.Label htmlFor="incomeIncrease2" className="small">Inc. to index from expenditure start age(%):</Form.Label>
                </OverlayTrigger>
                <Form.Control name="incomeIncrease2" id="incomeIncrease2" className="form-control border-secondary" type="number" value={incomeIncrease2} onChange={(e) => setIncomeIncrease2(e.target.value)} placeholder="%" />
                {errors.incomeIncrease2 && <Alert className="alert alert-danger">{errors.incomeIncrease2}</Alert>}
                </Form.Group>
                </>}
                <Form.Group>
                <OverlayTrigger placement={'right'} overlay={<Tooltip id={`tooltip-right`}>For own reference when updating plan. Used by index adjust.</Tooltip>}>           
                <Form.Label htmlFor="incomeDate" className="small">Reference date item last updated:</Form.Label>
                </OverlayTrigger>
                
                <Form.Control
                  name="incomeDate"
                  id="incomeDate"
                  className="form-control border-secondary"
                  type="month"
                  value={incomeDate.slice(0, 7)} // Display only year and month
                  onChange={(e) => {
                    const selectedMonthYear = e.target.value; // yyyy-mm from input
                    const formattedDate = `${selectedMonthYear}-01`; // Set date to yyyy-mm-01
                    setIncomeDate(formattedDate); // Update state with full date format
                  }}
                  placeholder="yyyy-mm"
                />
                
                {errors.incomeDate && <Alert className="alert alert-danger">{errors.incomeDate}</Alert>}
                </Form.Group>

                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-between">
                  <Button variant="success" onClick={openIndexAdjustModal}>Index adjust amount</Button>
                  <Button variant="success" onClick={handleIncomeChange}>Save</Button>
                </Modal.Footer>
                </Modal>

            </> : <></>}  

            <div className="my-2" />
            {presetObject.dynamic_option === 'constantflex' || presetObject.dynamic_option === 'constantbonus' ? 

            <div className="d-grid gap-2">
              <Button variant="secondary" size="" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} onClick={openAddFlexIncomeRequirementsModal}>Add bonus expenditure
              <span className="badge-space"><Badge bg="info">{presetObject.flex_income_requirements.length ? presetObject.flex_income_requirements.length : null}</Badge></span>
              </Button>
            </div>

            : <></>}

            {/* FlexIncomeRequirements Modal */}
            <Modal show={isAddFlexIncomeRequirementsModalOpen} onHide={closeAddFlexIncomeRequirementsModal} size="xl" className="border-light">
              <Modal.Header closeButton className="bg-dark text-light border-light">
                <Modal.Title>Bonus Expenditure</Modal.Title>
              </Modal.Header>
              <Modal.Body className="bg-dark text-light border-light">

            <div className="mt-4" />
            <Row>
                {presetObject.flex_income_requirements
                  .map((item, index) => ({ ...item, originalIndex: index })) // Add the original index to each item
                  .sort((a, b) => a.name.localeCompare(b.name)) // Sort by 'name'
                  .map((item) => (
                    <Col key={item.originalIndex} md={3} className="mb-4">
                      <Card bg="dark" text="white" className="border-light">
                        <Card.Body>
                          <Card.Title className="h6">{item.name}</Card.Title>
                          <hr className="hr-line" />
                          <Card.Text className="small tight-spacing">Start year (age): {item.year}</Card.Text>     
                          <Card.Text className="small tight-spacing">End year (age): {item.yearend}</Card.Text>                                     
                          <Card.Text className="small tight-spacing">Annual amount: {currencyIcon}{item.amount}</Card.Text>
                          <Card.Text className="small tight-spacing">Indexed: {item.index === '1' ? 'Yes' : 'No'}</Card.Text>
                          <Card.Text className="small tight-spacing">Inc. over index (%): {item.increase}</Card.Text>
                          <Card.Text className="small tight-spacing">Last Update: {new Date(item.last_update_date).toLocaleDateString('en-US', { year: 'numeric', month: 'long' })}</Card.Text>
                          <Button variant="danger" onClick={() => handleFlexIncomeDeleteItem(item.originalIndex)}>Delete</Button>
                          <span> </span>
                          <Button variant="info" onClick={() => handleFlexIncomeModityItem(item.originalIndex)}>Modify</Button>   
                        </Card.Body>
                      </Card>
                    </Col>
                  ))}
              </Row>

              </Modal.Body>
              <Modal.Footer className="bg-dark text-light border-light d-flex justify-content-between">
                <Button className="btn btn-success" onClick={openBonusModal}>Add Bonus Expenditure</Button>
                <Button variant="secondary" onClick={closeAddFlexIncomeRequirementsModal}>Close</Button>
              </Modal.Footer>
            </Modal>

            <Modal show={isBonusModalOpen} onHide={modifyingEntry ? handleFlexIncomeChange : closeBonusModal}>
            <Modal.Header closeButton>
            <Modal.Title>Bonus Expenditure</Modal.Title>
            </Modal.Header>
            <Modal.Body>

            <Form.Group>
            <OverlayTrigger placement={'right'} overlay={<Tooltip id={`tooltip-right`}>For own reference</Tooltip>}>           
            <Form.Label htmlFor="flexIncomeName" className="small">Description:</Form.Label>
            </OverlayTrigger>
                <Form.Control name="flexIncomeName" id="flexIncomeName" className="form-control border-secondary" type="text" value={flexIncomeName} onChange={(e) => setFlexIncomeName(e.target.value)} placeholder="Name" />
              {errors.flexIncomeName && <Alert className="alert alert-danger">{errors.flexIncomeName}</Alert>}
              </Form.Group>
              <Form.Group>
              <OverlayTrigger placement={'right'} overlay={<Tooltip id={`tooltip-right`}>Age when 'bonus' expenditure will start to be made (e.g. at retirement age, 65)</Tooltip>}>           
              <Form.Label htmlFor="flexIncomeYear" className="small">Start age:</Form.Label>
              </OverlayTrigger>
                <Form.Control name="flexIncomeYear" id="flexIncomeYear" className="form-control border-secondary" type="number" value={flexIncomeYear} onChange={(e) => { if (/^\d*$/.test(e.target.value)) {setFlexIncomeYear(e.target.value)}}} placeholder="AgeStart" min="1" />
              {errors.flexIncomeYear && <Alert className="alert alert-danger">{errors.flexIncomeYear}</Alert>}
              </Form.Group>
              <Form.Group>
              <OverlayTrigger placement={'right'} overlay={<Tooltip id={`tooltip-right`}>Age when 'bonus' expenditure will end (e.g. at end the simulation, 100)</Tooltip>}>           
              <Form.Label htmlFor="flexIncomeYearEnd" className="small">End age:</Form.Label>
              </OverlayTrigger>
                <Form.Control name="flexIncomeYearEnd" id="flexIncomeYearEnd" className="form-control border-secondary" type="number" value={flexIncomeYearEnd} onChange={(e) => { if (/^\d*$/.test(e.target.value)) {setFlexIncomeYearEnd(e.target.value)}}} placeholder="AgeEnd" min="1" />
              {errors.flexIncomeYearEnd && <Alert className="alert alert-danger">{errors.flexIncomeYearEnd}</Alert>}
              </Form.Group>
              <Form.Group>
              <OverlayTrigger placement={'right'} overlay={<Tooltip id={`tooltip-right`}>'Bonus' expenditure amount in today's money</Tooltip>}>           
              <Form.Label htmlFor="flexIncomeAmount" className="small">Amount (current):</Form.Label>
              </OverlayTrigger>
                <Form.Control name="flexIncomeAmount" id="flexIncomeAmount" className="form-control border-secondary" type="number" value={flexIncomeAmount} onChange={(e) => setFlexIncomeAmount(e.target.value)} placeholder="Amount" min="1" />
                {errors.flexIncomeAmount && <Alert className="alert alert-danger">{errors.flexIncomeAmount}</Alert>}
                </Form.Group>
                <Form.Group>
                <OverlayTrigger placement={'right'} overlay={<Tooltip id={`tooltip-right`}>Select whether the 'bonus' expenditure amount is expected to index with inflation each year</Tooltip>}>           
                <Form.Label htmlFor="flexIncomeIndex" className="small">Index (Y/N):</Form.Label>
                </OverlayTrigger>
                <Form.Select name="flexIncomeIndex" id="flexIncomeIndex" className="form-control border-secondary" value={flexIncomeIndex} onChange={(e) => setFlexIncomeIndex(e.target.value)} defaultValue="1">
                  <option value="1">Indexed</option>
                  <option value="0">Not Indexed</option>
                </Form.Select>
                </Form.Group>
                <Form.Group>
                <OverlayTrigger placement={'right'} overlay={<Tooltip id={`tooltip-right`}>Enter expected increase in 'bonus' expenditure amount each year (over inflation if you selected 'Indexed' in the previous question or in absolute terms if you selected 'Not Indexed')</Tooltip>}>           
                <Form.Label htmlFor="flexIncomeIncrease" className="small">Inc. over index (%):</Form.Label>
                </OverlayTrigger>
                <Form.Control name="flexIncomeIncrease" id="flexIncomeIncrease" className="form-control border-secondary" type="number" value={flexIncomeIncrease} onChange={(e) => setFlexIncomeIncrease(e.target.value)} placeholder="%" />
                {errors.flexIncomeIncrease && <Alert className="alert alert-danger">{errors.flexIncomeIncrease}</Alert>}
                </Form.Group>
                <Form.Group>
                <OverlayTrigger placement={'right'} overlay={<Tooltip id={`tooltip-right`}>For own reference when updating plan</Tooltip>}>           
                <Form.Label htmlFor="flexIncomeDate" className="small">Reference date item last updated:</Form.Label>
                </OverlayTrigger>
                <Form.Control
                  name="flexIncomeDate"
                  id="flexIncomeDate"
                  className="form-control border-secondary"
                  type="month"
                  value={flexIncomeDate.slice(0, 7)} // Display only yyyy-mm in the input
                  onChange={(e) => {
                    const selectedMonthYear = e.target.value; // yyyy-mm from input
                    const formattedDate = `${selectedMonthYear}-01`; // Set to the first of the month
                    setFlexIncomeDate(formattedDate); // Update state with full yyyy-mm-dd format
                  }}
                  placeholder="yyyy-mm"
                />    
                {errors.flexIncomeDate && <Alert className="alert alert-danger">{errors.flexIncomeDate}</Alert>}
                </Form.Group>

                </Modal.Body>
                <Modal.Footer>
                <Button variant="success" onClick={handleFlexIncomeChange }>Save</Button>
                </Modal.Footer>
                </Modal>

            <hr className="my-2" />           
            <div className="d-flex justify-content-between align-items-start">
            <h6>Portfolio asset mix:</h6>
            <OverlayTrigger
              placement="right"
              overlay={<Tooltip id="tooltip-right">The asset mix for duration of the simulation; the simulation calculator automatically rebalances the asset mix each year. The asset mix can be changed during the plan (e.g. to simulate a lifecycle fund) via the <em>Change asset mix during plan</em> button.</Tooltip>}>
            <span style={{ cursor: 'pointer', display: 'inline-block', verticalAlign: 'top' }}><FaInfoCircle style={{ color: '#adb5bd', fontSize: '1.0rem' }} /></span>
            </OverlayTrigger>
            </div>

            <Form.Group as={Col}>
            <OverlayTrigger placement={'right'} overlay={<Tooltip id={`tooltip-right`}>'Use asset mix in asset portfolio' calculates an overall portfolio asset allocation based on the size of and allocations entered for each individual asset in the portfolio.<br/>'Enter asset mix for simulation' overrides any asset specific allocations entered in the portfolio.</Tooltip>}>           
            <Form.Label htmlFor="asset_mix_source" className="fw-light small">Asset mix source:</Form.Label>
            </OverlayTrigger>
            <Form.Select name="asset_mix_source" id="asset_mix_source" className="form-control bg-secondary border-secondary text-light" onChange={(e) => handleSelectChange('asset_mix_source', e.target.value)} value={presetObject.asset_mix_source}>
              <option value="0" >Use asset mix in asset portfolio</option>
              <option value="1" >Enter asset mix for simulation</option>
            </Form.Select>
            </Form.Group>

            {presetObject.asset_mix_source === "0" ? <></> : 
            <>
            <div className="my-2" />
            {(presetObject.data_direction === 'forward') ? (
              <div>
            <Row className="d-flex align-items-end">
            <Form.Group as={Col}>
            <FormTemplate formlabel="Equity allocation (of 100%):" formname="asset_mix_equity" type="number" presetObject={presetObject} handleObjectChange={handleObjectChange} errors={errors} overlaytext="Enter portfolio allocation to equities (e.g. for 60%, enter 60)"/>            
            </Form.Group>
            <Form.Group as={Col}>
            <FormTemplate formlabel="Bond allocation (conventional) (of 100%):" formname="asset_mix_bond" type="number" presetObject={presetObject} handleObjectChange={handleObjectChange} errors={errors} overlaytext="Enter portfolio allocation to index linked bonds (e.g. for 20%, enter 20)"/>
            </Form.Group>
            <Form.Group as={Col}>
            <FormTemplate formlabel="Bond allocation (index) (of 100%):" formname="asset_mix_index_bond" type="number" presetObject={presetObject} handleObjectChange={handleObjectChange} errors={errors} overlaytext="Enter portfolio allocation to conventional bonds (e.g. for 20%, enter 20)"/>              
            </Form.Group>
            </Row> 
            </div>
            ) : (
              <div>
              <Row>
              <Form.Group as={Col}>
              <FormTemplate formlabel="Equity allocation (of 100%):" formname="asset_mix_equity" type="number" presetObject={presetObject} handleObjectChange={handleObjectChange} errors={errors} overlaytext="Enter portfolio allocation to equities (e.g. for 60%, enter 60)"/>
              </Form.Group>
              <Form.Group as={Col}>
              <FormTemplate formlabel="Bond allocation (of 100%):" formname="asset_mix_bond" type="number" presetObject={presetObject} handleObjectChange={handleObjectChange} errors={errors} overlaytext="Enter portfolio allocation to bonds (e.g. for 40%, enter 40)"/>
              </Form.Group>
              </Row>
              </div>
            )
            }
            </>}

            <div className="d-grid gap-2">
              <Button variant="secondary" size="" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '8px' }} onClick={openAssetMixChangeModal}>Change asset mix during plan
              <span className="badge-space"><Badge bg="info">{presetObject.asset_mix_changes.length ? presetObject.asset_mix_changes.length : null}</Badge></span>
              </Button>
            </div>

            {/* Asset Mix Changes Modal */}
            <Modal show={isAssetMixChangeModalOpen} onHide={closeAssetMixChangeModal} size="xl" className="border-light">
              <Modal.Header closeButton className="bg-dark text-light border-light">
                <Modal.Title>Change Asset Mix During Plan</Modal.Title>
              </Modal.Header>
              <Modal.Body className="bg-dark text-light border-light">

              <div className="mt-4" />

                <Row>
                  {presetObject.asset_mix_changes
                    .map((item, index) => ({ ...item, originalIndex: index })) // Add the original index to each item
                    .sort((a, b) => a.age - b.age) // Sort by 'age' instead of 'name'
                    .map((item) => (
                      <Col key={item.originalIndex} md={3} className="mb-4">
                        <Card bg="dark" text="white" className="border-light">
                          <Card.Body>
                            <Card.Text className="small tight-spacing">From age {item.age}, asset mix set to:</Card.Text>
                            <Card.Text className="small tight-spacing">Equity (%): {item.equity}</Card.Text>
                            {presetObject.data_direction === 'forward' ? 
                            <>
                            <Card.Text className="small tight-spacing">Conventional Bonds (%): {item.bonds}</Card.Text>
                            <Card.Text className="small tight-spacing">Index Bonds (%): {item.index}</Card.Text>
                            </>
                            : 
                            <>
                            <Card.Text className="small tight-spacing">
                              Bonds (%): {parseFloat(item.bonds) + parseFloat(item.index)}
                            </Card.Text>
                            </>
                            }
                            <Button variant="danger" onClick={() => handleAssetMixChangeDeleteItem(item.originalIndex)}>Delete</Button>
                            <span> </span>
                            <Button variant="info" onClick={() => handleAssetMixChangeModityItem(item.originalIndex)}>Modify</Button>                      
                          </Card.Body>
                        </Card>
                      </Col>
                    ))}
                </Row>

              </Modal.Body>
              <Modal.Footer className="bg-dark text-light border-light d-flex justify-content-between">
                <div>
                <Button className="btn btn-success" onClick={openAssetMixChangeEntryModal} style={{ marginRight: '4px' }}>Add Asset Mix Change</Button>
                </div>
                <div>
                <Button variant="secondary" onClick={closeAssetMixChangeModal}>Close</Button>
                </div>
              </Modal.Footer>
            </Modal>


            <Modal show={isAssetMixChangeEntryModalOpen} onHide={modifyingEntry ? handleAssetMixChangeSaveItem : closeAssetMixChangeEntryModal}>
            <Modal.Header closeButton>
            <Modal.Title>Asset Mix Change Entry</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <Form.Group>
            <OverlayTrigger placement={'right'} overlay={<Tooltip id={`tooltip-right`}>Age in plan when new asset mix takes effect</Tooltip>}>           
            <Form.Label htmlFor="assetMixChangeAge" className="small">Age from which change takes effect:</Form.Label>
            </OverlayTrigger>
            <Form.Control name="assetMixChangeAge" id="assetMixChangeAge" className="form-control border-secondary" type="number" value={assetMixChangeAge} onChange={(e) => setAssetMixChangeAge(e.target.value)} placeholder="Age" />
            {errors.assetMixChangeAge && <Alert className="alert alert-danger">{errors.assetMixChangeAge}</Alert>}
            </Form.Group>
            <Form.Group>
            <OverlayTrigger placement={'right'} overlay={<Tooltip id={`tooltip-right`}>Equity %</Tooltip>}>           
            <Form.Label htmlFor="assetMixChangeEquity" className="small">Equity (%):</Form.Label>
            </OverlayTrigger>
            <Form.Control name="assetMixChangeEquity" id="assetMixChangeEquity" className="form-control border-secondary" type="number" value={assetMixChangeEquity} onChange={(e) => setAssetMixChangeEquity(e.target.value)} placeholder="%" />
            {errors.assetMixChangeEquity && <Alert className="alert alert-danger">{errors.assetMixChangeEquity}</Alert>}
            </Form.Group>

            {presetObject.data_direction === 'forward' ? <>
            <Form.Group>
            <OverlayTrigger placement={'right'} overlay={<Tooltip id={`tooltip-right`}>Conventional Bonds %</Tooltip>}>           
            <Form.Label htmlFor="assetMixChangeBonds" className="small">Conventional Bonds (%):</Form.Label>
            </OverlayTrigger>
            <Form.Control name="assetMixChangeBonds" id="assetMixChangeBonds" className="form-control border-secondary" type="number" value={assetMixChangeBonds} onChange={(e) => setAssetMixChangeBonds(e.target.value)} placeholder="%" />
            {errors.assetMixChangeBonds && <Alert className="alert alert-danger">{errors.assetMixChangeBonds}</Alert>}
            </Form.Group>     

            <Form.Group>
            <OverlayTrigger placement={'right'} overlay={<Tooltip id={`tooltip-right`}>Index Bonds %</Tooltip>}>           
            <Form.Label htmlFor="assetMixChangeIndex" className="small">Index Bonds (%):</Form.Label>
            </OverlayTrigger>
            <Form.Control 
              name="assetMixChangeIndex" 
              id="assetMixChangeIndex" 
              className="form-control border-secondary" 
              type="number" 
              value={assetMixChangeIndex} 
              onChange={(e) =>setAssetMixChangeIndex(e.target.value)} 
              placeholder="%" 
            />
            {errors.assetMixChangeIndex && <Alert className="alert alert-danger">{errors.assetMixChangeIndex}</Alert>}
            </Form.Group>
            </> : <>
            <Form.Group>
            <OverlayTrigger placement={'right'} overlay={<Tooltip id={`tooltip-right`}>Bonds %</Tooltip>}>           
            <Form.Label htmlFor="assetMixChangeBonds" className="small">Bonds (%):</Form.Label>
            </OverlayTrigger>
            <Form.Control name="assetMixChangeBonds" 
              id="assetMixChangeBonds" 
              className="form-control border-secondary" 
              type="number" 
              value={parseFloat(assetMixChangeBonds) + parseFloat(assetMixChangeIndex)} 
              onChange={(e) => {setAssetMixChangeBonds(e.target.value); setAssetMixChangeIndex('0');}} 
              placeholder="%" 
            />
            {errors.assetMixChangeBonds && <Alert className="alert alert-danger">{errors.assetMixChangeBonds}</Alert>}
            </Form.Group>            
            </>}

            </Modal.Body>
            <Modal.Footer>
              <div className="ms-auto">
                <Button variant="success" onClick={handleAssetMixChangeSaveItem}>Save</Button>
              </div>
            </Modal.Footer>
            </Modal>            

            <hr className="my-2" />           

            <div className="d-flex justify-content-between align-items-start">
            <h6>Optional annuitisation:</h6>
            <OverlayTrigger
              placement="right"
              overlay={<Tooltip id="tooltip-right">Option to simulate purchasing, at a future point in the simulation, a lifetime annuity to cover all or part of planned expenditure not covered by other pensions.</Tooltip>}>
            <span style={{ cursor: 'pointer', display: 'inline-block', verticalAlign: 'top' }}><FaInfoCircle style={{ color: '#adb5bd', fontSize: '1.0rem' }} /></span>
            </OverlayTrigger>
            </div>

            <div className="d-grid gap-2">
              <Button variant="secondary" size="" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} onClick={openAddAnnuityModal}>Purchase annuity into portfolio
              <span className="badge-space"><Badge bg="info">{presetObject.annuity_pension.length ? presetObject.annuity_pension.length : null}</Badge></span>
              </Button>
            </div>


            {/* Annuity Modal */}
            <Modal show={isAddAnnuityModalOpen} onHide={closeAddAnnuityModal} size="xl" className="border-light">
              <Modal.Header closeButton className="bg-dark text-light border-light">
                <Modal.Title>Purchase Annuity Into Portfolio</Modal.Title>
              </Modal.Header>
              <Modal.Body className="bg-dark text-light border-light">

              <div className="mt-4" />
              <Row>
                {presetObject.annuity_pension
                  .map((item, index) => ({ ...item, originalIndex: index })) // Add the original index to each item
                  .sort((a, b) => a.name.localeCompare(b.name)) // Sort by 'name'
                  .map((item) => (
                    <Col key={item.originalIndex} md={3} className="mb-4">
                      <Card bg="dark" text="white" className="border-light">
                        <Card.Body>
                          <Card.Title className="h6">{item.name}</Card.Title>
                          <hr className="hr-line" />
                          <Card.Text className="small tight-spacing">Start year (age): {item.year}</Card.Text>
                          <Card.Text className="small tight-spacing">Annual amount: {currencyIcon}{item.amount}</Card.Text>
                          <Card.Text className="small tight-spacing">Price (% notional): {item.price}</Card.Text>
                          <Card.Text className="small tight-spacing">Timing: {item.deferred === '0' ? 'Immediate' : 'Deferred'}</Card.Text>
                          <Card.Text className="small tight-spacing">Indexed: {item.index === '1' ? 'Yes' : 'No'}</Card.Text>
                          <Card.Text className="small tight-spacing">Inc. over index (%): {item.increase}</Card.Text>
                          <Card.Text className="small tight-spacing">Last Update: {new Date(item.last_update_date).toLocaleDateString('en-US', { year: 'numeric', month: 'long' })}</Card.Text>
                          <Button variant="danger" onClick={() => handleAnnuityPensionDeleteItem(item.originalIndex)}>Delete</Button>
                          <span> </span>
                          <Button variant="info" onClick={() => handleAnnuityPensionModityItem(item.originalIndex)}>Modify</Button>                      
                        </Card.Body>
                      </Card>
                    </Col>
                  ))}
                </Row>

              </Modal.Body>
              <Modal.Footer className="bg-dark text-light border-light d-flex justify-content-between">
                <div>
                <Button className="btn btn-success" onClick={openAnnuityModal} style={{ marginRight: '4px' }}>Add Annuity</Button>
                <Button variant="success" onClick={openAnnuityPriceModal}>Open Indicative Annuity Price Table</Button>
                </div>
                <div>
                <Button variant="secondary" onClick={closeAddAnnuityModal}>Close</Button>
                </div>
              </Modal.Footer>
            </Modal>

            <Modal show={isAnnuityModalOpen} onHide={modifyingEntry ? handleAnnuityPensionChange : closeAnnuityModal}>
            <Modal.Header closeButton>
            <Modal.Title>Annuity</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <Form.Group>
            <OverlayTrigger placement={'right'} overlay={<Tooltip id={`tooltip-right`}>For own reference</Tooltip>}>           
            <Form.Label htmlFor="annuityName" className="small">Description:</Form.Label>
            </OverlayTrigger>
                <Form.Control name="annuityName" id="annuityName" className="form-control border-secondary" type="text" value={annuityName} onChange={(e) => setAnnuityName(e.target.value)} placeholder="Name" />
              {errors.annuityName && <Alert className="alert alert-danger">{errors.annuityName}</Alert>}
              </Form.Group>

            <Form.Group>
            <OverlayTrigger placement={'right'} overlay={<Tooltip id={`tooltip-right`}>Age when annuity will be bought and income will start to be received</Tooltip>}>           
            <Form.Label htmlFor="annuityYear" className="small">Start year (age):</Form.Label>
            </OverlayTrigger>
                <Form.Control name="annuityYear" id="annuityYear" className="form-control border-secondary" type="number" value={annuityYear} onChange={(e) => { if (/^\d*$/.test(e.target.value)) {setAnnuityYear(e.target.value)}}} placeholder="Year" min="1" />
              {errors.annuityYear && <Alert className="alert alert-danger">{errors.annuityYear}</Alert>}
              </Form.Group>
              <Form.Group>
                <OverlayTrigger placement={'right'} overlay={<Tooltip id={`tooltip-right`}>Annuity annual income in today's money</Tooltip>}>           
                <Form.Label htmlFor="annuityAmount" className="small">Annual income (from purchase age):</Form.Label>
                </OverlayTrigger>
                <Form.Control name="annuityAmount" id="annuityAmount" className="form-control border-secondary" type="number" value={annuityAmount} onChange={(e) => setAnnuityAmount(e.target.value)} placeholder="Income" min="1" />
                {errors.annuityAmount && <Alert className="alert alert-danger">{errors.annuityAmount}</Alert>}
                </Form.Group>
                <Form.Group>
                <OverlayTrigger placement={'right'} overlay={<Tooltip id={`tooltip-right`}>Enter (year 1) annuity income as percentage notional purchase cost (e.g. if the annuity will provide an income of 10,000 and it costs 100,000, then enter 10)</Tooltip>}>           
                <Form.Label htmlFor="annuityPrice" className="small">Price (income as % notional cost):</Form.Label>
                </OverlayTrigger>
                <Form.Control name="annuityPrice" id="annuityPrice" className="form-control border-secondary" type="number" value={annuityPrice} onChange={(e) => setAnnuityPrice(e.target.value)} placeholder="Price" />
                {errors.annuityPrice && <Alert className="alert alert-danger">{errors.annuityPrice}</Alert>}
                </Form.Group>
                <Form.Group>
                <OverlayTrigger placement={'right'} overlay={<Tooltip id={`tooltip-right`}>Select whether annuity is immediate (purchased in year given followed by immediate payout) or deferred (purchased at the beginning of the plan with payout beginning in year given) </Tooltip>}>           
                <Form.Label htmlFor="annuityDeferred" className="small">Immediate / deferred (select):</Form.Label>
                </OverlayTrigger>
                <Form.Select name="annuityDeferred" id="annuityDeferred" className="form-control border-secondary" value={annuityDeferred} onChange={(e) => setAnnuityDeferred(e.target.value)} defaultValue="0">
                  <option value="1">Deferred</option>
                  <option value="0">Immediate</option>
                </Form.Select>
                </Form.Group>
                <Form.Group>
                <OverlayTrigger placement={'right'} overlay={<Tooltip id={`tooltip-right`}>Select whether the value of the payment is indexed to inflation each year</Tooltip>}>           
                <Form.Label htmlFor="annuityIndex" className="small">Annual inc. (select):</Form.Label>
                </OverlayTrigger>
                <Form.Select name="annuityIndex" id="annuityIndex" className="form-control border-secondary" value={annuityIndex} onChange={(e) => setAnnuityIndex(e.target.value)} defaultValue="1">
                  <option value="1">Indexed</option>
                  <option value="0">Not Indexed</option>
                </Form.Select>
                </Form.Group>
                <Form.Group>
                <OverlayTrigger placement={'right'} overlay={<Tooltip id={`tooltip-right`}>Enter expected increase in value of the payment each year (over inflation if you selected 'Indexed' in the previous question or in absolute terms if you selected 'Not Indexed')</Tooltip>}>           
                <Form.Label htmlFor="annuityIncrease" className="small">Inc. over index (%):</Form.Label>
                </OverlayTrigger>
                <Form.Control name="annuityIndex" id="annuityIndex" className="form-control border-secondary" type="number" value={annuityIncrease} onChange={(e) => setAnnuityIncrease(e.target.value)} placeholder="%" />
                {errors.annuityIncrease && <Alert className="alert alert-danger">{errors.annuityIncrease}</Alert>}
                </Form.Group>
                <Form.Group>
                <OverlayTrigger placement={'right'} overlay={<Tooltip id={`tooltip-right`}>For own reference when updating plan</Tooltip>}>           
                <Form.Label htmlFor="annuityDate" className="small">Reference date item last updated:</Form.Label>
                </OverlayTrigger>
                <Form.Control
                  name="annuityDate"
                  id="annuityDate"
                  className="form-control border-secondary"
                  type="month"
                  value={annuityDate.slice(0, 7)} // Display only yyyy-mm in the input
                  onChange={(e) => {
                    const selectedMonthYear = e.target.value; // yyyy-mm from input
                    const formattedDate = `${selectedMonthYear}-01`; // Set to the first of the month
                    setAnnuityDate(formattedDate); // Update state with full yyyy-mm-dd format
                  }}
                  placeholder="yyyy-mm"
                />
                {errors.annuityDate && <Alert className="alert alert-danger">{errors.annuityDate}</Alert>}
                </Form.Group>

                </Modal.Body>
                <Modal.Footer>
                  <div className="me-auto">
                    <Button variant="success" onClick={openAnnuityPriceModal}>Open Indicative Annuity Price Table</Button>
                  </div>
                  <div className="ms-auto">
                    <Button variant="success" onClick={handleAnnuityPensionChange}>Save</Button>
                  </div>
                </Modal.Footer>
                </Modal>

            <hr className="my-2" />           

            <div className="d-flex justify-content-between align-items-start">
            <h6>Taxes, fees and data settings:</h6>
            <OverlayTrigger
              placement="right"
              overlay={<Tooltip id="tooltip-right">- Choose whether to use USD (for US investors) or GBP (for UK investors) denominated dataset. <br />- Set taxes that may be deducted from returns for different fund types. Simulation shows results net of tax.<br />- Set portfolio / management fees that may be deducted from returns.</Tooltip>}>
            <span style={{ cursor: 'pointer', display: 'inline-block', verticalAlign: 'top' }}><FaInfoCircle style={{ color: '#adb5bd', fontSize: '1.0rem' }} /></span>
            </OverlayTrigger>
            </div>


            <div className="d-grid gap-2">
              <Button variant="secondary" size="" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} onClick={openDataChoiceModal}>Simulation data options
              </Button>
            </div>

            <div className="my-2" />

            <div className="d-grid gap-2">
              <Button variant="secondary" size="" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} onClick={openTaxesModal}>Set taxes
              </Button>
            </div>
            <div className="my-2" />
            <div className="d-grid gap-2">
              <Button variant="secondary" size="" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} onClick={openSettingsModal}>Fees and other simulation settings
              </Button>
            </div>
            <div className="my-2" />
 
            {/* Taxes Modal */}
            <Modal show={isTaxesModalOpen} onHide={closeTaxesModal} className="border-light">
              {/* <Modal.Header closeButton className="bg-dark text-light border-light"> */}
              <Modal.Header closeButton className="border-light">
                <Modal.Title>Tax settings</Modal.Title>
              </Modal.Header>
              {/* <Modal.Body className="bg-dark text-light border-light custom-scrollbar-modal" style={{ maxHeight: '70vh', overflowY: 'auto' }}> */}
              <Modal.Body style={{ maxHeight: '70vh', overflowY: 'auto' }}>
                
              <FormTemplateLight formlabel="Tax on general account equity returns (0-100%):" formname="equity_tax" type="number" overlaytext="Reduces total returns on equity portion of general account portfolio in simulation to cover dividend and capital gains taxes." presetObject={presetObject} handleObjectChange={handleObjectChange} errors={errors} />       
            <FormTemplateLight formlabel="Tax on general account bond returns (0-100%):" formname="bond_tax" type="number" overlaytext="Reduces total returns on bond portion of general account portfolio in simulation to cover dividend and capital gains taxes." presetObject={presetObject} handleObjectChange={handleObjectChange} errors={errors} />            
            <OverlayTrigger placement={'right'} overlay={<Tooltip id={`tooltip-right`}>Select to apply bond tax to inflation adjustment component of return of index linked bonds</Tooltip>}>           
            <Form.Label htmlFor="apply_tax_to_inflation" className="fw-light small">Index linked bond tax treatment:</Form.Label>
            </OverlayTrigger>
            <Form.Select name="apply_tax_to_inflation" id="apply_tax_to_inflation" className="form-control border-secondary" onChange={(e) => handleObjectChange('apply_tax_to_inflation', e.target.value)} value={presetObject.apply_tax_to_inflation}>
              <option value="0" >No tax applied to inflation adjustment</option>                
              <option value="1" >Tax applied to inflation adjustment</option>
		        </Form.Select>
            {errors.apply_tax_to_inflation && <span className="alert alert-danger">{errors.apply_tax_to_inflation}</span>}

            <FormTemplateLight formlabel="Tax on tax deferred withdrawals (0-100%):" formname="draw_tax" type="number" overlaytext="Enter income tax rate that will be charged on withdrawals from tax deferred portion of portfolio. Gross deduction from portfolio in simulation is scaled up to cover these taxes so that net income equals income requirement." presetObject={presetObject} handleObjectChange={handleObjectChange} errors={errors} />
            <FormTemplateLight formlabel="Tax on annuity interest rate return component (0-100%):" formname="annuity_tax_rate" type="number" overlaytext="Enter income tax rate that will be charged on annuity income for the interest rate return component of that income. Simulation also captures the income tax that will be charged on the non-interest rate return component of annuity income in proportion to the amount of the portfolio that is in tax deferred funds. Annuity purchase cost in simulation is scaled up to cover these taxes." presetObject={presetObject} handleObjectChange={handleObjectChange} errors={errors} /> 
            <FormTemplateLight formlabel="Tax on state pension income (0-100%):" formname="annuity_tax_rate2" type="number" overlaytext="Enter income tax rate that will be deducted on state pension income." presetObject={presetObject} handleObjectChange={handleObjectChange} errors={errors} />    
            <FormTemplateLight formlabel="Tax on occupational pension income (0-100%):" formname="annuity_tax_rate3" type="number" overlaytext="Enter income tax rate that will be deducted on occupational pension income." presetObject={presetObject} handleObjectChange={handleObjectChange} errors={errors} />

              </Modal.Body>
              <Modal.Footer >
                <Button variant="secondary" onClick={closeTaxesModal}>Close</Button>
              </Modal.Footer>
            </Modal>

            {/* Settings Modal */}
            <Modal show={isSettingsModalOpen} onHide={closeSettingsModal} className="border-light">
              {/* <Modal.Header closeButton className="bg-dark text-light border-light"> */}
              <Modal.Header closeButton className="border-light">
                <Modal.Title>Fee and other simulation settings</Modal.Title>
              </Modal.Header>
              {/* <Modal.Body className="bg-dark text-light border-light custom-scrollbar-modal" style={{ maxHeight: '70vh', overflowY: 'auto' }}> */}
              <Modal.Body style={{ maxHeight: '70vh', overflowY: 'auto' }}>
                
            <FormTemplateLight formlabel="Average portfolio fees (basis points):" formname="fees" type="number" overlaytext="Deducts annual charge from portfolio value in simulation - fees reduce returns" presetObject={presetObject} handleObjectChange={handleObjectChange} errors={errors} />
            <FormTemplateLight formlabel="Add net credit spread return to forward looking conventional bond yield (basis points):" formname="spread" type="number" overlaytext="Add net credit spread return to forward looking conventional bond yield (basis points) to simulate holding credit/corporate bonds rather than government bonds" presetObject={presetObject} handleObjectChange={handleObjectChange} errors={errors} />
            <FormTemplateLight formlabel="Add alpha spread return to all equity returns (basis points):" formname="equity_alpha" type="number" overlaytext="Add alpha spread return to equity returns" presetObject={presetObject} handleObjectChange={handleObjectChange} errors={errors} />
            <FormTemplateLight formlabel="Add alpha spread return to all bond returns (basis points):" formname="fixed_income_alpha" type="number" overlaytext="Add alpha spread return to bond returns" presetObject={presetObject} handleObjectChange={handleObjectChange} errors={errors} />
            
            <Form.Group>
                <OverlayTrigger placement={'right'} overlay={<Tooltip id={`tooltip-right`}>Change date for own reference</Tooltip>}>           
                <Form.Label htmlFor="plan_date" className="fw-light small">Plan 'last update' date:</Form.Label>
                </OverlayTrigger>
                <Form.Control
                  name="plan_date"
                  id="plan_date"
                  className="form-control border-secondary"
                  type="month"
                  value={presetObject.plan_date.slice(0, 7)} // Display only yyyy-mm in the input
                  onChange={(e) => {
                    const selectedMonthYear = e.target.value; // yyyy-mm from input
                    const formattedDate = `${selectedMonthYear}-01`; // Set to the first of the month
                    handleDateChange('plan_date', formattedDate)
                  }}
                  placeholder="yyyy-mm"
                />
                {errors.plan_date && <Alert className="alert alert-danger">{errors.plan_date}</Alert>}
            </Form.Group>


            <OverlayTrigger placement={'right'} overlay={<Tooltip id={`tooltip-right`}>Linear simulation uses back-testing cycles from the first year of the historic data set up until the last completable cycle in the data set (e.g. if dataset is from 1900-2023 and the simulation is 30 years, the model will use 94 backtesting cycles with the first from 1900-1930 and the last from 1993-2023). Circular simulation starts a back-testing cycle in every year of the historic data set - if there are insufficent years to finish the cycle, it uses again the years at the beginning of the data set (e.g. if dataset is from 1900-2023 and the simulation is 30 years, the model will use 124 backtesting cycles with the first from 1900-1930, the 95th using 1994-2023 plus 1900 (to make 30 years) and the last from using 2023 plus 1900-1929 (again, to make 30 years)). </Tooltip>}>           
            <Form.Label htmlFor="circular_simulation" className="fw-light small">Simulation methodology:</Form.Label>
            </OverlayTrigger>
            <Form.Select name="circular_simulation" id="circular_simulation" className="form-control border-secondary" onChange={(e) => handleObjectChange('circular_simulation', e.target.value)} value={presetObject.circular_simulation}>
              <option value="1" >Circular exploratory simulation</option>
              <option value="0" >Linear exploratory simulation</option>
		        </Form.Select>
            {errors.circular_simulation && <span className="alert alert-danger">{errors.circular_simulation}</span>}           
            <OverlayTrigger placement={'right'} overlay={<Tooltip id={`tooltip-right`}>First year (end of) of historic dataset for backtesting </Tooltip>}>           
            <Form.Label htmlFor="data_start_year" className="fw-light small">First year (end of) of historic dataset for backtesting:</Form.Label>
            </OverlayTrigger>
            <Form.Select type="number" name="data_start_year" id="data_start_year" value={presetObject.data_start_year} onChange={(e) => handleObjectChange('data_start_year', e.target.value)} className="form-control border-secondary">
              {yearList.map((number, index) => (<option key={index} value={number}>{number}</option>))}
            </Form.Select>
            {errors.data_start_year && <span className="alert alert-danger">{errors.data_start_year}</span>}

            <OverlayTrigger placement={'right'} overlay={<Tooltip id={`tooltip-right`}>Last year (end of) of historic dataset for backtesting </Tooltip>}>           
            <Form.Label htmlFor="data_end_year" className="fw-light small">Last year (end of) of historic dataset for backtesting:</Form.Label>
            </OverlayTrigger>
            <Form.Select type="number" name="data_end_year" id="data_end_year" value={presetObject.data_end_year} onChange={(e) => handleObjectChange('data_end_year', e.target.value)} className="form-control border-secondary">
              {yearList.map((number, index) => (<option key={index} value={number}>{number}</option>))}
            </Form.Select>
            {errors.data_end_year && <span className="alert alert-danger">{errors.data_end_year}</span>}

              </Modal.Body>
              <Modal.Footer >
                <Button variant="secondary" onClick={closeSettingsModal}>Close</Button>
              </Modal.Footer>
            </Modal>
            
            {/* Settings Modal */}
            <Modal show={isDataChoiceModalOpen} onHide={closeDataChoiceModal} className="border-light">
              {/* <Modal.Header closeButton className="bg-dark text-light border-light"> */}
              <Modal.Header closeButton className="border-light">
                <Modal.Title>Simulation data options</Modal.Title>
              </Modal.Header>
              {/* <Modal.Body className="bg-dark text-light border-light custom-scrollbar-modal" style={{ maxHeight: '70vh', overflowY: 'auto' }}> */}
              <Modal.Body style={{ maxHeight: '70vh', overflowY: 'auto' }}>
                
              <Form.Group>
              <Form.Label htmlFor="currency_set" className="fw-light small">Select simulation currency denomination:</Form.Label>
              
              <div>
                {/* USD Radio Button */}
                <Form.Check
                  type="radio"
                  className="fw-light small"
                  name="currency_set"
                  id="currency_usd"
                  label="USD - for US based investors. Equity returns are denominated in USD. Bond returns are represented by US Treasury. US inflation is used."
                  value="USD"
                  checked={presetObject.currency_set === 'USD'}
                  onChange={(e) => handleTextChange('currency_set', e.target.value)}
                />
                {/* GBP Radio Button */}
                <Form.Check
                  type="radio"
                  className="fw-light small"
                  name="currency_set"
                  id="currency_gbp"
                  label="GBP - for UK based investors. Equity returns are denominated in GBP. Bond returns are represented by UK Gilts. UK inflation is used."
                  value="GBP"
                  checked={presetObject.currency_set === 'GBP'}
                  onChange={(e) => handleTextChange('currency_set', e.target.value)}
                />
 
              </div>
              </Form.Group>

              <Form.Group>
              <Form.Label htmlFor="currency_set" className="fw-light small">Select equity return data:</Form.Label>
              <div>
                <Form.Check
                  type="radio"
                  className="fw-light small"
                  name="geographic_set"
                  id="geographic_DOMESTIC"
                  label="US Equity Market - largest equity market by far, over time has delivered higher real returns than most other major equity markets but no guarantee that this will continue."
                  value="DOMESTIC"
                  checked={presetObject.geographic_set === 'DOMESTIC'}
                  onChange={(e) => handleTextChange('geographic_set', e.target.value)}
                />              
                <Form.Check
                  type="radio"
                  className="fw-light small"
                  name="geographic_set"
                  id="geographic_GLOBAL"
                  label="Global (inc. US) Equity Market - incorporates a broad range of stress scenarios (global conflict, high inflation and political upheaval) on market returns."
                  value="GLOBAL"
                  checked={presetObject.geographic_set === 'GLOBAL'}
                  onChange={(e) => handleTextChange('geographic_set', e.target.value)}
                />
              </div>
              </Form.Group>


              <Form.Group>
              <Form.Label htmlFor="data_direction" className="fw-light small">Select bond return data:</Form.Label>
              <div>
                <Form.Check
                  type="radio"
                  className="fw-light small"
                  name="data_direction"
                  id="data_direction_back"
                  label="Historic bond returns - suitable for investors using pooled bond funds."
                  value="back"
                  checked={presetObject.data_direction === 'back'}
                  onChange={(e) => handleTextChange('data_direction', e.target.value)}
                />              
                <Form.Check
                  type="radio"
                  className="fw-light small"
                  name="data_direction"
                  id="data_direction_forward"
                  label="Forward market implied bond returns - suitable for investors who have a portfolio of bonds where each bond will be held to maturity and are cash flow matched. Gives investors a better indication of returns that they currently 'lock-in'. Gives option of allocating in simulation to either conventional bonds or index linked bonds."
                  value="forward"
                  checked={presetObject.data_direction === 'forward'}
                  onChange={(e) => handleTextChange('data_direction', e.target.value)}
                />
              </div>
              </Form.Group>

              </Modal.Body>
              <Modal.Footer >
                <Button variant="secondary" onClick={closeDataChoiceModal}>Close</Button>
              </Modal.Footer>
            </Modal>

            <Modal show={isAnnuityPriceModalOpen} onHide={closeAnnuityPriceModal} size='xl'>
              <Modal.Header>
              <Modal.Title>Indicative Annuity Price Table</Modal.Title>
              </Modal.Header>
              <Modal.Body>

              <p>Indicative annuity prices by age and type calculated from forward market interest rate curve ({presetObject.currency_set === 'GBP' ? 'UK' : 'US'}), average life expectancy ({presetObject.currency_set === 'GBP' ? 'UK' : 'US'}) and price scaler. Price is expressed as year one income / purchase price (%). </p>
              <p>Note: theses are indicative only. Check against actual market prices before using in plan.</p>
              <Row className="align-items-end" style={{ marginBottom: '10px' }}> 
                <Col sm={2}>
                  <OverlayTrigger placement={'right'} overlay={<Tooltip id={`tooltip-right`}>Typically 80-95%. Scales theoretical annuity price (based on interest rate curve and life expectancy) to market annuity price (considering costs, capital, profit margin, etc).</Tooltip>}>
                    <Form.Label htmlFor="annuity_price_scale" className="fw-light small mb-0 me-2">
                      Price scaler (%):
                    </Form.Label>
                  </OverlayTrigger>
                  <Form.Control
                    name="annuity_price_scale"
                    id="annuity_price_scale"
                    className="border-secondary"
                    type="number"
                    value={presetObject.annuity_price_scale} 
                    onChange={(e) => handleObjectChange('annuity_price_scale', e.target.value)}
                    placeholder="%"
                  />
                  {errors.annuity_price_scale && <span className="alert alert-danger">{errors.annuity_price_scale}</span>}
                </Col>
                <Col sm={10}>
                  <Button variant="success" onClick={getAnnuityPrices} style={{ marginLeft: '10px' }}>
                    Update Table
                  </Button>
                </Col>
              </Row>
              <br></br>
              <Table striped bordered hover>
                  <thead>
                      <tr>
                          <th></th>
                          <th>55</th>
                          <th>60</th>
                          <th>65</th>
                          <th>70</th>
                          <th>75</th>
                      </tr>
                  </thead>
                  <tbody>

                  <tr>
                      <td>Flat</td>
                      {annuityTableData.flat_results ? (
                          annuityTableData.flat_results.slice(0, 5).map((result, index) => (
                              <td key={index}>{result.toFixed(1)}%</td>
                          ))
                      ) : (
                          <td colSpan={5}>Loading...</td>
                      )}
                  </tr>

                      <tr>
                        <td>3% escalating</td>
                          {annuityTableData.escalator_results ? (
                              annuityTableData.escalator_results.slice(0, 5).map((result, index) => (
                                  <td key={index}>{result.toFixed(1)}%</td>
                              ))
                          ) : (
                              <td colSpan={5}>Loading...</td>
                          )}
                      </tr>

                      <tr>
                        <td>Inflation linked</td>
                          {annuityTableData.index_results ? (
                              annuityTableData.index_results.slice(0, 5).map((result, index) => (
                                  <td key={index}>{result.toFixed(1)}%</td>
                              ))
                          ) : (
                              <td colSpan={5}>Loading...</td>
                          )}
                      </tr>
                      <tr>
                        <td>Flat, deferred to age 85</td>
                          {annuityTableData.deferred_results ? (
                              annuityTableData.deferred_results.slice(0, 5).map((result, index) => (
                                  <td key={index}>{result.toFixed(1)}%</td>
                              ))
                          ) : (
                              <td colSpan={5}>Loading...</td>
                          )}
                      </tr>



                  </tbody>
              </Table>

              </Modal.Body>

              <Modal.Footer>
              <Button variant="secondary" onClick={closeAnnuityPriceModal}>Close</Button>
              </Modal.Footer>
            </Modal>

            <Modal show={isModalOpen} onHide={closeModal}>
            <Modal.Header>
            <Modal.Title>Notice</Modal.Title>
            </Modal.Header>
            <Modal.Body>{modalmessage}</Modal.Body>
            <Modal.Footer>
            <Button variant="secondary" onClick={closeModal}>Close</Button>
            </Modal.Footer>
            </Modal>

            <Modal show={isResetModalOpen} onHide={closeResetModal }>
            <Modal.Header>
            <Modal.Title>Notice</Modal.Title>
            </Modal.Header>
            <Modal.Body>{modalResetMessage}</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={closeResetModal }>Cancel</Button>
              <Button variant="danger" onClick={resetPlan }>Confirm</Button>
            </Modal.Footer>
            </Modal>

            <Modal show={isRetrieveModalOpen} onHide={closeRetrieveModal} centered size={isSmallScreen ? 'sm' : 'lg'}>
            <Modal.Header closeButton>
            <Modal.Title>User Plans</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            {plans.length > 0 ? (
            <Table hover className="horizontal-border-only">
              <thead>
                <tr>
                  <th style={{ width: '5%' }}>#</th>
                  <th style={{ width: '45%' }}>Plan title</th>
                  <th style={{ width: '25%' }}>Actions</th> 
                  <th style={{ width: '25%' }}>Last update</th> 
                </tr>
              </thead>
              <tbody>
                {plans.map((plan, index) => (
                  <tr key={plan.id}>
                    <td>{index + 1}</td>
                    <td>{plan.plan_title}</td>
                    <td>
                      <Button variant="success" onClick={() => retrievePlan(plan.id)} className="me-1 mb-1 mb-lg-0" style={{ width: '80px' }}>Retrieve</Button>
                      <Button variant="danger" onClick={() => handleOpenDeleteModal(plan.id)}className="me-1" style={{ width: '80px' }}>Delete</Button>
                    </td>
                    <td>{new Date(plan.plan_date).toLocaleDateString('en-US', { year: 'numeric', month: 'long' })}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
            ) : (
            <p>No plans found.</p>
            )}
            </Modal.Body>
            <Modal.Footer>
            <Button variant="secondary" onClick={closeRetrieveModal}>Close</Button>
            </Modal.Footer>
            </Modal>

            <Modal show={showDateModal} onHide={() => handleDateModalClose(false)}>
              <Modal.Header closeButton>
                <Modal.Title>Update Portfolio Date</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p>Do you want to update this plan's 'Last Update' to the current date?</p>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={() => {handleDateModalClose(false)}}>No, keep existing</Button>
                <Button variant="secondary" onClick={() => {handleDateModalClose(true)}}>Yes, update date</Button>
              </Modal.Footer>
            </Modal>

            <Modal show={showDeleteModal} onHide={handleCloseDeleteModal} centered>
            <Modal.Header>
            <Modal.Title>Notice</Modal.Title>
            </Modal.Header>
            <Modal.Body><p>Are you sure you want to delete this plan? This action cannot be undone.</p></Modal.Body>
            <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseDeleteModal}>Cancel</Button>
            <Button variant="danger" onClick={() => deletePlan(selectedPlanId)}>Confirm</Button>
            </Modal.Footer>
            </Modal>

            <Modal show={isReLoginModalOpen} onHide={closeReLoginModal}>
              <Modal.Header closeButton>
                <Modal.Title>Login</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p>Error connecting to server. Action could not be completed. Please try re-logging in and re-trying action.</p>
                <ReLoginForm onClose={closeReLoginModal} />
              </Modal.Body>
            </Modal>

            <Modal show={isIndexAdjustModalOpen} onHide={closeIndexAdjustModal}>
              <Modal.Header closeButton>
                <Modal.Title>Expenditure item index adjust amount</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {updateMessage ?
                <>
                <p>Original amount: {currencyIcon}{incomeAmount}</p>
                <p>Date last updated: {new Date(firstDateFound).toLocaleDateString('en-US', { month: 'long', year: 'numeric' })}</p>
                <p>Latest index update available: {new Date(secondDateFound).toLocaleDateString('en-US', { month: 'long', year: 'numeric' })}</p>
                <p>Increase in index: {((secondInflationIndex / firstInflationIndex - 1) * 100).toFixed(2)}%</p>
                <p>Adjusted amount: {currencyIcon}{(incomeAmount * secondInflationIndex / firstInflationIndex).toFixed(2)}</p>
                </>
                :
                <>
                <p>No update is currently available. The latest index data available is to {new Date(updateMessageDate).toLocaleDateString('en-US', { month: 'long', year: 'numeric' })}. This is earlier than the last update date for this expenditure item.</p>
                </>}
              </Modal.Body>
              <Modal.Footer>
                {updateMessage ?
                <>
                <Button variant="success" onClick={closeIndexAdjustModalUpdate}>Update</Button>
                <Button variant="secondary" onClick={closeIndexAdjustModal}>Close without update</Button>
                </> :
                <>
                <Button variant="success" onClick={closeIndexAdjustModal}>Close</Button>
                </>}
              </Modal.Footer>
            </Modal>

            <Modal show={showRedirectModal} onHide={handleRedirectClose}>
					<Modal.Header closeButton>
					<Modal.Title>Start a Plan</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<p>Thank you for your responses. The simulation calculator will now test the plan.</p>
						<p>You will then be able to edit, enhance and re-test your plan. If you want to save your plan, create a user account.</p>
						<h6>Note:</h6>
						<ul>
							<li>Home currency set to {presetObject.currency_set === 'GBP' ? 'GBP/UK' : 'USD/US'}</li>	
							<li>End of plan has been set to 100 years old</li>
							<li>Tax rate has been set to 0% on all types of pension income / withdrawals</li>
							<li>Portfolio management fee deduction is set at 0bp</li>
						</ul>
						<p>You can change these and other assumptions in the simulation calculator.</p>
					</Modal.Body>
					<Modal.Footer>
					<Button variant="primary" onClick={handleRunPlan}>
						Continue
					</Button>
					</Modal.Footer>
				</Modal>

				{/* Modal Component */}
				<Modal show={showModal} onHide={handleClose} size="lg">
					<Modal.Header closeButton>
					<Modal.Title>Start A Plan</Modal.Title>
					</Modal.Header>
					<Modal.Body>
					<Form>
            {/* Conditionally render text input or radio buttons based on question type */}
            <Form.Group>
              <Form.Label>{questions[questionIndex].question}</Form.Label>
              {questions[questionIndex].type === "text" || questions[questionIndex].type === "number" ? (
                <Form.Control
                  type={questions[questionIndex].type}
                //   value={responses[`question${questionIndex + 1}`]}
				  value={responses[`question${questionIndex + 1}`] !== null ? responses[`question${questionIndex + 1}`] : ''}
                  onChange={handleInputChange}
                  isInvalid={!!questionErrors[`question${questionIndex + 1}`]}
                />
              ) : (
                <div>
                  {questions[questionIndex].options.map((option, index) => (
                    <Form.Check 
                      key={index}
                      type="radio" 
                      label={option} 
                      name="yesNo" 
                      value={option} 
                      onChange={handleInputChange}
                      checked={responses[`question${questionIndex + 1}`] === option}
                    />
                  ))}
                </div>
              )}
              <Form.Control.Feedback type="invalid">
                {questionErrors[`question${questionIndex + 1}`]}
              </Form.Control.Feedback>
            </Form.Group>
          </Form>

					</Modal.Body>
					<Modal.Footer>
					<Button variant="secondary" onClick={handleClose}>
						Close
					</Button>
					<Button variant="primary" onClick={handleNext}>
						{questionIndex < questions.length - 1 ? 'Next' : 'Submit'}
					</Button>
					</Modal.Footer>
				</Modal>


          {/* this is stuffing to help the onscreen formatting */}
          {isSmallScreen ? <></> : <><br /><br /></>}

          </Col>

          <Col sm={8} className={`border border-secondary p-3 mt-2 mb-2 ${isSmallScreen ? '' : 'custom-scrollbar-col'}`} style={isSmallScreen ? {} : { overflowY: 'auto', height: '100vh' }}>
          <Row>
            <Col xs="6" sm="6">
            <div className="d-flex align-items-center">
            <p className="lead mb-0">Simulation results:</p>
            <OverlayTrigger
            placement="right"
            overlay={
            <Tooltip id="tooltip-right">
            The simulation calculator graphics show how the strategy would have performed in each back-test cycle. The success rate measures the percentage of back-tests where the portfolio lasted until the end of the simulation. The higher this number, the higher the level of confidence in the plan. <br /> <strong>Caution</strong> - a success rate of 100% means the plan was succesful in all of the historical back-test simulations but that does not mean it will 100% successful in the future. 
            </Tooltip>}>
            <span style={{ cursor: 'pointer', display: 'inline-block', marginLeft: '8px', verticalAlign: 'middle' }}>
            <FaInfoCircle style={{ color: '#adb5bd', fontSize: '1.0rem' }} />
            </span>
            </OverlayTrigger>
            </div>

            <div className='my-2'></div>
            {loading4 ? <ButtonExample data={buttonlabel} /> : <button className="btn btn-success" onClick={handleSubmit} style={{ marginRight: '5px', marginBottom: '5px' }}>Run Simulation</button>}
            
            </Col>
            <Col xs="6" sm="6">
            <div className="border border-light bg-dark p-2 mt-2 d-inline-block float-end rounded">
            <div className={isSmallScreen ? "fs-1 bg-dark text-light" : "fs-1 bg-dark text-light"}>{(Math.round((100 - result) * 10) / 10).toFixed(1)}%</div>
            <div className="d-flex align-items-center">
            <div className={isSmallScreen ? "small bg-dark text-light" : "small bg-dark text-light"}>Success rate</div>
            <OverlayTrigger
            placement="left"
            overlay={
            <Tooltip id="tooltip-right">
            The success rate measures the percentage of back-tests where the portfolio lasted until the end of the simulation. The higher this number, the higher the level of confidence in the plan. <br /> <strong>Caution</strong> - a success rate of 100% means the plan was succesful in all of the historical back-test simulations but that does not mean it will 100% successful in the future. 
            </Tooltip>}>
            <span style={{ cursor: 'pointer', display: 'inline-block', marginLeft: '8px', marginBottom: '4px', verticalAlign: 'top' }}>
            <FaInfoCircle style={{ color: '#adb5bd', fontSize: '1.0rem' }} />
            </span>
            </OverlayTrigger>
            </div>
            </div> 
            {/* </OverlayTrigger> */}
            </Col>
          </Row>
          
        
          <div className="my-2" />
          
            {switchvalue === 1 ? (<MasterLineChart data={dataForChart} simulation_years={presetObject.end_simulation_age - presetObject.start_simulation_age} data_direction={presetObject.data_direction} asset_mix_equity={presetObject.asset_mix_equity} labels={unitsForYAxis} title={titleForChart} yaxistitle={yaxisTitleForChart} heightratio={0.25} percentile={percentileSettingForChart} multiplelines={multipleLinesSettingForChart} portvaluedistchart={flagPortfolioValueDistChart}/>) 
            : switchvalue === 2 ? (<MaxMinPlotChart data={decresult} heightratio={0.25}/>) 
            : switchvalue === 3 ? (switchvalue3 === 1 || switchvalue3 === 2 || switchvalue3 === 3 ? (<MasterLineChart data={dataForChart} simulation_years={presetObject.end_simulation_age - presetObject.start_simulation_age} data_direction={presetObject.data_direction} asset_mix_equity={presetObject.asset_mix_equity} labels={unitsForYAxis} title={titleForChart} yaxistitle={yaxisTitleForChart} heightratio={0.25} percentile={percentileSettingForChart} multiplelines={multipleLinesSettingForChart} portvaluedistchart={flagPortfolioValueDistChart}/>) : <TradeOffAnalysis data1={presetObject} data2={setErrors} data3={setIsModalOpen} data4={setModalmessage} heightratio={0.2} currencysymbol={currencyIcon}/>)
            : switchvalue === 4 ? (switchvalue2 === 1 || switchvalue2 === 2 ? (<MasterLineChart data={dataForChart} simulation_years={presetObject.end_simulation_age - presetObject.start_simulation_age} data_direction={presetObject.data_direction} asset_mix_equity={presetObject.asset_mix_equity} labels={unitsForYAxis} title={titleForChart} yaxistitle={yaxisTitleForChart} heightratio={0.25} percentile={percentileSettingForChart} multiplelines={multipleLinesSettingForChart} portvaluedistchart={flagPortfolioValueDistChart}/>) : (switchvalue2 === 3 ? (<DrawHistChart data={drawhistdata} data3={presetObject.annuity_percent_withdrawal} heightratio={0.25} currencysymbol={currencyIcon}/>) : (<DistTypeChart data={drawstreamsbytype} data2={presetObject.years} data3={presetObject.annuity_percent_withdrawal} data5={yearsForChartShort} heightratio={0.25} currencysymbol={currencyIcon}/>)))
            : (<></>)}

          <div className="my-2"></div>

            <Row>
            <Col sm="12">
            <ToggleButtonGroup type="radio" name="options" value={switchvalue} onChange={handleSwitchChange} style={{ paddingRight: '10px', paddingBottom: '10px' }}>
              <ToggleButton className={isSmallScreen ? 'mini-toggle-button' : ''} id="tbg-btn-a1" value={1} variant="secondary" size="sm" style={isSmallScreen ? { flex: 1, maxWidth: '80px', height: '80px' } : { flex: 1, maxWidth: '100px', height: '100px' }}>
                Portfolio value by year
              </ToggleButton>           
              <ToggleButton className={isSmallScreen ? 'mini-toggle-button' : ''} id="tbg-btn-a4" value={4} variant="secondary" size="sm" style={isSmallScreen ? { flex: 1, maxWidth: '80px', height: '80px' } : { flex: 1, maxWidth: '100px', height: '100px' }}>
                Income / withdrawal analysis
              </ToggleButton>
              <ToggleButton className={isSmallScreen ? 'mini-toggle-button' : ''} id="tbg-btn-a3" value={3} variant="secondary" size="sm" style={isSmallScreen ? { flex: 1, maxWidth: '80px', height: '80px' } : { flex: 1, maxWidth: '100px', height: '100px' }}>
                Safe funding analysis
              </ToggleButton>
            </ToggleButtonGroup>

            {switchvalue === 4 ? (
            <ToggleButtonGroup type="radio" name="options2" value={switchvalue2} onChange={handleSwitchChange2} style={{ paddingBottom: '10px' }}>
   
            <ToggleButton className={isSmallScreen ? 'mini-toggle-button' : ''} id="tbg-btn-b2" value={2} variant="secondary" size="sm" style={isSmallScreen ? { flex: 1, maxWidth: '80px', height: '80px' } : { flex: 1, maxWidth: '100px', height: '100px' }}>
            Income / draw by year (by percentile)
            </ToggleButton>  
            <ToggleButton className={isSmallScreen ? 'mini-toggle-button' : ''} id="tbg-btn-b1" value={1} variant="secondary" size="sm" style={isSmallScreen ? { flex: 1, maxWidth: '80px', height: '80px' } : { flex: 1, maxWidth: '100px', height: '100px' }}>
            Income / draw by year (all results)
            </ToggleButton>   
            <ToggleButton className={isSmallScreen ? 'mini-toggle-button' : ''} id="tbg-btn-b3" value={3} variant="secondary" size="sm" style={isSmallScreen ? { flex: 1, maxWidth: '80px', height: '80px' } : { flex: 1, maxWidth: '100px', height: '100px' }}>
            Distribution of income / withdrawal
            </ToggleButton>
            <ToggleButton className={isSmallScreen ? 'mini-toggle-button' : ''} id="tbg-btn-b4" value={4} variant="secondary" size="sm" style={isSmallScreen ? { flex: 1, maxWidth: '80px', height: '80px' } : { flex: 1, maxWidth: '100px', height: '100px' }}>
            Income by type by year
            </ToggleButton>
            </ToggleButtonGroup>
            ) : switchvalue === 3 ? (
            <ToggleButtonGroup type="radio" name="options3" value={switchvalue3} onChange={handleSwitchChange3} style={{ paddingBottom: '10px' }}>
            <ToggleButton className={isSmallScreen ? 'mini-toggle-button' : ''} id="tbg-btn-c1" value={1} variant="secondary" size="sm" style={isSmallScreen ? { flex: 1, maxWidth: '80px', height: '80px' } : { flex: 1, maxWidth: '100px', height: '100px' }}>
              Safe funding by back-test cycle
            </ToggleButton>
            <ToggleButton className={isSmallScreen ? 'mini-toggle-button' : ''} id="tbg-btn-c3" value={3} variant="secondary" size="sm" style={isSmallScreen ? { flex: 1, maxWidth: '80px', height: '80px' } : { flex: 1, maxWidth: '100px', height: '100px' }}>
              Safe funding level by age
            </ToggleButton>
            <ToggleButton className={isSmallScreen ? 'mini-toggle-button' : ''} id="tbg-btn-c2" value={2} variant="secondary" size="sm" style={isSmallScreen ? { flex: 1, maxWidth: '80px', height: '80px' } : { flex: 1, maxWidth: '100px', height: '100px' }}>
              Safe withdrawal rate by age
            </ToggleButton>
            <ToggleButton className={isSmallScreen ? 'mini-toggle-button' : ''} id="tbg-btn-c4" value={4} variant="secondary" size="sm" style={isSmallScreen ? { flex: 1, maxWidth: '80px', height: '80px' } : { flex: 1, maxWidth: '100px', height: '100px' }}>
              Safe funding level by asset mix
            </ToggleButton>            
            </ToggleButtonGroup>
            ) : switchvalue === 1 ? (
            <ToggleButtonGroup type="radio" name="options4" value={switchvalue4} onChange={handleSwitchChange4} style={{ paddingBottom: '10px' }}>
            <ToggleButton className={isSmallScreen ? 'mini-toggle-button' : ''} id="tbg-btn-d2" value={2} variant="secondary" size="sm" style={isSmallScreen ? { flex: 1, maxWidth: '80px', height: '80px' } : { flex: 1, maxWidth: '100px', height: '100px' }}>
            Results by percentile
            </ToggleButton>             
            <ToggleButton className={isSmallScreen ? 'mini-toggle-button' : ''} id="tbg-btn-d1" value={1} variant="secondary" size="sm" style={isSmallScreen ? { flex: 1, maxWidth: '80px', height: '80px' } : { flex: 1, maxWidth: '100px', height: '100px' }}>
            All results
            </ToggleButton>           
            <ToggleButton className={isSmallScreen ? 'mini-toggle-button' : ''} id="tbg-btn-d3" value={3} variant="secondary" size="sm" style={isSmallScreen ? { flex: 1, maxWidth: '80px', height: '80px' } : { flex: 1, maxWidth: '100px', height: '100px' }}>
            All results (general account)
            </ToggleButton>
            <ToggleButton className={isSmallScreen ? 'mini-toggle-button' : ''} id="tbg-btn-d5" value={5} variant="secondary" size="sm" style={isSmallScreen ? { flex: 1, maxWidth: '80px', height: '80px' } : { flex: 1, maxWidth: '100px', height: '100px' }}>
            All results (tax deferred)
            </ToggleButton>    
            <ToggleButton className={isSmallScreen ? 'mini-toggle-button' : ''} id="tbg-btn-d4" value={4} variant="secondary" size="sm" style={isSmallScreen ? { flex: 1, maxWidth: '80px', height: '80px' } : { flex: 1, maxWidth: '100px', height: '100px' }}>
            All results (tax exempt)
            </ToggleButton>
            </ToggleButtonGroup>
            ) : (<></>)}
            </Col>
            </Row>
            
          <hr className="my-2" />           

          {/* <div className="row"> */}
            <p className='lead'>Key stats:</p>
            <Row>
              <div className="d-flex align-items-center">
              <div className="">Portfolio simulation (current {currencyIcon}):</div>
              <OverlayTrigger
              placement="right"
              overlay={
              <Tooltip id="tooltip-right">
              All figures are in current value (i.e. future values are shown in today's $ or £, adjusted down for inflation increase). 
              </Tooltip>}>
              <span style={{ cursor: 'pointer', display: 'inline-block', marginLeft: '8px', verticalAlign: 'middle' }}>
              <FaInfoCircle style={{ color: '#adb5bd', fontSize: '1.0rem' }} />
              </span>
              </OverlayTrigger>
              </div>
              <Col sm="4">
              <div className="fs-1 bg-dark text-light">{portfolioTotal?.toLocaleString(undefined, { maximumFractionDigits: 0 }) || '0'}</div>
              <div className="small bg-dark text-light">Portfolio starting size</div>
              </Col>
              <Col sm="4">
              <div className="fs-1 bg-dark text-light">{portfoliovaluepercentiles?.[3]?.[portfoliovaluepercentiles[3]?.length - 1]?.toLocaleString(undefined, { maximumFractionDigits: 0 }) || '0'}</div>
              <div className="small bg-dark text-light">Portfolio median ending size</div>
              </Col>
              <Col sm="4">
              <div className="fs-1 bg-dark text-light">{portfoliovaluepercentiles?.[0]?.[portfoliovaluepercentiles[0]?.length - 1]?.toLocaleString(undefined, { maximumFractionDigits: 0 }) || '0'}</div>
              <div className="small bg-dark text-light">Portfolio lowest ending size</div>
              </Col>
            </Row>
            <div className="my-2"></div>
            {safeFundingPercentiles[1] !== 0 && safeFundingPercentiles[1] != null && (
            <hr className="my-2" />
            )}

            <Row>
            {safeFundingPercentiles[1] !== 0 && safeFundingPercentiles[1] != null && (
              <div>
              <div className="d-flex align-items-center">
              <div className="">Safe funding levels (current {currencyIcon}):</div>
              <OverlayTrigger
              placement="right"
              overlay={
              <Tooltip id="tooltip-right">
              Minimum portfolio size required at the beginning of the simulation to meet the planned withdrawal / expenditure schedule under different levels of historic back-testing success (e.g. 95% level is the minimum portfolio size to meet the plan's schedule in 95 out of 100 back-testing cycles)
              </Tooltip>}>
              <span style={{ cursor: 'pointer', display: 'inline-block', marginLeft: '8px', verticalAlign: 'middle' }}>
              <FaInfoCircle style={{ color: '#adb5bd', fontSize: '1.0rem' }} />
              </span>
              </OverlayTrigger>
              </div>
              <Row>
              <Col sm="4">
                <div className="fs-1 bg-dark text-light">
                  {safeFundingPercentiles[1]?.toLocaleString(undefined, { maximumFractionDigits: 0 })}
                </div>
                <div className="small bg-dark text-light" style={{ border: 'none' }}>
                95% success level
                </div>
              </Col>
              <Col sm="4">
                <div className="fs-1 bg-dark text-light">
                  {safeFundingPercentiles[2]?.toLocaleString(undefined, { maximumFractionDigits: 0 })}
                </div>
                <div className="small bg-dark text-light" style={{ border: 'none' }}>
                99% success level
                </div>
              </Col>
              <Col sm="4">
                <div className="fs-1 bg-dark text-light">
                  {safeFundingPercentiles[3]?.toLocaleString(undefined, { maximumFractionDigits: 0 })}
                </div>
                <div className="small bg-dark text-light" style={{ border: 'none' }}>
                100% success level
                </div>
              </Col>
              </Row>
              </div>
              )}
            </Row>
            <div className="my-2"></div>
            {safeWithdrawalPercentiles?.[1] !== 0 && safeWithdrawalPercentiles?.[1] !== 100 && safeWithdrawalPercentiles?.[1] != null && (
            <hr className="my-2" />
            )}
            <Row>
            {safeWithdrawalPercentiles?.[1] !== 0 && safeWithdrawalPercentiles?.[1] !== 100 && safeWithdrawalPercentiles?.[1] != null && (
              // {safeFundingPercentiles[1] !== 0 && safeFundingPercentiles[1] != null && (
            <div>
            <div className="d-flex align-items-center">
            <div className="">Safe withdrawal rates:</div>
            <OverlayTrigger
              placement="right"
              overlay={
              <Tooltip id="tooltip-right">
              Expressed as planned expenditure (in the first year of planned expenditure; net any state, occupational and annuity income) as a percentage of the minimum safe funding level in that same simulation year under different levels of historic back-testing success. (e.g. 95% level is the maximum withdrawal rate possible without exhausting the portfolio in 95 out of 100 back-testing cycles).
              </Tooltip>}>
              <span style={{ cursor: 'pointer', display: 'inline-block', marginLeft: '8px', verticalAlign: 'middle' }}>
              <FaInfoCircle style={{ color: '#adb5bd', fontSize: '1.0rem' }} />
              </span>
              </OverlayTrigger>
              </div>
              <Row>
              <Col sm="4">
                <div className="fs-1 bg-dark text-light">
                  {safeWithdrawalPercentiles?.[1]?.toFixed(1)}%
                </div>
                <div className="small bg-dark text-light" style={{ border: 'none' }}>
                  95% success level
                </div>
              </Col>
              <Col sm="4">
                <div className="fs-1 bg-dark text-light">
                  {safeWithdrawalPercentiles?.[2]?.toFixed(1)}%
                </div>
                <div className="small bg-dark text-light" style={{ border: 'none' }}>
                  99% success level
                </div>
              </Col>
              <Col sm="4">
                <div className="fs-1 bg-dark text-light">
                  {safeWithdrawalPercentiles?.[3]?.toFixed(1)}%
                </div>
                <div className="small bg-dark text-light" style={{ border: 'none' }}>
                  100% success level
                </div>
              </Col>
              </Row>
              </div>
              )}
            </Row>

            <div className="my-2"></div>
            <hr className="my-2" />
            <Row>
            <div className="d-flex align-items-center">
            <div className="">Income / withdrawal development (current {currencyIcon}, net of tax):</div>
              <OverlayTrigger
              placement="right"
              overlay={
              <Tooltip id="tooltip-right">
              All figures are in current value (i.e. future values are shown in today's $ or £, adjusted down for inflation increase).  Figures include withdrawals plus any state, occupational and annuity pension income entering into the plan. Figures are show net of tax (if tax rates have been entered into the plan). <br />If state + occupational + annuity income is together greater than planned expenditure in any one year, then the difference is not taken as income and is re-invested into the portfolio.
              </Tooltip>}>
              <span style={{ cursor: 'pointer', display: 'inline-block', marginLeft: '8px', verticalAlign: 'middle' }}>
              <FaInfoCircle style={{ color: '#adb5bd', fontSize: '1.0rem' }} />
              </span>
              </OverlayTrigger>
              </div>

              <Col sm="4">
              <div className="fs-1 bg-dark text-light">{incomepercentiles?.[3]?.[3]?.toLocaleString(undefined, { maximumFractionDigits: 0 }) || '0'}</div>
              <div className="small bg-dark text-light" style={{ border: 'none' }}>Initial income / withdrawal value:</div>
              </Col>
              <Col sm="4">
              <div className="fs-1 bg-dark text-light">{incomepercentiles?.[3]?.[incomepercentiles[3]?.length - 1]?.toLocaleString(undefined, { maximumFractionDigits: 0 }) || '0'}</div>
              <div className="small bg-dark text-light" style={{ border: 'none' }}>Median last income / withdrawal value:</div>
              </Col>
              <Col sm="4">
              <div className="fs-1 bg-dark text-light">{Array.isArray(incomepercentiles) && incomepercentiles[0] ? Math.min(...incomepercentiles[0]).toLocaleString(undefined, { maximumFractionDigits: 0 }) : '0'}</div>
              <div className="small bg-dark text-light" style={{ border: 'none' }}>Lowest income / withdrawal value:</div>
              </Col>
              <Col sm="4">
              <div className="fs-1 bg-dark text-light">{averagedraw.toLocaleString(undefined, { maximumFractionDigits: 0 })}</div>
              <div className="small bg-dark text-light" style={{ border: 'none' }}>Average income / withdrawal value:</div>
              </Col>
              <Col sm="4">
              </Col>
            </Row>
            <div className="col-sm-12">

            {isSmallScreen ? <></> : <><br /><br /><br /></>}
            </div>


          </Col>
          </Row>
      </Container>
      </div>
    )
};

export default CalcDash1

