import React, { useEffect, useState, Component } from 'react'
import { Line } from 'react-chartjs-2';
import 'chart.js/auto'; 
import '../styles/mystyles.css'
import { FaInfoCircle,FaExclamationCircle } from 'react-icons/fa'; // Font Awesome info icon
import { OverlayTrigger, Tooltip  } from 'react-bootstrap';

function MasterLineChart(props) {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 576);
  const [chartHeight, setChartHeight] = useState(300); // Initial chart height
  const [datasets, setDatasets] = useState([]);
  const [chartLabels, setChartLabels] = useState([]);

  useEffect(() => {
    const updateChartHeight = () => {
      const screenWidth = window.innerWidth;
      const newHeight = screenWidth * props.heightratio; // You can adjust this proportion as needed
      setChartHeight(newHeight);
      setIsSmallScreen(window.innerWidth < 576);
    };
    updateChartHeight();
    window.addEventListener('resize', updateChartHeight);
    return () => {
      window.removeEventListener('resize', updateChartHeight);
    };
  }, [props.heightratio]);

  useEffect(() => {
  const newDatasets = [{
    label: 'Balance',
    data: props.data,
    backgroundColor: 'rgba(75, 192, 192, 0.2)',
    borderColor: 'rgba(75, 192, 192, 1)',
    borderWidth: 1,
  }];
  setDatasets(newDatasets);
  }, [props.data]);

  const todayraw = new Date();
  const today = todayraw.toISOString().split('T')[0];
  const month = new Date(todayraw.getFullYear(), todayraw.getMonth(), 1); // Start of the current month

  useEffect(() => {
    const newSeries = Array.from({ length: props.simulation_periods }, (_, index) => {
      const newDate = new Date(month.getFullYear(), month.getMonth() + index, 1); // Increment month/year
      const shortYear = newDate.getFullYear().toString().slice(-2); // Last two digits of the year
      return `${newDate.toLocaleString('default', { month: 'short' })}-${shortYear}`; // Format as "MMM-YY"
    });
    setChartLabels(newSeries);
  }, [props.simulation_periods]);


  const chartData = {
    labels: chartLabels,
    datasets: datasets,
  };

  const options = {
    animation: true,
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false, // Disable the legend
      },
      tooltip: {
        callbacks: {
          label: function(tooltipItem) {
            let label = tooltipItem.dataset.label || '';
            let value = tooltipItem.raw.toFixed(2);  // Set decimal places to 1
            
            return label + ': ' + value;
          }
        }
      },

      title: {
        display: true,
        color: 'white',
        font: {
          size: 14, // Set the font size for the title
        },
        text: props.title
      },
    },
    scales: {
      x: {
        grid: {
          color: 'rgba(255,255,255,0.2)', // Set x-axis grid color to a light grey with some opacity
        },
        ticks: {
          color: 'white', // Set x-axis ticks color to white
        },
        title: {
          display: true,
          text: props.yaxistitle,
          font: {
            size: 12, // optional: set the title font size
          },
          color: 'white',
        },
        type: 'category',
        labels: chartLabels,
      },
      y: {
        grid: {
          color: 'rgba(255,255,255,0.2)', // Set y-axis grid color to a light grey with some opacity
        },
        beginAtZero: true,
        ticks: {
          color: 'white',
          beginAtZero: true,
 
          callback: function(value) {
            if (value >= 1000000 && value < 10000000 || value <= -1000000 && value > -10000000) {
              return `${(value / 1000000).toFixed(1)}m`; // Show 1 decimal point for values between 1m and 10m
            } else if (value >= 10000000 || value <= -10000000) {
              return `${(Math.round(value / 1000000)).toLocaleString()}m`; // Show whole numbers for values 10m and above
            } else if (value >= 1000 && value < 10000 || value <= -1000 && value > -10000) {
              return `${(value / 1000).toFixed(1)}k`; // Show 1 decimal place for values between 1k and 10k
            } else if (value >= 10000 || value <= -10000) {
              return `${(Math.round(value / 1000)).toLocaleString()}k`; // Show whole numbers for values 10k and above
            }
            return value.toLocaleString(); // For values below 1k
          },

        },
      },
    },
  };

  return (
    <div>
      {isSmallScreen ? (
        <div style={{ width: '100%', height: '300px' }}>
          <Line data={chartData} options={options} />
        </div>
      ) : (
        <div style={{ width: '100%', height: `${chartHeight}px` }}>
          <Line data={chartData} options={options} />
        </div>
      )}
    </div>
  );
};

export default MasterLineChart;


