import React, { useEffect, useState, Component } from 'react'
import { Modal, Table, Button, Row, Form, Col, Alert } from 'react-bootstrap';

const PortfolioBreakdownPopup = ({ show, onClose, data, data2, data3, data4, data5, data6}) => {

const tablecolumnwidth = { width: '20%' }

const [showFirstTable, setShowFirstTable] = useState(true);

const [showTaxCalcs, setShowTaxCalcs] = useState(true);

const [equityReturnTaxRate, setEquityReturnTaxRate] = useState(0);
const [bondReturnTaxRate, setBondReturnTaxRate] = useState(0);
const [deferredIncomeTaxRate, setDeferredIncomeTaxRate] = useState(0);

const [effectiveEquityTax, setEffectiveEquityTax] = useState(0);
const [effectiveBondTax, setEffectiveBondTax] = useState(0);
const [effectiveDrawTax, setEffectiveDrawTax] = useState(0);

useEffect(() => {
  var percentPort;
  if ((data2.general + data2.tax_exempt + data2.tax_deferred) > 0) {percentPort = data2.general / (data2.general + data2.tax_exempt + data2.tax_deferred)} else {percentPort = 0};
  setEffectiveEquityTax(percentPort * equityReturnTaxRate)
}, [equityReturnTaxRate, data2]);

useEffect(() => {
  var percentPort;
  if ((data3.general + data3.tax_exempt + data3.tax_deferred + data4.general + data4.tax_exempt + data4.tax_deferred) > 0) {percentPort = (data3.general + data4.general) / (data3.general + data3.tax_exempt + data3.tax_deferred + data4.general + data4.tax_exempt + data4.tax_deferred)} else {percentPort = 0};
  setEffectiveBondTax(percentPort * bondReturnTaxRate)
}, [bondReturnTaxRate, data3, data4]);

useEffect(() => {
  setEffectiveDrawTax(deferredIncomeTaxRate * data5.tax_deferred_percent / 100)
}, [deferredIncomeTaxRate, data5.tax_deferred_percent]);

const toggleTable = () => {
  setShowFirstTable(!showFirstTable);
};

const toggleTaxCalc = () => {
  setShowTaxCalcs(!showTaxCalcs);
}

return (
  <Modal show={show} onHide={onClose}>
    <Modal.Header closeButton>
    <Modal.Title>Portfolio Breakdown</Modal.Title>
    </Modal.Header>
    <Modal.Body>
    {/* {data} */}

    {data === null || data === undefined || isNaN(data) || data === 0 ? <p></p> : <p>Total portfolio size ({data6}): {data.toLocaleString()}</p>}
    
    {showFirstTable ? 

    <Table striped bordered hover className="small">
          <thead>
            <tr>
              <th style={tablecolumnwidth}></th>
              <th style={tablecolumnwidth}>Equity</th>
              <th style={tablecolumnwidth}>Bonds (conv.)</th>
              <th style={tablecolumnwidth}>Bonds (index)</th>
              <th style={tablecolumnwidth}>Total</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style={tablecolumnwidth}>General</td>
              <td style={tablecolumnwidth}>{data2.general === 0 || data2.general === undefined || data2.general === null ? '0' : Math.round(data2.general).toLocaleString()}</td>
              <td style={tablecolumnwidth}>{data3.general === 0 || data3.general === undefined || data3.general === null ? '0' : Math.round(data3.general).toLocaleString()}</td>
              <td style={tablecolumnwidth}>{data4.general === 0 || data4.general === undefined || data4.general === null ? '0' : Math.round(data4.general).toLocaleString()}</td>
              <td style={tablecolumnwidth}>{data5.general === 0 || data5.general === undefined || data5.general === null ? '0' : Math.round(data5.general).toLocaleString()}</td>
            </tr>
            <tr>
              <td style={tablecolumnwidth}>Tax-exempt</td>
              <td style={tablecolumnwidth}>{data2.tax_exempt === 0 || data2.tax_exempt === undefined || data2.tax_exempt === null ? '0' : Math.round(data2.tax_exempt).toLocaleString()}</td>
              <td style={tablecolumnwidth}>{data3.tax_exempt === 0 || data3.tax_exempt === undefined || data3.tax_exempt === null ? '0' : Math.round(data3.tax_exempt).toLocaleString()}</td>
              <td style={tablecolumnwidth}>{data4.tax_exempt === 0 || data4.tax_exempt === undefined || data4.tax_exempt === null ? '0' : Math.round(data4.tax_exempt).toLocaleString()}</td>
              <td style={tablecolumnwidth}>{data5.tax_exempt === 0 || data5.tax_exempt === undefined || data5.tax_exempt === null ? '0' : Math.round(data5.tax_exempt).toLocaleString()}</td>
            </tr>
            <tr>
              <td style={tablecolumnwidth}>Tax-deferred</td>
              <td style={tablecolumnwidth}>{data2.tax_deferred === 0 || data2.tax_deferred === undefined || data2.tax_deferred === null ? '0' : Math.round(data2.tax_deferred).toLocaleString()}</td>
              <td style={tablecolumnwidth}>{data3.tax_deferred === 0 || data3.tax_deferred === undefined || data3.tax_deferred === null ? '0' : Math.round(data3.tax_deferred).toLocaleString()}</td>
              <td style={tablecolumnwidth}>{data4.tax_deferred === 0 || data4.tax_deferred === undefined || data4.tax_deferred === null ? '0' : Math.round(data4.tax_deferred).toLocaleString()}</td>
              <td style={tablecolumnwidth}>{data5.tax_deferred === 0 || data5.tax_deferred === undefined || data5.tax_deferred === null ? '0' : Math.round(data5.tax_deferred).toLocaleString()}</td>
            </tr>
            <tr>
              <td style={tablecolumnwidth}>Total</td>
              <td style={tablecolumnwidth}>{data2.total === 0 || data2.total === undefined || data2.tax_deferred === null ? '0' : Math.round(data2.total).toLocaleString()}</td>
              <td style={tablecolumnwidth}>{data3.total === 0 || data3.total === undefined || data3.tax_deferred === null ? '0' : Math.round(data3.total).toLocaleString()}</td>
              <td style={tablecolumnwidth}>{data4.total === 0 || data4.total === undefined || data4.tax_deferred === null ? '0' : Math.round(data4.total).toLocaleString()}</td>
              <td style={tablecolumnwidth}>{data5.total === 0 || data5.total === undefined || data5.tax_deferred === null ? '0' : Math.round(data5.total).toLocaleString()}</td>
            </tr>
          </tbody>
    </Table>
    :
    <Table striped bordered hover className="small">
          <thead>
            <tr>
              <th style={tablecolumnwidth}></th>
              <th style={tablecolumnwidth}>Equity</th>
              <th style={tablecolumnwidth}>Bonds (conv.)</th>
              <th style={tablecolumnwidth}>Bonds (index)</th>
              <th style={tablecolumnwidth}>Total</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style={tablecolumnwidth}>General</td>
              <td style={tablecolumnwidth}>{data2.general_percent.toFixed(1)}%</td>
              <td style={tablecolumnwidth}>{data3.general_percent.toFixed(1)}%</td>
              <td style={tablecolumnwidth}>{data4.general_percent.toFixed(1)}%</td>
              <td style={tablecolumnwidth}>{data5.general_percent.toFixed(1)}%</td>
            </tr>
            <tr>
              <td style={tablecolumnwidth}>Tax-exempt</td>
              <td style={tablecolumnwidth}>{data2.tax_exempt_percent.toFixed(1)}%</td>
              <td style={tablecolumnwidth}>{data3.tax_exempt_percent.toFixed(1)}%</td>
              <td style={tablecolumnwidth}>{data4.tax_exempt_percent.toFixed(1)}%</td>
              <td style={tablecolumnwidth}>{data5.tax_exempt_percent.toFixed(1)}%</td>
            </tr>
            <tr>
              <td style={tablecolumnwidth}>Tax-deferred</td>
              <td style={tablecolumnwidth}>{data2.tax_deferred_percent.toFixed(1)}%</td>
              <td style={tablecolumnwidth}>{data3.tax_deferred_percent.toFixed(1)}%</td>
              <td style={tablecolumnwidth}>{data4.tax_deferred_percent.toFixed(1)}%</td>
              <td style={tablecolumnwidth}>{data5.tax_deferred_percent.toFixed(1)}%</td>
            </tr>
            <tr>
              <td style={tablecolumnwidth}>Total</td>
              <td style={tablecolumnwidth}>{data2.total_percent.toFixed(1)}%</td>
              <td style={tablecolumnwidth}>{data3.total_percent.toFixed(1)}%</td>
              <td style={tablecolumnwidth}>{data4.total_percent.toFixed(1)}%</td>
              <td style={tablecolumnwidth}>{data5.total_percent.toFixed(1)}%</td>
            </tr>
          </tbody>
    </Table>}
    <Button variant="success" onClick={toggleTable}>
        {showFirstTable ? 'Show as %' : 'Show as $,£'}
    </Button> 
    {/* <Button variant="success" onClick={toggleTaxCalc}>
        {showTaxCalcs ? 'Show tax calculations' : 'Remove tax calculations'}
    </Button> */}

    {/* {showTaxCalcs ? 
    <div>
    <hr className="my-2"/>
    <h6>Calculations for effective tax rate inputs:</h6>
      <Row>
        <Form.Group as={Col}>
          <Form.Label htmlFor="equityReturnTaxRate" className="small">Tax rate on equity returns (%):</Form.Label>
            <Form.Control
              name="equityReturnTaxRate"
              id="equityReturnTaxRate"
              className="form-control bg-secondary border-secondary text-light"
              type="number"
              value={equityReturnTaxRate}
              onChange={(e) => setEquityReturnTaxRate(e.target.value)}
              placeholder="%"
              min="0"         
            />
        </Form.Group>
        <Form.Group as={Col}>
          <Form.Label htmlFor="bondReturnTaxRate" className="small">Tax rate on bonds returns (%):</Form.Label>
            <Form.Control
              name="bondReturnTaxRate"
              id="bondReturnTaxRate"
              className="form-control bg-secondary border-secondary text-light"
              type="number"
              value={bondReturnTaxRate}
              onChange={(e) => setBondReturnTaxRate(e.target.value)}
              placeholder="%"
              min="0"         
            />
        </Form.Group>
      </Row>
      <Row>
    <Form.Group as={Col}>
          <Form.Label htmlFor="deferredIncomeTaxRate" className="small">Tax rate on tax deferred withdrawals (%):</Form.Label>
            <Form.Control
              name="deferredIncomeTaxRate"
              id="deferredIncomeTaxRate"
              className="form-control bg-secondary border-secondary text-light"
              type="number"
              value={deferredIncomeTaxRate}
              onChange={(e) => setDeferredIncomeTaxRate(e.target.value)}
              placeholder="%"
              min="0"         
            />
        </Form.Group>  
    </Row>
    <div className="my-2"></div>
    <p className="small">Equity returns effective direct tax (on whole portfolio): {effectiveEquityTax.toFixed(1)}%</p>
    <p className="small">Bond returns effective direct tax (on whole portfolio): {effectiveBondTax.toFixed(1)}%</p>
    <p className="small">Effective tax on withdrawal income (on whole portfolio): {effectiveDrawTax.toFixed(1)}%</p>
    </div> : <div></div>} */}


    </Modal.Body>
  </Modal>
)
}

export default PortfolioBreakdownPopup

