import React from 'react'

function ValidateStatePensionForm (numberStatePensionItems, presetObject, stateName, stateYear, stateAmount, stateIncrease, stateDate, setErrors, setIsModalOpen, setModalmessage) {

    const newErrors = {};

    if (numberStatePensionItems >= 10) {setModalmessage('Maximum number of items exceeded'); setIsModalOpen(true); return false}
    if (stateName.length > 64) {newErrors.stateName = 'Name description must be less than 255 characters'}
    if (stateName === undefined || String(stateName).trim() === '') {newErrors.stateName = 'Add a name'}
    if (stateAmount < 0) {newErrors.stateAmount = 'Can not be negative'}
    if (isNaN(stateAmount) || stateAmount === null || stateAmount === undefined || String(stateAmount).trim() === '') {newErrors.stateAmount = 'Can not have empty fields. Enter a value.'}
    if (isNaN(stateIncrease) || stateIncrease === null || stateIncrease === undefined || String(stateIncrease).trim() === '') {newErrors.stateIncrease = 'Can not have empty fields. Enter a value.'}
    if (stateYear < presetObject.start_simulation_age || stateYear > presetObject.end_simulation_age) {newErrors.stateYear = 'Start age must be inside range of simulation start age and end age'}

    if (Object.keys(newErrors).length > 0) {
        setIsModalOpen(true)
        setModalmessage('Entry error. Check beneath input boxes for error messages.')
        setErrors(newErrors); 
        return false};
    setErrors({}); 

    return (true)
    };

export default ValidateStatePensionForm
