import React from 'react'

function ValidateBondPortfolioForm (numberBondPortfolioItems, presetObject, bondPortfolioName, bondPortfolioMaturity, bondPortfolioCoupon, bondPortfolioIndex, bondPortfolioParNotional, bondPortfolioIndexRatio, bondPortfolioPrice, bondPortfolioNotes, bondPortfolioLastUpdate, setErrors, setIsModalOpen, setModalmessage) {
    
    const newErrors = {};

    if (numberBondPortfolioItems >= 100) {setModalmessage('Maximum number of items exceeded'); setIsModalOpen(true); return false}
    if (bondPortfolioName.length > 64) {newErrors.bondPortfolioName = 'Name  / description must be no more than 64 characters'}
    if (bondPortfolioNotes && bondPortfolioNotes.length > 64) {
        newErrors.bondPortfolioNotes = 'Name / description must be no more than 64 characters';
      }

    // if (incomeName === undefined || String(incomeName).trim() === '') {newErrors.incomeName = 'Add a name'}
    
    if (bondPortfolioCoupon < 0) {newErrors.bondPortfolioCoupon = 'Can not be negative'}
    if (bondPortfolioParNotional < 0) {newErrors.bondPortfolioParNotional = 'Can not be negative'}
    if (bondPortfolioIndexRatio < 0) {newErrors.bondPortfolioIndexRatio = 'Can not be negative'}
    if (bondPortfolioPrice < 0) {newErrors.bondPortfolioPrice = 'Can not be negative'}

    if (isNaN(bondPortfolioCoupon) || bondPortfolioCoupon === null || bondPortfolioCoupon === undefined || String(bondPortfolioCoupon).trim() === '') {newErrors.bondPortfolioCoupon = 'Can not have empty fields. Enter a value.'}
    if (isNaN(bondPortfolioParNotional) || bondPortfolioParNotional === null || bondPortfolioParNotional === undefined || String(bondPortfolioParNotional).trim() === '') {newErrors.bondPortfolioParNotional = 'Can not have empty fields. Enter a value.'}
    if (isNaN(bondPortfolioPrice) || bondPortfolioPrice === null || bondPortfolioPrice === undefined || String(bondPortfolioPrice).trim() === '') {newErrors.bondPortfolioPrice = 'Can not have empty fields. Enter a value.'}
    
    if (Object.keys(newErrors).length > 0) {
        setIsModalOpen(true)
        setModalmessage('Entry error. Check beneath input boxes for error messages.')
        setErrors(newErrors); 
        return false};
    setErrors({}); 

    return (true)
    };

export default ValidateBondPortfolioForm
