import React from 'react'

function ValidateIncomeForm (numberIncomeItems, presetObject, incomeName, incomeYear, incomeYearEnd, incomeAmount, incomeIncrease, incomeIncrease2, incomeDate, percentCovered, incomeStartDate, incomeEndDate, setErrors, setIsModalOpen, setModalmessage) {

    const newErrors = {};

    if (numberIncomeItems >= 20) {setModalmessage('Maximum number of items exceeded'); setIsModalOpen(true); return false}
    if (incomeName.length > 64) {newErrors.incomeName = 'Name description must be less than 255 characters'}
    if (incomeName === undefined || String(incomeName).trim() === '') {newErrors.incomeName = 'Add a name'}
    if (incomeEndDate < incomeStartDate) {newErrors.incomeEndDate = 'End date must be after start date'}
    
    if (incomeAmount < 0) {newErrors.incomeAmount = 'Can not be negative'}
    if (percentCovered < 0 || percentCovered > 100) {newErrors.percentCovered = 'Must be between 0 and 100'}
    if (isNaN(incomeAmount) || incomeAmount === null || incomeAmount === undefined || String(incomeAmount).trim() === '') {newErrors.incomeAmount = 'Can not have empty fields. Enter a value.'}
    if (isNaN(incomeIncrease) || incomeIncrease === null || incomeIncrease === undefined || String(incomeIncrease).trim() === '') {newErrors.incomeIncrease = 'Can not have empty fields. Enter a value.'}
    if (isNaN(incomeIncrease2) || incomeIncrease2 === null || incomeIncrease2 === undefined || String(incomeIncrease2).trim() === '') {newErrors.incomeIncrease2 = 'Can not have empty fields. Enter a value.'}
    if (isNaN(percentCovered) || percentCovered === null || percentCovered === undefined || String(percentCovered).trim() === '') {newErrors.percentCovered = 'Can not have empty fields. Enter a value.'}
    
    if (Object.keys(newErrors).length > 0) {
        setIsModalOpen(true)
        setModalmessage('Entry error. Check beneath input boxes for error messages.')
        setErrors(newErrors); 
        return false};
    setErrors({}); 

    return (true)
    };

export default ValidateIncomeForm
