import React, { useEffect, useState, Component } from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import BoxAndWhiskerPlot from "../components/barandwhisker.js"
import YieldChart from "../components/barchart.js"
import Dropdown from 'react-bootstrap/Dropdown'
import IndexChart from "../components/indexchart.js"
import '../styles/mystyles.css'
import LogoBanner from "../components/logobanner.png"
import ReturnVarianceChart from "../components/returnvariancechart.js"

const Returns = () => { 

	const API_HOST = 'http://localhost:8000';

    // const API_HOST = 'https://ukfinindbackend-eaa5c6e3a567.herokuapp.com';

    let _csrfToken = null

	const api_key = 'seh3599f@fUERkdf22&^2{u'

	// ideally need to update below so it auto links from set data for gilt coupon...

	const [requestObject, setRequestObject] = useState({'period' : 5, 'bond_coupon' : 3, 'index_bond_coupon' : 0.5, 'data_option' : 2, 'data_start_year': 1899, 'data_end_year': 2023, 'currency_set': 'USD', 'geographic_set': 'DOMESTIC'})

	const [selectedOption, setSelectedOption] = useState(5);

	const [selectedCurrency, setSelectedCurrency] = useState('USD (US Eq, US Gvmt Bonds, US CPI, 1900-2023)');

	// this is in order to control text size depending on screen size
	const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 576);
	useEffect(() => {
		const handleResize = () => {
		setIsSmallScreen(window.innerWidth < 576);
		};
		window.addEventListener('resize', handleResize);
		return () => {
		window.removeEventListener('resize', handleResize);
		};
	}, []);

	const webpageData = {
		"@context": "https://schema.org",
		"@type": "WebPage",
		"name": "Retire Smart Calc",
		"logo": "https://www.retiresmartcalc.com/logo.png",
		"description": "Retirement portfolio safe withdrawal rate (SWR) calculator. Back-test strategy and examine impact of asset mix, constant vs. flexible withdrawal strategies and purchase of an annuity.",
	};

	const [chartData, setChartData] = useState({
			'equity_nominal': [],
			'bond_nominal': [],
			'index_bond_nominal': [],
			'cpi_nominal': [],
			'equity_nominal_avg': 0.0,
			'bond_nominal_avg': 0.0,
			'index_bond_nominal_avg': 0.0,
			'cpi_nominal_avg': 0.0,
			'equity_real': [],
			'bond_real': [],
			'index_bond_real': [],
			'equity_real_avg': 0.0,
			'bond_real_avg': 0.0,
			'index_bond_real_avg': 0.0,
	});

	const [chartData2, setChartData2] = useState({
		'equity_nominal': [],
		'bond_nominal': [],
		'index_bond_nominal': [],
		'cpi_nominal': [],
		'equity_nominal_avg': 0.0,
		'bond_nominal_avg': 0.0,
		'index_bond_nominal_avg': 0.0,
		'cpi_nominal_avg': 0.0,
		'equity_real': [],
		'bond_real': [],
		'index_bond_real': [],
		'equity_real_avg': 0.0,
		'bond_real_avg': 0.0,
		'index_bond_real_avg': 0.0,
	});

	const [chartData3, setChartData3] = useState({
		'index_bond_forward_select' : [],
        'bond_forward_select' : [],
        'forward_chart_labels' : [],
	});

	const [chartData4, setChartData4] = useState({
		'five' : [],
        'ten' : [],
        'twenty' : [],
        'thirty' : [],
	});

	const [chartData5, setChartData5] = useState({
		'equity' : [],
		'bond' : [],
		'years' : [],
	});

	const [chartData6, setChartData6] = useState({
		'one' : [],
		'ten' : [],
		'twenty' : [],
	})

    const [updatedate, setUpdatedate] = useState();

	const handleSelectChange = (eventKey) => {
		setSelectedOption(eventKey);
		setRequestObject({...requestObject,['period']:parseInt(eventKey)});
	};

	const handleCurrencyChange = (eventKey) => {
		if (eventKey == 1) {setSelectedCurrency('GBP (global equity, UK conventional gvmt 10y bonds, UK CPI, 1900-2023)'); setRequestObject({...requestObject,['currency_set']:'GBP', ['geographic_set']:'GLOBAL', ['data_start_year']: 1899, ['data_option']:parseInt(eventKey)})} 
		else if (eventKey == 2) {setSelectedCurrency('USD (US equity, US conventional gvmt 10y bonds, US CPI, 1900-2023)'); setRequestObject({...requestObject,['currency_set']:'USD', ['geographic_set']:'DOMESTIC', ['data_start_year']: 1899, ['data_option']:parseInt(eventKey)})}
		else if (eventKey == 3) {setSelectedCurrency('USD (US equity, US conventional gvmt 10y bonds, US CPI, 1870-2023)'); setRequestObject({...requestObject,['currency_set']:'USD', ['geographic_set']:'DOMESTIC', ['data_start_year']: 1870, ['data_option']:parseInt(eventKey)})}
		else {setSelectedCurrency('USD (global equity, US conventional gvmt 10y bonds, US CPI, 1900-2023)'); setRequestObject({...requestObject,['currency_set']:'USD', ['geographic_set']:'GLOBAL', ['data_start_year']: 1899, ['data_option']:parseInt(eventKey)})};
	};

	useEffect(() => {
		handleSubmit()
	}, []);

	useEffect(() => {
		handleSubmit()
	}, [requestObject]);

    const handleSubmit = async () => {
		try {
		const response = await fetch('/api/historics/', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'X-API-KEY': api_key // Set the custom header here
				},
		  	body: JSON.stringify(requestObject),
	});
		const data = await response.json();

		setChartData6({
			'one': data.returnvariance.one,
			'ten': data.returnvariance.ten,
			'twenty': data.returnvariance.twenty,
		})

		setChartData({
			'equity_nominal': data.deciles_equity_nominal_1,
			'bond_nominal': data.deciles_bond_nominal_1,
			'index_bond_nominal': data.deciles_index_bond_nominal_1,
			'cpi_nominal': data.deciles_cpi_change_1,
			'equity_nominal_avg': data.avg_equity_nominal_1,
			'bond_nominal_avg': data.avg_bond_nominal_1,
			'index_bond_nominal_avg': data.avg_index_bond_nominal_1,
			'cpi_nominal_avg': data.avg_cpi_change_1,
			'equity_real': data.deciles_equity_real_1,
			'bond_real': data.deciles_bond_real_1,
			'index_bond_real': data.deciles_index_bond_real_1,
			'equity_real_avg': data.avg_equity_real_1,
			'bond_real_avg': data.avg_bond_real_1,
			'index_bond_real_avg': data.avg_index_bond_real_1,
		});

		setChartData2({
			'equity_nominal': data.deciles_equity_nominal_5,
			'bond_nominal': data.deciles_bond_nominal_5,
			'index_bond_nominal': data.deciles_index_bond_nominal_5,
			'cpi_nominal': data.deciles_cpi_change_5,
			'equity_nominal_avg': data.avg_equity_nominal_5,
			'bond_nominal_avg': data.avg_bond_nominal_5,
			'index_bond_nominal_avg': data.avg_index_bond_nominal_5,
			'cpi_nominal_avg': data.avg_cpi_change_5,
			'equity_real': data.deciles_equity_real_5,
			'bond_real': data.deciles_bond_real_5,
			'index_bond_real': data.deciles_index_bond_real_5,
			'equity_real_avg': data.avg_equity_real_5,
			'bond_real_avg': data.avg_bond_real_5,
			'index_bond_real_avg': data.avg_index_bond_real_5,
		});

		setChartData3({
			'index_bond_forward_select' : data.index_bond_forward_select,
			'bond_forward_select' : data.bond_forward_select,
			'index_bond_forward_select_us' : data.index_bond_forward_select_us,
			'bond_forward_select_us' : data.bond_forward_select_us,
			'forward_chart_labels' : data.forward_chart_labels,
		});

		setChartData4({
			'five' : data.five,
			'ten' : data.ten,
			'twenty' : data.twenty,
			'thirty' : data.thirty,
		});

		setChartData5({
			'equity' : data.equity_chart,
			'bond' : data.bond_chart,
			'years' : data.years
		})

		setUpdatedate(data.update_date.toString().slice(0, -9));

		} catch (error) {console.log(error)};

	  };



	return ( 
		<Container fluid className="bg-dark text-light" style={{ minHeight: '100vh' }}>
			<Helmet>
            	<title>Retire Smart Calc - Nominal And Real Historic Returns On Equities And Government Bonds</title>
            	<meta name="description" content="Retirement portfolio safe withdrawal rate (SWR) calculator. Back-test strategy and examine impact of asset mix, constant vs. flexible withdrawal strategies and purchase of an annuity." />
				<link rel="canonical" href="https://www.retiresmartcalc.com/#/returns"></link>
				<link rel="icon" href="https://www.retiresmartcalc.com/logo.png" />
				
				<script type="application/ld+json">
      			{JSON.stringify(webpageData)}
    			</script>
			  </Helmet>



			<Row className="h-100 d-flex align-items-end" style={{ paddingTop: '50px' }}>
			<Col xs={0} sm={1} />
			<Col xs={12} sm={10} className="rounded p-3 mt-2 mb-2">
			  {/* <h1 className="display-5">Retire Smart Calc</h1> */}
			  {isSmallScreen ? <img src={LogoBanner} alt="Logo" style={{ width: '350px', height: 'auto' }} /> : <img src={LogoBanner} alt="Logo" style={{ width: '400px', height: 'auto' }} />}
			<p className="lead"></p>
			<div className="my-4"></div>
				<p className="lead"></p>
				{/* <hr className="my-4"></hr> */}
				<h2 className="lead">Historic nominal and real returns on equities vs. bonds:</h2>
				<hr className="my-1"></hr>
				<p className={isSmallScreen ? 'small' : ''}>The first interactive chart below allows you to see the range of historic returns (up to end 2023) from equities and conventional bonds (10 year government bond) both in nominal and real terms (i.e. adjusted for inflation). The second chart shows the real term index growth of equites vs. conventional bonds for the selected data-set. The third chart shows the impact of different asset mix combinations of equities and conventional bonds on historic real returns vs. volatility (standard deviation) of real returns over different holding periods for the selected data-set (with annual rebalancing of the target asset mix during those holding periods).</p>

				<div className="d-flex align-items-center">
						<Dropdown onSelect={handleSelectChange} className='me-2'>
						<Dropdown.Toggle variant="success" id="dropdown-basic">
							Choose a time period
						</Dropdown.Toggle>
						<Dropdown.Menu>
							<Dropdown.Item eventKey="3">3 years</Dropdown.Item>
							<Dropdown.Item eventKey="5">5 years</Dropdown.Item>
							<Dropdown.Item eventKey="10">10 years</Dropdown.Item>
							<Dropdown.Item eventKey="15">15 years</Dropdown.Item>
							<Dropdown.Item eventKey="20">20 years</Dropdown.Item>
							<Dropdown.Item eventKey="25">25 years</Dropdown.Item>
						</Dropdown.Menu>
						</Dropdown>

						<Dropdown onSelect={handleCurrencyChange}>
						<Dropdown.Toggle variant="success" id="dropdown-basic">
							Choose data set
						</Dropdown.Toggle>
						<Dropdown.Menu>
							<Dropdown.Item eventKey="2">USD (US equities in USD, US conventional gvmt 10y bonds, US CPI 1900-2023)</Dropdown.Item>
							<Dropdown.Item eventKey="3">USD (US equities in USD, US conventional gvmt 10y bonds, US CPI 1870-2023)</Dropdown.Item>
							<Dropdown.Item eventKey="4">USD (Global equities in USD, US conventional gvmt 10y bonds, US CPI 1900-2023)</Dropdown.Item>
							<Dropdown.Item eventKey="1">GBP (Global equities in GBP, UK conventional gvmt 10y bonds, UK CPI 1900-2023)</Dropdown.Item>
						</Dropdown.Menu>
						</Dropdown>
			</div>


			<br></br>
			<h6>{selectedOption} year historic asset class returns ({selectedCurrency}, by selected percentiles):</h6>
			<div className="row">
			<div className="col-lg-2"></div>
			<div className="col-lg-8 mt-2 mb-2">
			<BoxAndWhiskerPlot data={chartData2} heightratio={1}/>
			{/* <p className="fw-lighter small">Note: the mid point in each bar shows the average return rather than the median.</p> */}
			</div>
			<div className="col-lg-2"></div>
			</div>

			<br></br>
			<h6>Accumulated historic real returns by asset class ({selectedCurrency}):</h6>	
			<div className="row">
			<div className="col-lg-2"></div>
			<div className="col-lg-8 mt-2" style={{ marginBottom: '40px' }}>		
			<IndexChart data={chartData5}/>
			</div>
			<div className="col-lg-2"></div>
			</div>

			<h6>Average real returns and volatility of returns by asset class mix and holding period (with annual rebalancing) ({selectedCurrency}):</h6>	
			<div className="row">
			<div className="col-lg-2"></div>
			<div className="col-lg-8 mt-2" style={{ marginBottom: '40px' }}>		
			<ReturnVarianceChart data={chartData6}/>
			</div>
			<div className="col-lg-2"></div>
			</div>			
			</Col> 
			<Col xs={0} sm={1} />
			</Row>
		</Container>
	); 
	}; 

	export default Returns;
	 
	  
	  
	  
	  