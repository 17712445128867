import React from 'react'

function ValidateContributionForm (numberContributionItems, presetObject, contributionName, contributionYear, contributionYearEnd, contributionAmount, contributionIncrease, contributionDate, setErrors, setIsModalOpen, setModalmessage) {

    const newErrors = {};

    if (numberContributionItems >= 10) {setModalmessage('Maximum number of items exceeded'); setIsModalOpen(true); return false}
    if (contributionName.length > 64) {newErrors.contributionName = 'Name description must be less than 255 characters'}
    if (contributionName === undefined || String(contributionName).trim() === '') {newErrors.contributionName = 'Add a name'}
    if (contributionYear < presetObject.start_simulation_age || contributionYear > presetObject.end_simulation_age) {newErrors.contributionYear = 'Start age must be inside range of simulation start age and end age'}
    if (contributionYearEnd < presetObject.start_simulation_age || contributionYearEnd > presetObject.end_simulation_age) {newErrors.contributionYearEnd = 'End age must be inside range of simulation start age and end age'}
    if (contributionAmount < 0) {newErrors.contributionAmount = 'Can not be negative'}
    if (isNaN(contributionAmount) || contributionAmount === null || contributionAmount === undefined || String(contributionAmount).trim() === '') {newErrors.contributionAmount = 'Can not have empty fields. Enter a value.'}
    if (isNaN(contributionIncrease) || contributionIncrease === null || contributionIncrease === undefined || String(contributionIncrease).trim() === '') {newErrors.contributionIncrease = 'Can not have empty fields. Enter a value.'}

    if (Object.keys(newErrors).length > 0) {
        setIsModalOpen(true)
        setModalmessage('Entry error. Check beneath input boxes for error messages.')
        setErrors(newErrors); 
        return false};
    setErrors({}); 

    return (true)
    };

export default ValidateContributionForm
